import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { id: "app" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Notification = _resolveComponent("Notification")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", _hoisted_1, [
      _createVNode(_component_router_view),
      _createVNode("iframe", {
        src: `https://www.googletagmanager.com/ns.html?id=${_ctx.gtmId}`,
        height: "0",
        width: "0",
        title: "genesis-gtm",
        style: {"display":"none","visibility":"hidden"}
      }, null, 8, ["src"])
    ]),
    _createVNode(_component_Notification, {
      modelValue: _ctx.updateExists,
      onUpdate: _ctx.refreshApp
    }, null, 8, ["modelValue", "onUpdate"])
  ], 64))
}