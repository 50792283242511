import {
  DetailClientManagement,
  ClientCurrentRate,
  ClientRenewalRate,
  ClientManagementData,
  ClientManagementEntities,
  ClientCod,
  ClientCodAsDefault,
  ClientLocation,
  ClientTax,
  ClientBilling,
  BankDetail
} from "@/domain/entities/ClientManagement";
import {
  clientCodConfigAmountData,
  clientPaymentMethodData
} from "@/domain/entities/ClientRegistration";
import { OptionsClass } from "@/domain/entities/MainApp";
import { Pagination } from "@/domain/entities/Pagination";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { AxiosResponse } from "axios";
import startCase from "lodash/startCase";
import { ConfigBucketStorage } from "@/feature-flags/flags-config-bucket-storage";
import parsingLinkGcpToAws from "@/app/infrastructures/misc/common-library/ParsingLinkGcpToAws";

export class ClientManagementMapper {
  public convertClientManagementFromApi(
    result: AxiosResponse<any>
  ): ClientManagementEntities {
    const { data } = result;
    return new ClientManagementEntities(
      new Pagination(data.meta.page, data.meta.limit, data.meta.total_records),
      data.data.length > 0
        ? data.data.map((item: any) => {
            return new ClientManagementData(
              item.client_id,
              item.client_company_name,
              item.client_code,
              item.rate_version_start_date,
              item.rate_version_end_date,
              item.is_banned ? "freeze" : item.rate_version_status,
              item.rate_version_status_renewal,
              item.type,
              item.is_banned ? "freeze" : "active"
            );
          })
        : []
    );
  }

  public convertDetailClientManagementFromApi(
    result: AxiosResponse<any>,
    version = "v1"
  ): DetailClientManagement {
    const {
      data: { data }
    } = result;

    const isV1 = version === "v1";

    return new DetailClientManagement({
      id: data.client_id,
      clientName: data.client_name,
      clientType: data.client_type,
      clientBalance: data.client_balance,
      clientCategory: data.client_category,
      clientIsBanned: data.client_is_banned,
      clientBannedReason: data.client_is_banned_reason,
      clientAccountName: data.client_beneficiary_account_name,
      clientAccountNumber: data.client_beneficiary_account_number,
      clientBankName: data.client_beneficiary_bank_name,
      clientBeneficiaryEmail: data.client_beneficiary_email,
      clientContactEmail: data.client_contact_email,
      clientContactJobTitle: data.client_contact_job_title,
      clientContactName: data.client_contact_name,
      clientContactPhone: data.client_contact_phone,
      clientIsCod: data.client_is_cod,
      clientIsDfod: data.client_is_dfod,
      clientIsDo: data.client_is_do,
      clientParentName: data.client_parent_name,
      clientParentId : data.client_parent_id,
      clientParentCode: data.client_parent_code,
      clientPartnerCommissionAmount: data.client_partner_commission_amount,
      clientPartnerCommissionAmountPercentage: String(
        data.client_partner_commission_amount
      ).replaceAll(".", ","),
      clientPartnerId: data.client_partner_id,
      clientPartnerName: data.client_partner_name,
      clientPartnerQuantifierCommission:
        data.client_partner_quantifier_commission,
      clientPartnerNumeratorCommission:
        data.client_partner_numerator_commission,
      clientCode: data.client_code,
      clientElexysCode: data.client_elexys_code,
      clientPhone: data.client_phone,
      clientEmail: data.client_email,
      clientTaxNumber: isV1
        ? data.client_tax_number
        : data.client_tax_number.replaceAll(".", "").replaceAll("-", ""),
      clientStatus: data.client_status,
      clientFreeze: data.client_is_banned,
      clientAddress: data.client_address,
      clientDistrict: new ClientLocation({
        id: data.client_district_id,
        code: data.client_district_code,
        name: data.client_district_name,
        cityCode: data.client_city_code
      }),
      clientCity: new ClientLocation({
        id: data.client_city_id,
        code: data.client_city_code,
        name: data.client_city_name,
        cityCode: data.client_city_code
      }),
      clientStatusUpdatedAt: data.client_status_updated_at,
      clientStatusUpdatedBy: data.client_status_updated_by,
      clientStatusUpdatedReason: data.client_status_updated_reason,
      clientIsCustomRate: data.client_is_custom_rate,
      clientIsRenewalCustomRate: data.client_is_renewal_custom_rate,
      clientCreatedAt: data.client_created_at,
      clientUpdatedAt: data.client_updated_at,
      clientCreatedBy: data.client_created_by,
      clientUpdatedBy: data.client_updated_by,
      clientCurrentRate: data.client_current_rate
        ? new ClientCurrentRate({
            clientCustomRateId: data.client_current_rate.client_custom_rate_id,
            clientStatusCustomRate:
              data.client_current_rate.client_status_custom_rate,
            clientRateversionStartDate:
              data.client_current_rate.client_rate_version_start_date,
            clientRateVersionEndDate:
              data.client_current_rate.client_rate_version_end_date,
            clientStatusUpdatedAt:
              data.client_current_rate.client_status_updated_at,
            clientStatusUpdatedBy:
              data.client_current_rate.client_status_updated_by,
            clientStatusUpdatedReason:
              data.client_current_rate.client_status_updated_reason,
            clientVersionBaseRate:
              data.client_current_rate.client_version_base_rate,
            clientDiscountBaseRate: Number(
              data.client_current_rate.client_discount_base_rate
            ).toLocaleString("id"),
            clientFileNameBaseRate:
              data.client_current_rate.client_file_name_base_rate,
            clientFileBaseRate: data.client_current_rate.client_file_base_rate,
            clientVersionCustomRate:
              data.client_current_rate.client_version_custom_rate,
            clientFileNameCustomRate:
              data.client_current_rate.client_file_name_custom_rate,
            clientFileCustomRate:
              data.client_current_rate.client_file_custom_rate,
            clientErrorNameCustomRate:
              data.client_current_rate.client_error_name_custom_rate,
            clientErrorFileCustomRate:
              data.client_current_rate.client_error_file_custom_rate,
            isBaseRate: !!data.client_current_rate.client_file_name_base_rate,
            isCustomRate: !!data.client_current_rate
              .client_file_name_custom_rate,
            isCombine:
              !!data.client_current_rate.client_file_name_base_rate &&
              !!data.client_current_rate.client_file_name_custom_rate,
            clientRateVersionCustom3lc:
              data.client_current_rate.client_rate_version_custom_3lc,
            clientRatePriceType:
              data.client_current_rate.client_rate_price_type,
            clientRateInsuranceType:
              data.client_current_rate.client_rate_insurance_type || "",
            clientRateInsuranceValuePercentage:
              data.client_current_rate.client_rate_insurance_value_percentage ||
              0
          })
        : new ClientCurrentRate(),
      clientCodReject: new ClientCod({
        clientCodName: data.client_branch_cod_return_receiver_name,
        clientCodAddress: data.client_branch_cod_return_receiver_address,
        clientCodDistrict:
          data.client_branch_cod_return_receiver_district_id === 0
            ? ""
            : {
                id: data.client_branch_cod_return_receiver_district_id,
                code: data.client_branch_cod_return_receiver_district_code,
                name: data.client_branch_cod_return_receiver_district_name,
                cityCode: data.client_branch_cod_return_receiver_city_code
              },
        clientCodCity: data.client_branch_cod_return_receiver_city_name,
        clientCodPhone: data.client_branch_cod_return_receiver_phone,
        clientCodCommission: data.client_branch_cod_fee,
        clientCodCommissionPercentage: String(
          data.client_branch_cod_fee
        ).replaceAll(".", ",")
      }),
      clientCodRejectAsDefault: new ClientCodAsDefault({
        defaultCodName: data.client_branch_cod_return_receiver_name,
        defaultCodAddress: data.client_branch_cod_return_receiver_address,
        defaultCodDistrict:
          data.client_branch_cod_return_receiver_district_id === 0
            ? ""
            : {
                id: data.client_branch_cod_return_receiver_district_id,
                code: data.client_branch_cod_return_receiver_district_code,
                name: data.client_branch_cod_return_receiver_district_name,
                cityCode: data.client_branch_cod_return_receiver_city_code
              },
        defaultCodCity: data.client_branch_cod_return_receiver_city_name,
        defaultCodPhone: data.client_branch_cod_return_receiver_phone
      }),
      dataProcessed: data.data_processed_renewal,
      totalData: data.total_data_renewal,
      clientRenewalRate: data.client_renewal_rate
        ? new ClientRenewalRate({
            clientCustomRateId: data.client_renewal_rate?.client_custom_rate_id,
            clientStatusCustomRate:
              data.client_renewal_rate?.client_status_custom_rate,
            clientRateversionStartDate:
              data.client_renewal_rate?.client_rate_version_start_date,
            clientRateVersionEndDate:
              data.client_renewal_rate?.client_rate_version_end_date,
            clientStatusUpdatedAt:
              data.client_renewal_rate?.client_status_updated_at,
            clientStatusUpdatedBy:
              data.client_renewal_rate?.client_status_updated_by,
            clientStatusUpdatedReason:
              data.client_renewal_rate?.client_status_updated_reason,
            clientVersionBaseRate:
              data.client_renewal_rate?.client_version_base_rate,
            clientDiscountBaseRate: Number(
              data.client_renewal_rate?.client_discount_base_rate
            ).toLocaleString("id"),
            clientFileNameBaseRate:
              data.client_renewal_rate?.client_file_name_base_rate,
            clientFileBaseRate: data.client_renewal_rate?.client_file_base_rate,
            clientVersionCustomRate:
              data.client_renewal_rate?.client_version_custom_rate,
            clientFileNameCustomRate:
              data.client_renewal_rate?.client_file_name_custom_rate,
            clientFileCustomRate:
              data.client_renewal_rate?.client_file_custom_rate,
            clientErrorNameCustomRate:
              data.client_renewal_rate?.client_error_name_custom_rate,
            clientErrorFileCustomRate:
              data.client_renewal_rate?.client_error_file_custom_rate,
            clientRateVersionCustom3lc:
              data.client_renewal_rate.client_rate_version_custom_3lc,
            clientRatePriceType:
              data.client_renewal_rate.client_rate_price_type,
            clientRateInsuranceType:
              data.client_renewal_rate.client_rate_insurance_type || "",
            clientRateInsuranceValuePercentage:
              data.client_renewal_rate.client_rate_insurance_value_percentage || 0
          })
        : new ClientRenewalRate(),
      clientOdooId: data.client_odoo_id,
      clientCompanySize: startCase(data.client_company_size),
      clientTax: new ClientTax({
        NIK: data.client_nik_id,
        NIKName: data.client_nik_name,
        NIKAddress: data.client_nik_address,
        isTaxPayer: data.client_efaktur_with_nik,
        taxNumber: isV1
          ? data.client_tax_number
          : data.client_tax_number.replaceAll(".", "").replaceAll("-", ""),
        taxPayerAddress: data.client_taxpayer_address,
        taxPayerName: data.client_taxpayer_name,
        transactionCode: data.client_tax_transaction_no
      }),
      clientBilling: new ClientBilling({
        noWhatsApp: data.client_billing_wa_number.startsWith("0")
          ? data.client_billing_wa_number.substr(1)
          : data.client_billing_wa_number.substr(2),
        billingSchedule: data.client_billing_schedule_so.toLowerCase(),
        timeRangePayment: data.client_billing_payment_period.replace(
          " Days",
          ""
        ),
        email: data.client_billing_email
          ? data.client_billing_email.split(",")
          : [],
        startDate: data.client_contract_start_date,
        endDate: data.client_contract_end_date,
        attachmentUrl: parsingLinkGcpToAws(data.client_contract_attachment_url)
      }),
      bankSettlement: new BankDetail({
        bankLabel: `VA ${startCase(data.client_va_bank_name)}`,
        bankName: data.client_va_bank_name,
        accountNo: data.client_va_bank_number,
        accountName: data.client_va_bank_account_name,
        logo: `${ConfigBucketStorage.config_bucket_bank_logo.getValue()}${
          data.client_va_bank_name
        }.png`
      }),
      clientPadFee: data.client_pad_fee || 0,
      clientPadFeePercentage: String(data.client_pad_fee).replaceAll(".", ","),
      rateActiveDate: data.rate_active_date,
      clientPaymentMethod: clientPaymentMethodData.find(
        (payment: OptionsClass) => payment.value === data.client_payment_method
      ),
      clientCodConfigAmount: clientCodConfigAmountData.find(
        (cod: OptionsClass) => cod.value === data.client_cod_config_amount
      ),
      clientCodShipmentDiscount: data.client_cod_shipment_discount,
      clientCodShipmentDiscountPercentage: String(
        data.client_cod_shipment_discount
      ).replaceAll(".", ","),
      clientBranchDoPaymentType: data.client_branch_do_payment_type,
      isReturnConfig: data.client_meta?.is_return_config || false,
      returnConfigLimit: data.client_meta?.return_config_limit || 0,
      returnConfigReasonIds: data.client_meta?.return_config_reason_ids || "",
      priceType: data.price_type,
      rateVersionCustom3lc: data.rate_version_custom_3lc,
      insuranceType: data.client_meta?.insurance_type || "",
      insuranceValuePercentage: data.client_meta?.insurance_value_percentage || "",
      dataProcessedCurrent: data.data_processed_current,
      dataProcessedRenewal: data.data_processed_renewal,
      totalDataCurrent: data.total_data_current,
      totalDataRenewal: data.total_data_renewal
    });
  }

  public convertChangeDataFromApi(result: AxiosResponse<any>): ResponsePayload {
    const { data } = result;
    return new ResponsePayload({
      success: data.success,
      message: data.message
    });
  }
}
