import { StiDestApiRepository } from "@/app/infrastructures/repositories/api/StiDestApiRepository";
import { injectable } from "tsyringe";
import { ChangeStiDestRequestInterface } from "@/data/payload/contracts/StiDestRequest";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import {
  StiDestSttList,
  StiDestManifestData,
  StiDestDetail,
  StiDestEntities,
  STIDestReverseScan,
  RequestListSTIDestTemporary,
  ListSTIDestTemporary
} from "@/domain/entities/StiDest";
import { StiDestListRequest } from "@/data/payload/api/StiDestApiRequest";

@injectable()
export class StiDestPresenter {
  private repository: StiDestApiRepository;

  constructor(repository: StiDestApiRepository) {
    this.repository = repository;
  }

  public getListStiDest(params: StiDestListRequest): Promise<StiDestEntities> {
    return this.repository.getListStiDest(params);
  }

  public getDetailStiDest(id: number, version = "v1"): Promise<StiDestDetail> {
    return this.repository.getDetailStiDest(id, version);
  }

  public async getStiDestSttList(bagOrStt: string): Promise<StiDestSttList> {
    return this.repository.getStiDestSttList(bagOrStt);
  }

  public async getStiDestSttListLilo(bagNo: string): Promise<StiDestSttList> {
    return this.repository.getStiDestSttListLilo(bagNo);
  }

  public async getStiDestManifestDetail(
    id: number
  ): Promise<StiDestManifestData> {
    return this.repository.getStiDestManifestDetail(id);
  }

  public async generateStiDest(
    payload: ChangeStiDestRequestInterface
  ): Promise<ResponsePayload> {
    return await this.repository.generateStiDest(payload);
  }

  public async stiDestReverseScan(
    payload: ChangeStiDestRequestInterface
  ): Promise<STIDestReverseScan> {
    return await this.repository.stiDestReverseScan(payload);
  }

  public async stiDestProcessReverseScan(): Promise<ResponsePayload> {
    return await this.repository.stiDestProcessReverseScan();
  }

  public async getListStiDestTemporary(
    params: RequestListSTIDestTemporary
  ): Promise<ListSTIDestTemporary> {
    return await this.repository.stiDestTemporaryScan(params);
  }

  public async downloadStiDest(id: number, version = "v1"): Promise<any> {
    return this.repository.downloadStiDest(id, version);
  }
}
