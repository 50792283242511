import { injectable } from "tsyringe";
import { PodDexApiRepository } from "@/app/infrastructures/repositories/api/PodDexApiRepository";
import { CheckPaymentStatus, ReasonData } from "@/domain/entities/PodDex";
import {
  CheckPaymentStatusRequestInterface,
  UpdatePodDexRequestInterface
} from "@/data/payload/contracts/PodDexRequest";
import {
  ResponsePayload,
  Entities,
  ResponsePayloadV2
} from "@/domain/entities/ResponsePayload";
import {
  ReasonListDex,
  RequestListPodDex
} from "@/data/payload/api/PodDexRequest";
import { ApiRequestList } from "@/domain/entities/MainApp";

@injectable()
export class PodDexPresenter {
  private repository: PodDexApiRepository;

  constructor(repo: PodDexApiRepository) {
    this.repository = repo;
  }

  public getListReason(params: ReasonListDex): Promise<ReasonData[]> {
    return this.repository.getListReason(params);
  }

  public getPodDownload(params: ApiRequestList): Promise<ResponsePayloadV2> {
    return this.repository.getPodDownload(params);
  }

  public updatePodDex(
    payload: UpdatePodDexRequestInterface
  ): Promise<ResponsePayload> {
    return this.repository.updatePodDex(payload);
  }
  public getPodDexList(params: RequestListPodDex): Promise<Entities> {
    return this.repository.getPodDexList(params);
  }
  public checkPaymentStatus(
    payload: CheckPaymentStatusRequestInterface
  ): Promise<CheckPaymentStatus> {
    return this.repository.checkPaymentStatus(payload);
  }
}
