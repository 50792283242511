/* eslint-disable @typescript-eslint/camelcase */
import {
  AddBookingRequestInterface,
  EditBookingRequestInterface,
  GetEstimationPriceInterface
} from "../contracts/BookingRequest";
import { QueryParamsEntities } from "@/domain/entities/MainApp";
import moment from "moment";
import { ConvertObjectCamelToSnakeCase } from "@/app/infrastructures/misc/Utils";
import {
  PayloadPosterProduct,
  PiecesPosterProduct
} from "@/domain/entities/Booking";
import toFormDataUtils from "@/app/infrastructures/misc/common-library/ToFormDataUtils";
import { getHubData } from "@/app/infrastructures/misc/Cookies";
const hubObj = {
  hub_id:  getHubData()?.hubId || 0,
  hub_name: getHubData()?.hubName || "",
  hub_origin_city: getHubData()?.originCity || "",
  hub_district_code: getHubData()?.hubDistrictCode || ""
}
export class AddCustomer implements AddBookingRequestInterface {
  customerName: string;
  customerPhone: string;
  customerAddress: string;

  constructor(
    customerName: string,
    customerPhone: string,
    customerAddress: string
  ) {
    this.customerName = customerName;
    this.customerPhone = customerPhone;
    this.customerAddress = customerAddress;
  }

  toJSON(): string {
    return JSON.stringify({
      customer_name: this.customerName,
      customer_phone: this.customerPhone,
      customer_address: this.customerAddress
    });
  }
}

export class AddManualBooking implements AddBookingRequestInterface {
  sttManual = "";
  referenceExternal = "";
  productName = "";
  originCity = "";
  originDistrict = "";
  destinationCity = "";
  destinationDistrict = "";
  phoneSender = "";
  phoneReceiver = "";
  sender = "";
  receiver = "";
  addressSender = "";
  addressReceiver = "";
  addressReceiverType = "";
  sttGrossWeight = 0;
  sttVolumeWeight = 0;
  sttChargeableWeight = 0;
  commodityCode = "";
  insurance = "";
  statusGoods = "";
  estimationPrice = "";
  taxNumber = "";
  isWoodpack = false;
  isCOD = false;
  isDFOD = false;
  sttAttachFiles = [];
  sttCommodityDetail = "";
  isSaveSender = false;
  isSaveReceiver = false;
  sttPieces = [];
  isDO = false;
  isMixpack = false;
  sttPiecePerPack = 0;
  sttNextCommodity = "";
  sttCodAmount = 0;
  postalCodeDestination = "";
  elexysTariff: any = {};
  isPAD = false;
  sttWeightAttachFiles: string[] = [];
  sttRecipientEmail = "";
  sttKtpImage = "";
  sttTaxImage = "";
  goodsNames = [];

  constructor(fields?: Partial<AddManualBooking>) {
    Object.assign(this, fields);
  }

  toJSON(): string {
    const objectPayload = {
      stt: {
        stt_no: this.sttManual,
        is_mixpack: this.isMixpack,
        stt_shipment_id: "",
        stt_no_ref_external: this.referenceExternal,
        stt_tax_number: this.taxNumber,
        stt_goods_estimate_price: this.estimationPrice,
        stt_goods_status: this.statusGoods,
        stt_origin_city_id: this.originCity,
        stt_origin_district_id: this.originDistrict,
        stt_destination_city_id: this.destinationCity,
        stt_destination_district_id: this.destinationDistrict,
        stt_sender_name: this.sender,
        stt_sender_phone: this.phoneSender,
        stt_sender_address: this.addressSender,
        stt_is_save_sender: this.isSaveSender,
        stt_recipient_name: this.receiver,
        stt_recipient_address: this.addressReceiver,
        stt_recipient_address_type: this.addressReceiverType,
        stt_recipient_phone: this.phoneReceiver,
        stt_is_save_recipient: this.isSaveReceiver,
        stt_insurance_type: this.insurance,
        stt_product_type: this.productName,
        stt_gross_weight: this.sttGrossWeight,
        stt_volume_weight: this.sttVolumeWeight,
        stt_chargeable_weight: this.sttChargeableWeight,
        stt_commodity_code: this.commodityCode,
        stt_client_stt_id: 0,
        stt_vendor_stt_id: 0,
        stt_is_cod: this.isCOD,
        stt_is_dfod: this.isDFOD,
        stt_attach_files: this.sttAttachFiles,
        stt_commodity_detail: this.sttCommodityDetail,
        stt_is_do: this.isDO,
        stt_is_woodpacking: this.isWoodpack,
        stt_pieces: this.sttPieces,
        stt_piece_per_pack: this.sttPiecePerPack,
        stt_next_commodity: this.sttNextCommodity,
        postal_code_destination: this.postalCodeDestination,
        is_pad: this.isPAD,
        stt_recipient_email: this.sttRecipientEmail,
        stt_ktp_image: this.sttKtpImage,
        stt_tax_image: this.sttTaxImage
      },
      stt_weight_attach_files: this.sttWeightAttachFiles,
      goods_names: this.goodsNames
    };
    if (this.isCOD) {
      Object.assign(objectPayload.stt, {
        stt_cod_amount: this.sttCodAmount
      });
    }
    if (this.isDFOD) {
      Object.assign(objectPayload.stt, {
        stt_cod_amount: 0
      });
    }
    if (
      process.env.VUE_APP_MIGRATE_FROM_ELEXYS === "true" &&
      this.elexysTariff
    ) {
      const elexysTariffPayload = {
        elexys_tariff: {
          se_publish_rate: this.elexysTariff.se_elexys_publish_rate,
          se_forward_rate: this.elexysTariff.se_elexys_forward_rate,
          se_shipping_surcharge_rate: this.elexysTariff
            .se_elexys_shipping_surcharge_rate,
          se_commodity_surcharge_rate: this.elexysTariff
            .se_elexys_commodity_surcharge_rate,
          se_heavyweight_surcharge_rate: this.elexysTariff
            .se_elexys_heavyweight_surcharge_rate,
          se_document_surcharge_rate: this.elexysTariff
            .se_elexys_document_surcharge_rate,
          se_insurance_rate: this.elexysTariff.se_elexys_insurance_rate,
          se_wood_packing_rate: this.elexysTariff.se_elexys_woodpacking_rate,
          se_total_tariff: this.elexysTariff.se_elexys_total_tariff,
          se_etd: this.elexysTariff.se_elexys_etd,
          se_gross_weight: this.elexysTariff.se_elexys_gross_weight,
          se_weight: this.elexysTariff.se_elexys_weight,
          se_is_cod_area: this.elexysTariff.se_elexys_is_cod_area,
          se_volume_weight: this.elexysTariff.se_elexys_volume_weight,
          se_chargeable_weight: this.elexysTariff.se_elexys_chargeable_weight,
          se_elexys_total_normal_tariff: this.elexysTariff
            .se_elexys_total_normal_tariff,
          se_elexys_total_basic_tariff: this.elexysTariff
            .se_elexys_total_basic_tariff
        }
      };
      Object.assign(objectPayload, elexysTariffPayload);
    }
    return JSON.stringify(objectPayload);
  }
}

export class GetEstimationPrice implements GetEstimationPriceInterface {
  originId = "";
  destinationId = "";
  commodityId = 0;
  productType = "";
  accountType = "";
  accountRefId = 0;
  goodsPrice = 0;
  insuranceType = "";
  isWoodpacking = false;
  isHaveTaxId = false;
  pieces = [];
  isCod = false;
  isDfod = false;
  codHandling = "";
  codAmount = 0;
  isDisablePromo = false;
  promoAppliedTo = "";
  isPad = false;
  shipmentPrefix = "";
  hideError = false;
  discountFavoritePercentage = 0;

  constructor(fields?: Partial<GetEstimationPrice>) {
    Object.assign(this, fields);
  }

  toJSON(): string {
    return JSON.stringify(ConvertObjectCamelToSnakeCase(this));
  }
}

export class CancelBooking implements AddBookingRequestInterface {
  sttNumber = "";
  reason = "";
  isGenerateNewStt = false;
  sttBookedBy = 0;
  sttDestinationDistrictId = "";
  sttDestinationCityId = "";
  sttReceiptName = "";
  sttReceiptAddress = "";
  sttReceiptAddressType = "";
  sttReceiptPhone = "";
  sttPiecePerPack = 0;
  sttNextCommodity: string | number = "";

  constructor(fields?: Partial<CancelBooking>) {
    Object.assign(this, fields);
  }

  toJSON(): string {
    const jsonObj = {
      stt_no: this.sttNumber,
      reason: this.reason,
      is_generate_new_stt: this.isGenerateNewStt,
      ...hubObj
    };
    if (this.isGenerateNewStt) {
      Object.assign(jsonObj, {
        stt_booked_by: this.sttBookedBy,
        stt_destination_district_id: this.sttDestinationDistrictId,
        stt_destination_city_id: this.sttDestinationCityId,
        stt_receipt_name: this.sttReceiptName,
        stt_receipt_address: this.sttReceiptAddress,
        stt_receipt_address_type: this.sttReceiptAddressType,
        stt_receipt_phone: "62" + this.sttReceiptPhone,
        stt_piece_per_pack: Number(this.sttPiecePerPack),
        stt_next_commodity: String(this.sttNextCommodity)
      });
    }
    return JSON.stringify(jsonObj);
  }
}

export class EditBooking implements EditBookingRequestInterface {
  id = 0;
  isMixpack = false;
  sttNoRefExternal = "";
  sttTaxNumber = "";
  sttGoodsEstimatePrice = 0;
  sttGoodsStatus = "";
  sttOriginCityId = "";
  sttOriginDistrictId = "";
  sttDestinationCityId = "";
  sttDestinationDistrictId = "";
  sttSenderName = "";
  sttSenderPhone = "";
  sttSenderAddress = "";
  sttIsSaveSender = false;
  sttRecipientName = "";
  sttRecipientAddress = "";
  sttRecipientAddressType = "";
  sttRecipientPhone = "";
  sttIsSaveRecipient = false;
  sttInsuranceType = "";
  sttProductType = "";
  sttCommodityCode = "";
  sttClientSttId = 0;
  sttVendorSttId = 0;
  sttIsCod = false;
  sttIsDfod = false;
  sttWeightAttachFiles = [];
  sttAttachFiles = [];
  sttCommodityDetail = "";
  codHandling = "";
  sttIsDo = false;
  sttIsWoodpacking = false;
  sttPieces = [];
  sttPiecePerPack = 0;
  sttNextCommodity = "";
  sttCodAmount = 0;
  postalCodeDestination = "";
  sttIsPad = false;
  discountFavoritePercentage = 0;
  sttRecipientEmail = "";
  sttKtpImage = "";
  sttTaxImage = "";
  constructor(fields?: Partial<EditBooking>) {
    Object.assign(this, fields);
  }

  toJSON(): string {
    return JSON.stringify({
      is_mixpack: this.isMixpack,
      stt_no_ref_external: this.sttNoRefExternal,
      stt_tax_number: this.sttTaxNumber,
      stt_goods_estimate_price: this.sttGoodsEstimatePrice,
      stt_goods_status: this.sttGoodsStatus,
      stt_origin_city_id: this.sttOriginCityId,
      stt_origin_district_id: this.sttOriginDistrictId,
      stt_destination_city_id: this.sttDestinationCityId,
      stt_destination_district_id: this.sttDestinationDistrictId,
      stt_sender_name: this.sttSenderName,
      stt_sender_phone: this.sttSenderPhone,
      stt_sender_address: this.sttSenderAddress,
      stt_is_save_sender: this.sttIsSaveSender,
      stt_recipient_name: this.sttRecipientName,
      stt_recipient_address: this.sttRecipientAddress,
      stt_recipient_address_type: this.sttRecipientAddressType,
      stt_recipient_phone: this.sttRecipientPhone,
      stt_is_save_recipient: this.sttIsSaveRecipient,
      stt_insurance_type: this.sttInsuranceType,
      stt_product_type: this.sttProductType,
      stt_commodity_code: this.sttCommodityCode,
      stt_client_stt_id: this.sttClientSttId,
      stt_vendor_stt_id: this.sttVendorSttId,
      stt_is_cod: this.sttIsCod,
      stt_is_dfod: this.sttIsDfod,
      stt_weight_attach_files: this.sttWeightAttachFiles,
      stt_attach_files: this.sttAttachFiles,
      stt_commodity_detail: this.sttCommodityDetail,
      cod_handling: this.codHandling,
      stt_is_pad: this.sttIsPad,
      stt_is_do: this.sttIsDo,
      stt_is_woodpacking: this.sttIsWoodpacking,
      stt_pieces: this.sttPieces,
      stt_piece_per_pack: this.sttPiecePerPack,
      stt_next_commodity: this.sttNextCommodity,
      stt_cod_amount: this.sttCodAmount,
      postal_code_destination: this.postalCodeDestination,
      discount_favorite_percentage: this.discountFavoritePercentage,
      ...hubObj,
      stt_recipient_email: this.sttRecipientEmail,
      stt_ktp_image: this.sttKtpImage,
      stt_tax_image: this.sttTaxImage
    });
  }
}

export class AddBookingCorporate implements AddBookingRequestInterface {
  sttClient = 0;
  sttNo = "";
  sttShipmentId = "";
  sttNoRefExternal = "";
  sttTaxNumber = "";
  sttGoodsEstimatePrice = 0;
  sttGoodsStatus = "";
  sttOriginCityId = "";
  sttOriginDistrictId = "";
  sttDestinationCityId = "";
  sttDestinationDistrictId = "";
  sttSenderName = "";
  sttSenderPhone = "";
  sttSenderAddress = "";
  sttRecipientName = "";
  sttRecipientAddress = "";
  sttRecipientAddressType = "";
  sttRecipientPhone = "";
  sttInsuranceType = "";
  sttProductType = "";
  sttCommodityCode = "";
  sttClientSttId = 0;
  sttVendorSttId = 0;
  sttIsCod = false;
  sttIsDfod = false;
  sttIsWoodpacking = false;
  sttPieces: any = [];
  sttPiecePerPack = 0;
  sttNextCommodity = "";
  sttCodAmount = 0;
  postalCodeDestination = "";
  elexysTariff?: any = "";
  shipmentPackageId?: any = "";
  otherShipperTicketCode?: any = "";
  sttIsPad = false;
  sttRecipientEmail = "";
  sttKtpImage = "";
  sttTaxImage = "";
  sttWeightAttachFiles: string[] = [];
  sttCommodityDetail: any = "";
  goodsNames: string[] = [];
  sttAttachFiles: any = "";
  constructor(fields?: Partial<AddBookingCorporate>) {
    Object.assign(this, fields);
  }

  toJSON(): string {
    const objectPayload = {
      stt_client: this.sttClient,
      stt: {
        stt_no: this.sttNo,
        stt_shipment_id: this.sttShipmentId,
        stt_no_ref_external: this.sttNoRefExternal,
        stt_tax_number: this.sttTaxNumber,
        stt_goods_estimate_price: this.sttGoodsEstimatePrice,
        stt_goods_status: this.sttGoodsStatus,
        stt_origin_city_id: this.sttOriginCityId,
        stt_origin_district_id: this.sttOriginDistrictId,
        stt_destination_city_id: this.sttDestinationCityId,
        stt_destination_district_id: this.sttDestinationDistrictId,
        stt_sender_name: this.sttSenderName,
        stt_sender_phone: this.sttSenderPhone,
        stt_sender_address: this.sttSenderAddress,
        stt_recipient_name: this.sttRecipientName,
        stt_recipient_address: this.sttRecipientAddress,
        stt_recipient_address_type: this.sttRecipientAddressType,
        stt_recipient_phone: this.sttRecipientPhone,
        stt_insurance_type: this.sttInsuranceType,
        stt_product_type: this.sttProductType,
        stt_commodity_code: this.sttCommodityCode,
        stt_client_stt_id: this.sttClientSttId,
        stt_vendor_stt_id: this.sttVendorSttId,
        stt_is_cod: this.sttIsCod,
        stt_is_dfod: this.sttIsDfod,
        stt_is_pad: this.sttIsPad,
        stt_is_woodpacking: this.sttIsWoodpacking,
        stt_pieces: this.sttPieces,
        stt_piece_per_pack: this.sttPiecePerPack,
        stt_next_commodity: this.sttNextCommodity,
        stt_cod_amount: this.sttCodAmount,
        postal_code_destination: this.postalCodeDestination,
        stt_recipient_email: this.sttRecipientEmail,
        stt_ktp_image: this.sttKtpImage,
        stt_tax_image: this.sttTaxImage,
        stt_commodity_detail: this.sttCommodityDetail,
        stt_attach_files: this.sttAttachFiles
      },
      stt_weight_attach_files: this.sttWeightAttachFiles,
      goods_names: this.goodsNames
    };

    // kurir rekomendasi
    if (this.shipmentPackageId || this.sttNoRefExternal.startsWith("TKP01")) {
      const ticketCode = {
        ticket_code: this.sttNoRefExternal.startsWith("TKP01") ? this.sttNoRefExternal : this.shipmentPackageId,
      }
      Object.assign(objectPayload, ticketCode);
    }

    if (this.otherShipperTicketCode) {
      const shipperTicketCode = {
        other_shipper_ticket_code: this.otherShipperTicketCode
      }
      Object.assign(objectPayload, shipperTicketCode);
    }

    // elexys
    if (
      process.env.VUE_APP_MIGRATE_FROM_ELEXYS === "true" &&
      this.elexysTariff
    ) {
      const elexysTariffPayload = {
        elexys_tariff: {
          se_publish_rate: this.elexysTariff.se_elexys_publish_rate,
          se_forward_rate: this.elexysTariff.se_elexys_forward_rate,
          se_shipping_surcharge_rate: this.elexysTariff
            .se_elexys_shipping_surcharge_rate,
          se_commodity_surcharge_rate: this.elexysTariff
            .se_elexys_commodity_surcharge_rate,
          se_heavyweight_surcharge_rate: this.elexysTariff
            .se_elexys_heavyweight_surcharge_rate,
          se_document_surcharge_rate: this.elexysTariff
            .se_elexys_document_surcharge_rate,
          se_insurance_rate: this.elexysTariff.se_elexys_insurance_rate,
          se_wood_packing_rate: this.elexysTariff.se_elexys_woodpacking_rate,
          se_total_tariff: this.elexysTariff.se_elexys_total_tariff,
          se_etd: this.elexysTariff.se_elexys_etd,
          se_gross_weight: this.elexysTariff.se_elexys_gross_weight,
          se_weight: this.elexysTariff.se_elexys_weight,
          se_is_cod_area: this.elexysTariff.se_elexys_is_cod_area,
          se_volume_weight: this.elexysTariff.se_elexys_volume_weight,
          se_chargeable_weight: this.elexysTariff.se_elexys_chargeable_weight,
          se_elexys_total_normal_tariff: this.elexysTariff
            .se_elexys_total_normal_tariff,
          se_elexys_total_basic_tariff: this.elexysTariff
            .se_elexys_total_basic_tariff
        }
      };
      Object.assign(objectPayload, elexysTariffPayload);
    }
    return JSON.stringify(objectPayload);
  }
}

export class AddBookingCorporatePieces {
  stt_piece_length: number;
  stt_piece_width: number;
  stt_piece_height: number;
  stt_piece_gross_weight: number;

  constructor(
    stt_piece_length: number,
    stt_piece_width: number,
    stt_piece_height: number,
    stt_piece_gross_weight: number
  ) {
    this.stt_piece_length = stt_piece_length;
    this.stt_piece_width = stt_piece_width;
    this.stt_piece_height = stt_piece_height;
    this.stt_piece_gross_weight = stt_piece_gross_weight;
  }
}

export class ValidateBookingPhone implements AddBookingRequestInterface {
  sttShipmentId: string;
  sttSenderPhone: string;
  sttRecipientPhone: string;

  constructor(
    sttShipmentId: string,
    sttSenderPhone: string,
    sttRecipientPhone: string
  ) {
    this.sttShipmentId = sttShipmentId;
    this.sttSenderPhone = sttSenderPhone;
    this.sttRecipientPhone = sttRecipientPhone;
  }

  toJSON(): string {
    return JSON.stringify({
      stt_shipment_id: this.sttShipmentId,
      stt_sender_phone: this.sttSenderPhone,
      stt_recipient_phone: this.sttRecipientPhone
    });
  }
}

export class RequestListBooking {
  cache = false;
  search = "";
  page = 1;
  limit = 10;
  isTotalData = false;
  pmcStatus = "";
  pmcClientId = 0;
  bookedStart = "";
  bookedEnd = "";
  originDistrictCode = "";
  destinationDistrictCode = "";
  originCityCode = "";
  destinationCityCode = "";
  clientPartnerId = "";
  productType: any = "";
  status = "";
  isCod: any = "";
  isPad: any = "";
  isDfod = false;
  insuranceType: any = "";
  version = "1";
  paymentStatus = "paid";
  processState = "";
  minPayDate = "";
  maxPayDate = "";
  startUpdated = "";
  endUpdated = "";
  destinationCityId = "";
  originCityId = "";
  isPaidUnpaid = true;
  orderBy = "";
  sortBy = "";
  from = "";
  originDistrictId = "";
  destinationDistrictId = "";
  senderPhoneNumber = "";
  recipientPhoneNumber = "";

  constructor(fields?: Partial<RequestListBooking>) {
    Object.assign(this, fields);
  }

  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
}

export class RequestEstimationServiceLevelAgreement {
  cache = false;
  startDate = "";
  min = 0;
  max = 0;
  productType = "";

  constructor(fields?: Partial<RequestEstimationServiceLevelAgreement>) {
    Object.assign(this, fields);
    this.startDate = moment(fields?.startDate).format("YYYY-MM-DD HH:mm:ss");
  }

  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
}

export class RequestEstimateSlaDetail {
  cache = false;
  originId = "";
  destinationId = "";
  product = "";
  accountType = "";
  slaType = "district-district";

  constructor(fields?: Partial<RequestEstimateSlaDetail>) {
    Object.assign(this, fields);
  }

  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
}

const onMappingPieces = (params: any) => {
  const isDefault = params[0]?.pieceGrossWeight === 0 && params[0]?.pieceLength === 10 && params[0]?.pieceWidth === 10 && params[0]?.pieceHeight === 10;
  if (isDefault) {
    return [ConvertObjectCamelToSnakeCase(new PiecesPosterProduct())]
  }else {
    return params.map((key: any) => ConvertObjectCamelToSnakeCase(new PiecesPosterProduct({
      pieceLength: key.pieceLength,
      pieceWidth: key.pieceWidth,
      pieceHeight: key.pieceHeight,
      pieceGrossWeight: key.pieceGrossWeight,
    })))
  }
}
export class SubmitPosterProductApiRequest {
  payload: PayloadPosterProduct = new PayloadPosterProduct();
  constructor(fields?: Partial<SubmitPosterProductApiRequest>) {
    Object.assign(this, fields);
  }

  public toJSON(): string {
    return JSON.stringify(
      ConvertObjectCamelToSnakeCase({
        ...this.payload,
        pieces: onMappingPieces(this.payload.pieces),
        commodity: ConvertObjectCamelToSnakeCase(this.payload.commodity)
      })
    );
  }
}

export class UploadHydraFileRequest {
  attachFile = "";
  folderPath = "";
  constructor(fields?: Partial<UploadHydraFileRequest>) {
    Object.assign(this, fields);
  }

  toFormData(): FormData {
    return toFormDataUtils(ConvertObjectCamelToSnakeCase(this));
  }
}

export class DeleteHydraFileRequest {
  attachFiles: string[] = [];
  folderPath = "";
  constructor(fields?: Partial<DeleteHydraFileRequest>) {
    Object.assign(this, fields);
  }

  public toJSON(): string {
    return JSON.stringify(ConvertObjectCamelToSnakeCase(this));
  }
}
