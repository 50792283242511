import { ALLOWED } from "@/app/infrastructures/misc/RolePermission";

const Layout = () =>
  import(/* webpackChunkName: "Layout" */ "@/app/ui/layout/index.vue");
const Claim = () =>
  import(
    /* webpackChunkName: "PortalSender" */ "@/app/ui/views/claim/index.vue"
  );
const CekTarif = () =>
  import(
    /* webpackChunkName: "PortalSender" */ "@/app/ui/views/shipment/rates-check/index.vue"
  );
const Dashboard = () =>
  import(
    /* webpackChunkName: "PortalSender" */ "@/app/ui/views/dashboard/index.vue"
  );
const SaldoPoin = () =>
  import(
    /* webpackChunkName: "PortalSender" */ "@/app/ui/views/saldo-poin/index.vue"
  );
const RiwayatPoinTab = () =>
  import(
    /* webpackChunkName: "PortalSender" */ "@/app/ui/views/saldo-poin/tabs/history-poin-tabs.vue"
  );
const RiwayatSaldoTab = () =>
  import(
    /* webpackChunkName: "PortalSender" */ "@/app/ui/views/saldo-poin/tabs/history-saldo-tabs.vue"
  );
const TrackDelivery = () =>
  import(
    /* webpackChunkName: "PortalSender" */ "@/app/ui/views/track-delivery/index.vue"
  );
const DeliveryTab = () =>
  import(
    /* webpackChunkName: "PortalSender" */ "@/app/ui/views/track-delivery/delivery-tab/index.vue"
  );
const DetailBooking = () =>
  import(
    /* webpackChunkName: "PortalSender" */ "@/app/ui/views/shipment/booking/detail-booking/detail.vue"
  );
const TrackingStt = () =>
  import(
    /* webpackChunkName: "PortalSender" */ "@/app/ui/views/shipment/tracking-stt-cargo/tracking-stt-tab/index.vue"
  );
const DetailClaim = () =>
  import(
    /* webpackChunkName: "PortalSender" */ "@/app/ui/views/claim/detail/detail.vue"
  );
const PerformanceDelivery = () =>
  import(
    /* webpackChunkName: "PortalSender" */ "@/app/ui/views/track-delivery/delivery-tab/index.vue"
  );
const CreateClaim = () =>
  import(
    /* webpackChunkName: "PortalSender" */ "@/app/ui/views/track-delivery/claim/index.vue"
  );

export default [
  {
    path: "/dashboard",
    component: Layout,
    name: "dashboard",
    meta: {
      icon: "home",
      children: false,
      title: "Halaman Utama",
      name: "Halaman Utama",
      gtmEventName: "sender_halaman_utama",
      gtmEventCustoms: {},
      gtmEventOption: ["userType", "timestamp", "userId"],
      permission: ALLOWED
    },
    children: [
      {
        path: "",
        name: "dashboard",
        component: Dashboard,
        meta: {
          header: false
        }
      }
    ]
  },
  {
    path: "/dashboard",
    component: Layout,
    name: "performa-pengiriman",
    meta: {
      hidden: true,
      parent: "Halaman Utama"
    },
    children: [
      {
        path: ":id",
        name: "performa-pengiriman",
        component: PerformanceDelivery,
        meta: {
          title: "Performa Pengiriman",
          customPath: true,
          header: true,
          isAuth: true,
          permission: ALLOWED
        }
      }
    ]
  },
  {
    path: "/balance",
    component: Layout,
    name: "balance",
    meta: {
      icon: "empty",
      children: false,
      title: "Saldo",
      name: "Saldo",
      hidden: true,
      isFreeze: true,
      permission: ALLOWED
    },
    children: [
      {
        path: "",
        name: "balance",
        redirect: "/balance/riwayat-poin",
        component: SaldoPoin,
        meta: {
          header: false
        },
        children: [
          {
            path: "riwayat-poin",
            name: "riwayat-poin",
            component: RiwayatPoinTab
          },
          {
            path: "riwayat-saldo",
            name: "riwayat-saldo",
            component: RiwayatSaldoTab
          }
        ]
      }
    ]
  },
  {
    path: "/cek-tarif",
    name: "cek-tarif",
    component: Layout,
    meta: {
      children: false,
      icon: "rates-check",
      title: "Cek Tarif",
      name: "Cek Tarif",
      gtmEventName: "sender_cek_tariff",
      gtmEventCustoms: {},
      gtmEventOption: ["userType", "timestamp", "userId"],
      permission: ALLOWED
    },
    children: [
      {
        path: "",
        name: "cek-tarif",
        component: CekTarif,
        meta: {
          header: true
        }
      }
    ]
  },
  {
    path: "/lacak-pengiriman",
    component: Layout,
    name: "lacak-pengiriman",
    meta: {
      children: false,
      icon: "track-delivery",
      title: "Pengiriman",
      name: "Pengiriman",
      gtmEventName: "sender_pengiriman",
      gtmEventCustoms: {},
      gtmEventOption: ["userType", "timestamp", "userId"],
      permission: ALLOWED,
      isAuth: true
    },
    children: [
      {
        path: "",
        name: "lacak-pengiriman",
        redirect: "/lacak-pengiriman/lacak-stt",
        component: TrackDelivery,
        meta: {
          header: true,
          icon: "track-delivery",
          title: "Pengiriman",
          name: "Pengiriman",
          permission: ALLOWED
        },
        children: [
          {
            path: "lacak-stt",
            name: "lacak-stt",
            component: TrackingStt,
            meta: {
              permission: ALLOWED
            }
          },
          {
            path: "pengiriman-aktif",
            name: "pengiriman-aktif",
            component: DeliveryTab,
            meta: {
              permission: ALLOWED
            }
          },
          {
            path: "riwayat-pengiriman",
            name: "riwayat-pengiriman",
            component: DeliveryTab,
            meta: {
              permission: ALLOWED
            }
          }
        ]
      },
      {
        path: ":id",
        name: "detail-lacak-pengiriman",
        component: DetailBooking,
        meta: {
          header: true,
          isAuth: true,
          title: "Pengiriman | Detail",
          customPath: "lacak-pengiriman",
          permission: ALLOWED
        }
      }
    ]
  },
  {
    path: "/lacak-pengiriman",
    component: Layout,
    name: "lacak-pengiriman-claim",
    meta: {
      title: "Pengiriman | Klaim",
      hidden: true,
      parent: "Pengiriman",
      permission: ALLOWED
    },
    children: [
      {
        path: ":id/ajukan-klaim",
        name: "lacak-pengiriman-klaim",
        component: CreateClaim,
        meta: {
          title: "Ajukan Klaim",
          header: true,
          isAuth: true,
          customPath: true
        }
      }
    ]
  },
  {
    path: "/klaim",
    name: "klaim",
    component: Layout,
    meta: {
      children: false,
      icon: "claim",
      title: "Klaim",
      name: "Klaim",
      gtmEventName: "sender_klaim_menu",
      gtmEventCustoms: {},
      gtmEventOption: ["userId", "userType", "timestamp"],
      permission: ALLOWED
    },
    children: [
      {
        path: "",
        name: "klaim",
        component: Claim,
        meta: {
          header: false
        }
      }
    ]
  },
  {
    path: "/klaim",
    component: Layout,
    name: "detail-klaim",
    meta: {
      hidden: true,
      parent: "Klaim"
    },
    children: [
      {
        path: ":id",
        name: "detail-klaim",
        component: DetailClaim,
        meta: {
          title: "Klaim | Detail",
          header: true,
          permission: ALLOWED
        }
      }
    ]
  }
];
