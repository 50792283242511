/* eslint-disable */
import { RequestBulkBooking } from "@/data/payload/api/BulkApiRequest";
import { BalanceReportMutationApiRequest } from "@/domain/entities/Balance";
import { RequestApiClientMappingList } from "@/domain/entities/Client";
import {
  RequestApiElexysFailedBookingList,
  RequestDownloadElexysFailedBooking,
} from "@/domain/entities/IntegrationMonitoring";
import {
  ApiRequestList,
  QueryParamsEntities,
  QueryParamsEntities2,
} from "@/domain/entities/MainApp";
import { RequestListOnProcessStt } from "@/data/payload/api/OnProcessApiRequest";
import {
  ReportApiRequest,
  ReportListDownloadRequest,
} from "@/domain/entities/Report";
import {
  RequestEstimateSlaDetail,
  RequestEstimationServiceLevelAgreement,
  RequestListBooking,
} from "@/data/payload/api/BookingRequest";
import { RequestListManualStt } from "@/data/payload/api/ManualSTTApiRequest";
import {
  CodDashboardCourierDetailRequest,
  DashboardConsoleRequest,
  DashboardDetailListRequest,
  DashboardDownloadRequest,
  DownloadSummaryCodRequest,
} from "@/data/payload/api/DashboardRequests";
import { RequestBookingCommissionList } from "@/data/payload/api/BookingCommissionApiRequest";
import { RequestListAnnouncement } from "@/data/payload/api/AnnouncementApiRequest";
import { RequestMessagingServiceConfigurationList } from "@/data/payload/api/MessagingServiceConfigurationApiRequest";
import {
  ReasonListDex,
  RequestListPodDex,
} from "@/data/payload/api/PodDexRequest";
import {
  RequestListHandover,
  RequestPartnerVendorList,
} from "@/data/payload/api/HandoverApiRequest";
import { STIReverseScanTemporaryListRequest, STISTTDueListRequest, STISTTDueSummaryListRequest, StiListRequest } from "@/data/payload/api/StiApiRequest";
import { RequestListUser } from "@/data/payload/api/UserApiRequest";
import { RequestListCommodity } from "@/data/payload/api/CommodityApiRequest";
import { RequestListCustomProcess } from "@/data/payload/api/CustomProcessApiRequest";
import { RequestListBalanceTransaction } from "@/domain/entities/BalanceTransaction";
import { RequestListPartner } from "@/data/payload/api/PartnerApiRequest";
import { RequestListLastBalance } from "@/data/payload/api/LastBalanceApiRequest";
import {
  RequestListClientManagement,
  RequestUpdateCurrentRates,
} from "@/data/payload/api/ClientManagementApiRequest";
import { RequestDownloadIncomingOutgoing } from "@/domain/entities/IncomingOutgoing";
import { RequestListPaymentMethod } from "@/data/payload/api/PaymentApiRequest";
import { RequestList } from "@/domain/entities/ResponsePayload";
import { BonusKomisiDetailRequest } from "@/data/payload/api/ProgressiveCommissionDashboardRequest";
import { ProgressiveCommissionListRequest } from "@/data/payload/api/ProgressiveCommissionConfigurationApiRequest";
import { StiDestListRequest } from "@/data/payload/api/StiDestApiRequest";
import { BaggingListRequest } from "@/data/payload/api/BaggingApiRequest";
import {
  CargoListRequest,
  CargoRecomendationsListRequest,
} from "@/data/payload/api/CargoApiRequest";
import { ClientBookingListParams } from "@/data/payload/api/BulkBookingApiRequest";
import { RequestBaggingGroupingList } from "@/data/payload/api/BaggingGroupingApiRequest";
import { ClaimListRequest } from "@/data/payload/api/ClaimRequest";
import { CheckTariffRequest } from "@/data/payload/api/TariffApiRequest";
import { TrackDeliveryListRequest } from "@/data/payload/api/TrackDeliveryApiRequest";
import { RequestHistoryPoinSaldoList } from "@/data/payload/api/SaldoPoinApiRequest";
import { CargoConfigListRequest } from "@/data/payload/api/CargoConfigurationApiRequest";
import isUrlUpdateVersion from "@/app/infrastructures/misc/common-library/IsUrlUpdateVersion";
import { IS_SENDER_ACCOUNT } from "@/app/infrastructures/misc/Constants";
import { RequestListRouteLeg } from "@/data/payload/api/LegApiRequest";
import {
  DistrictClientRequest,
  RegionListRequest,
} from "@/data/payload/api/LocationRequest";
import {
  RequestListSTISCSession,
  RequestListSTISC,
} from "@/domain/entities/StiSc";
import { ApiRequestCourierManagementList, ApiRequestCourierManagementListHistory, ApiRequestCourierVehicleList } from "@/data/payload/api/CourierManagementApiRequest";
import { RequestSttPaidUnread } from "@/domain/entities/STTPaidUnpaid";
import { FlagsDashboardCod } from "@/feature-flags/dashboard/cod";
import { StringValueToken } from "html2canvas/dist/types/css/syntax/tokenizer";
import { GetListBalanceApiRequest } from "@/data/payload/api/BalanceRequest";
import { RequestMiddlewareList } from "@/data/payload/api/MiddlewareApiRequest";
import { CashbackListRequest } from "@/domain/entities/CashbackConfiguration";
import { RequestTopupFeeConfigurationList } from "@/domain/entities/TopupFeeConfiguration";
import { RequestListSTIDestTemporary } from "@/domain/entities/StiDest";
import { UrgentDeliveryManagementPODListRequest, UrgentDeliveryManagementRTSListRequest, UrgentDeliveryManagementHALListRequest, UrgentDeliveryManagementPODListRequestDownload, UrgentDeliveryManagementRTSListRequestDownload, UrgentDeliveryManagementHALListRequestDownload } from "@/domain/entities/UrgentDeliveryManagement";

const IS_NGEN_V2: boolean = !!String(
  process.env.VUE_APP_NGEN_GENESIS_API_URL_VERSION
).match(/v2/gi);

export class Endpoints {
  public static baseUrl = process.env.VUE_APP_GENESIS_API_URL;
  public static baseUrlPrivate = process.env.VUE_APP_GENESIS_PRIVATE_API_URL;
  public static baseUrlAlgo = process.env.VUE_APP_ALGO_API_URL;
  public static baseUrlNGen = IS_NGEN_V2
    ? process.env.VUE_APP_NGEN_GENESIS_API_URL_DEV_V2
    : process.env.VUE_APP_NGEN_GENESIS_API_URL_DEV_V1;

  login(version: string): string {
    return `/horde/${version}/auth/login`;
  }

  forgotPassword() {
    return "/horde/v1/auth/reset_password";
  }

  resetPassword() {
    return "/horde/v1/auth/change_password_token";
  }

  logout() {
    return "/horde/v1/auth/logout";
  }

  verificationAccount() {
    return "/horde/v1/auth/verify";
  }

  getListBulk(
    activity_name: string,
    archive_name: string,
    status: string,
    page: number,
    limit: number
  ) {
    return `/horde/v1/bulk?search=${encodeURIComponent(
      activity_name
    )}&archive_type=${archive_name}&status=${status}&page=${page}&limit=${limit}`;
  }

  bulkUpload(): string {
    return "/horde/v1/bulk";
  }

  bulkCustomerUpload(): string {
    return "/horde/v1/customer/bulk";
  }

  editPassword(): string {
    return "/horde/v1/auth/change_password";
  }

  getProfile(isLocation?: boolean, cache?: boolean): string {
    return `/horde/v1/account/profile?cache=${cache}&is_location=${isLocation}`;
  }

  unpairToken() {
    return "/horde/v1/account/unpair-token";
  }

  getValidationAccount(): string {
    return "horde/v1/auth/validation-access";
  }

  pairToken() {
    return "/horde/v1/account/pair-token";
  }

  getAccountStatus(accountId: number): string {
    return `horde/v1/cs-account-status/${accountId}`;
  }
  postCsAccountLog(): string {
    return "horde/v1/cs-account-log";
  }

  getListCity(
    search: string,
    status: string,
    page: number,
    limit: number
  ): string {
    return `/horde/${isUrlUpdateVersion(
      "city"
    )}/city?search=${encodeURIComponent(
      search
    )}&status=${status}&page=${page}&limit=${limit}`;
  }
  getListCityV2(params: ApiRequestList): string {
    return `/horde/${isUrlUpdateVersion(
      "city"
    )}/city${params.newEndpoint ? '/list' : ''}?${params.toQueryString()}`;
  }

  getListCityFTZ(
    search: string,
    status: string,
    page: number,
    limit: number,
    freeTradeZone: string
  ): string {
    return `/horde/${isUrlUpdateVersion(
      "city"
    )}/city?search=${encodeURIComponent(
      search
    )}&status=${status}&page=${page}&limit=${limit}&free_trade_zone=${freeTradeZone}`;
  }

  getDetailCity(id: number): string {
    return `/horde/v1/city/${id}`;
  }

  getListCountry(
    search: string,
    status: string,
    page: number,
    limit: number
  ): string {
    return `/horde/v1/country?country_name=${encodeURIComponent(
      search
    )}&status=${status}&page=${page}&limit=${limit}`;
  }

  getDetailCountry(id: number): string {
    return `/horde/v1/country/${id}`;
  }

  getListDistrict(
    search: string,
    status: string,
    page: number,
    limit: number,
    excludeCountry: string,
    type: string,
    cityCode = "",
    cache: boolean
  ): string {
    const version = IS_SENDER_ACCOUNT ? "v1" : "v3";
    return `/horde/${version}/district?cache=${cache}&search=${encodeURIComponent(
      search
    )}&status=${status}&page=${page}&limit=${limit}&exclude_country=${excludeCountry}&type=${type}&city_code=${cityCode}`;
  }

  getDetailDistrict(id: number): string {
    return `/horde/v1/district/${id}`;
  }

  getDetailDistrictByDistrictCode(str: string) {
    return `/horde/v1/district/code/${str}`;
  }

  editCity(id: number): string {
    return `/horde/v1/city/${id}`;
  }

  editCountry(id: number): string {
    return `/horde/v1/country/${id}`;
  }

  getUrsaCode(ursaCode: string) {
    return `/horde/v1/district/ursa-code?ursa_code=${ursaCode}`;
  }

  editDistrict(id: number): string {
    return `/horde/v1/district/${id}`;
  }

  getListDistrictZipCode(
    search: string,
    page: number,
    limit: number
  ) {
    return `/horde/v1/district/zip-code?search=${encodeURIComponent(
      search
    )}&page=${page}&limit=${limit}`;
  }

  getListUser(params: RequestListUser): string {
    return `/horde/v${params.version}/account?${params.toQueryString()}`;
  }

  getDetailUser(id: number): string {
    return `/horde/v1/account/detail/${id}`;
  }

  editUser(): string {
    return `/horde/v1/account`;
  }

  getUnreadNotification(cache: boolean) {
    return `/horde/v1/notification/unread/me?cache=${cache}`;
  }

  getNotifications(
    page: number,
    limit: number,
    status: string,
    search: string,
    isPinned: number,
    sort: string,
    cache: boolean
  ) {
    return `/horde/v1/notification/me?cache=${cache}&search=${encodeURIComponent(
      search
    )}&limit=${limit}&page=${page}&sort=${sort || "desc"}`;
  }

  readNotification() {
    return "/horde/v1/notification/read";
  }

  detailNotification(id: number) {
    return `/horde/${isUrlUpdateVersion("announcement")}/announcement/${id}`;
  }

  editAnnouncement(id: number) {
    return `/horde/v1/announcement/${id}`;
  }

  getReference(account_type: string, search: string): string {
    const accountType = account_type.split("_");
    if (accountType.length > 1) {
      return `/horde/v1/account/reference?page=1&limit=10&account_type=${
        accountType[0]
      }&reference_client_type=${accountType[1]}&search=${encodeURIComponent(
        search
      )}`;
    }
    return `/horde/v1/account/reference?page=1&limit=10&account_type=${account_type}&search=${encodeURIComponent(
      search
    )}`;
  }

  getRole(account_type: string): string {
    const accountType = account_type.split("_");
    if (accountType.length > 1) {
      return `/horde/v1/account/role?page=1&limit=50&account_type=${accountType[0]}&account_role_client_type=${accountType[1]}`;
    }
    return `/horde/v1/account/role?page=1&limit=50&account_type=${account_type}`;
  }

  addUser(): string {
    return `/horde/v1/account`;
  }

  getListVendor(
    search: string,
    status: string,
    page: number,
    limit: number
  ): string {
    return `/horde/v1/vendor?page=${page}&limit=${limit}&search=${encodeURIComponent(
      search
    )}&status=${status}`;
  }

  getDetailVendor(id: number): string {
    return `/horde/v1/vendor/${id}`;
  }

  editVendor(): string {
    return "/horde/v1/vendor";
  }

  getCommodityList(params: RequestListCommodity): string {
    return `/horde/${params.version}/commodity?${params.toQueryString()}`;
  }

  getDetailCommodity(id: number): string {
    return `/horde/${isUrlUpdateVersion("commodity")}/commodity/${id}`;
  }

  editCommodity(): string {
    return "/horde/v1/commodity";
  }

  // embargo
  getEmbargo(page: number, limit: number, search: string, status: string) {
    return `/horde/v1/embargo?page=${page}&limit=${limit}&search=${encodeURIComponent(
      search
    )}&status=${status}`;
  }
  getDetailEmbargo(id: number): string {
    return `/horde/v1/embargo/${id}`;
  }
  addEmbargo(): string {
    return "/horde/v1/embargo";
  }
  editEmbargo(): string {
    return "/horde/v1/embargo";
  }
  stopEmbargo(id: number): string {
    return `/horde/v1/embargo/stop/${id}`;
  }
  checkEmbargo(): string {
    return "/horde/v1/embargo/check";
  }

  getLocationSearchList(page: number, limit: number, search: string) {
    return `horde/v1/location?page=${page}&limit=${limit}&search=${encodeURIComponent(
      search
    )}`;
  }

  getListLeg(
    page: number,
    limit: number,
    status: string,
    originId: string,
    destinationId: string,
    sortBy: string
  ) {
    return `/horde/v1/leg?page=${page}&limit=${limit}&status=${status}&origin_id=${originId}&destination_id=${destinationId}&sort_by=${sortBy}`;
  }

  getRoute(
    page: number,
    limit: number,
    status: string,
    originId: string,
    destinationId: string,
    sortBy: string
  ) {
    return `/horde/v1/route?page=${page}&limit=${limit}&status=${status}&origin_id=${originId}&destination_id=${destinationId}&sort_by=${sortBy}`;
  }

  getListRouteLeg(params: RequestListRouteLeg) {
    return `/horde/v2/route/leg?${params.toQueryString()}`;
  }

  getDetailRoute(id: number): string {
    return `/horde/v1/route/detail/${id}`;
  }

  getDetailLeg(id: number): string {
    return `/horde/v1/leg/${id}`;
  }

  editRoute(): string {
    return "/horde/v1/route";
  }

  getListTransport(
    search: string,
    type: string,
    status: string,
    page: number,
    limit: number
  ) {
    return `/horde/v1/transport?search=${encodeURIComponent(
      search
    )}&type=${type}&status=${status}&page=${page}&limit=${limit}`;
  }

  editLeg(): string {
    return "/horde/v1/leg";
  }

  getTransportDetail(id: number): string {
    return `/horde/v1/transport/${id}`;
  }

  editTransport(): string {
    return "/horde/v1/transport";
  }

  getListBalanceLimit(page: number, limit: number): string {
    return `/horde/v1/limit?page=${page}&limit=${limit}`;
  }

  getDetailBalanceLimit(id: number): string {
    return `/horde/v1/limit/${id}`;
  }

  getDetailBulk(id: number): string {
    return `/horde/v1/bulk/${id}`;
  }

  editBalanceLimit(): string {
    return `/horde/v1/limit`;
  }

  getVersionName(search: string) {
    return `/horde/v1/bulk/version?search=${encodeURIComponent(
      search
    )}&page=&limit=10&sort_by=asc`;
  }

  getListConfigPrice(page: number, search: string, status: string) {
    return `/horde/v2/configurable?page=${page}&limit=10&search=${encodeURIComponent(
      search
    )}&status=${status}&sort_by=ASC`;
  }

  getDetailInsurance(id: number) {
    return `/horde/v1/configurable/insurance/${id}`;
  }

  addBulkUploadRetailPrice() {
    return `/horde/v1/bulk/xlsx`;
  }

  getDetailConfigWoodpacking(id: number) {
    return `/horde/v1/configurable/woodpacking/${id}`;
  }

  getDetailHeavyWeightSurcharge(id: number) {
    return `/horde/v2/configurable/heavy_weight_surcharge/${id}`;
  }

  editConfigWoodpacking() {
    return `/horde/v1/configurable`;
  }

  editConfigurablePrice(version = "v1"): string {
    return `/horde/${version}/configurable`;
  }

  getDetailRTSHQ(id: number): string {
    return `/horde/v1/configurable/penalty_rtshq/${id}`;
  }

  getGoodsTaxList(
    cityCode: string,
    status: string,
    page: number,
    limit: number,
    commodityCode: string
  ) {
    return `/horde/v1/goods_tax?page=${page}&limit=${limit}&origin_city_code=${cityCode}&status=${status}&commodity_code=${commodityCode}`;
  }

  getDetailGoodsTax(id: number) {
    return `/horde/v1/goods_tax/${id}`;
  }

  addGoodsTax(): string {
    return `/horde/v1/goods_tax`;
  }

  editGoodsTax(): string {
    return `/horde/v1/goods_tax`;
  }

  getCommoditySurchargeList(
    search: string,
    status: string,
    page: number,
    limit: number
  ) {
    return `/horde/v1/commodity_surcharge?page=${page}&limit=${limit}&search=${encodeURIComponent(
      search
    )}&status=${status}&sort_by=ASC`;
  }

  getDetailCommoditySurcharge(id: number) {
    return `/horde/v1/commodity_surcharge/${id}`;
  }

  addCommoditySurcharge(): string {
    return "/horde/v1/commodity_surcharge";
  }

  getListBaseRates(
    search: string,
    status: string,
    page: number,
    limit: number
  ) {
    return `/horde/v1/rates?page=${page}&limit=${limit}&search=${encodeURIComponent(
      search
    )}&status=${status}`;
  }

  getListRates(search: string) {
    return `/horde/v1/rates/name?search=${encodeURIComponent(search)}`;
  }

  getDetailRates(id: number) {
    return `/horde/v1/rates/${id}`;
  }

  editBaseRates(id: number): string {
    return `/horde/v1/rates/${id}`;
  }

  getListExchangeRate(
    from: string,
    to: string,
    status: string,
    page: number,
    limit: number
  ) {
    return `/horde/v1/exchange_rate?page=${page}&limit=${limit}&from=${from}&to=${to}&status=${status}`;
  }

  getDetailExchangeRate(id: number) {
    return `/horde/v1/exchange_rate/${id}`;
  }

  getClient(
    page: number,
    limit: number,
    search: string,
    status: string,
    clientParentId?: number
  ) {
    return `/horde/v1/client?page=${page}&limit=${limit}&search=${encodeURIComponent(
      search
    )}&status=${status}${
      clientParentId ? `&client_parent_id=${clientParentId}` : ""
    }`;
  }

  getListClientRegistration(
    page: number,
    limit: number,
    search: string,
    status: string,
    type: string,
    isTotalData: boolean,
  ) {
    return `/horde/v1/client/registration?page=${page}&limit=${limit}&search=${encodeURIComponent(
      search
    )}&status=${status}&sort_by=desc&type=${type}&is_total_data=${isTotalData}`;
  }

  getClientRegistrationDetail(id: number) {
    return `/horde/v1/client/registration/${id}`;
  }

  getClientRegistrationDetailV2(id: number) {
    return `/horde/v2/client/registration/${id}`;
  }

  editClientRegistration() {
    return `/horde/v1/client/resubmit`;
  }

  editClientRegistrationV2() {
    return `/horde/v2/client/resubmit`;
  }

  editClientManagemetBasic(version = "v1") {
    return `/horde/${version}/client`;
  }

  addNewExchangeRate() {
    return `/horde/v1/exchange_rate`;
  }

  getListAnnouncement(params: RequestListAnnouncement) {
    return `/horde/${isUrlUpdateVersion(
      "announcement"
    )}/announcement?${params.toQueryString()}`;
  }

  getAnnouncementDetail(id: number) {
    return `/horde/${isUrlUpdateVersion("announcement")}/announcement/${id}`;
  }

  addAnnouncement() {
    return `/horde/v1/announcement`;
  }

  deleteAnnouncement(id: number) {
    return `/horde/v1/announcement/${id}`;
  }

  addClientRegistration(version = "v1") {
    return `/horde/${version}/client`;
  }

  createClientBranch() {
    return `/horde/v1/client/branch`;
  }

  getListClientManagement(
page: number, limit: number, search: string, searchCode: string, statusRates: string, statusClient: string, type: string, isTotalData: boolean  ) {
    return `/horde/v1/client/management?search=${encodeURIComponent(
      search
    )}&search_code=${encodeURIComponent(
      searchCode
    )}&status_rates=${statusRates}&status_client=${statusClient}&page=${page}&limit=${limit}&type=${type}&is_total_data=${isTotalData}`;
  }

  getClientManagementDetail(id: number) {
    return `/horde/v1/client/management/${id}`;
  }

  getClientManagementDetailV2(id: number) {
    return `/horde/v2/client/management/${id}`;
  }

  editRenewRateClientManagement() {
    return "/horde/v1/client/management/renew-rate";
  }

  editRenewRateClientManagementV2() {
    return "/horde/v2/client/management/renew-rate";
  }

  updateCurrentRates(): string {
    return "horde/v1/client/management/update-live-rate";
  }

  editCurrentRatePeriode() {
    return `/horde/v1/client/period`;
  }

  approveClientRegistration() {
    return `horde/v1/client/approve`;
  }

  rejectClientRegistration() {
    return `horde/v1/client/reject`;
  }

  getListManualSTT(params: RequestListManualStt) {
    return `/hydra/${isUrlUpdateVersion(
      "sttManual",
      "v" + params.version
    )}/stt_manual?${params.toQueryString()}`;
  }

  addGenerateManualSTT() {
    return `/hydra/v1/stt_manual`;
  }

  addRenewRates() {
    return `horde/v1/client/management/renew-rate`;
  }

  addRenewRatesV2() {
    return `horde/v2/client/management/renew-rate`;
  }

  getListClientPartner(
    page: number,
    limit: number,
    search: string,
    partnerType: string,
    type: string = "",
    isBranch: boolean = false,
    isParent: boolean = false,
    orderBy: string = "",
    sortBy: string = ""
  ) {
    return `/horde/v1/partner/client?page=${page}&limit=${limit}&search=${encodeURIComponent(
      search
    )}&partner_type=${partnerType}&type=${type}&is_branch=${isBranch}&is_parent=${isParent}&order_by=${orderBy}&sort_by=${sortBy}`;
  }
  getListClientParent() {
    return `/horde/v1/cargo-configuration/list-client-parent`;
  }
  getListClientMapping(params: RequestApiClientMappingList): string {
    return `/horde/v1/client/name?search=${encodeURIComponent(
      params.search
    )}&limit=${params.limit}&page=${params.page}&is_branch=${
      params.isBranch
    }&is_parent=${params.isParent}`;
  }

  approveRenewRates() {
    return `horde/v1/client/management/renew-rate/approve`;
  }

  rejectRenewRates() {
    return `horde/v1/client/management/renew-rate/reject`;
  }

  getListTariff(params: CheckTariffRequest, cache: boolean) {
    return `/horde/v1/tariff?cache=${cache}&${params.toQueryString()}`;
  }

  getListCustomer(search: string, cache: boolean) {
    return `/horde/${isUrlUpdateVersion(
      "customer"
    )}/customer?cache=${cache}&search=${encodeURIComponent(search)}`;
  }

  addCustomer() {
    return `/horde/v1/customer`;
  }

  getBookingList(params: RequestListBooking) {
    return `/hydra/v${params.version}/stt?${params.toQueryString()}`;
  }
  getBookingListCBP(params: RequestListBooking) {
    return `/hydra/v1/cbp/pickup?${params.toQueryString()}`;
  }

  getManifestCBP(params: RequestListBooking) {
    return `/hydra/v1/cbp/pickup/manifest?${params.toQueryString()}`;
  }

  getBookingDetail(id: number, isWithEligibility: boolean, isAuth: boolean) {
    return `/hydra/v1/stt/${id}?is_with_eligibility=${isWithEligibility}&is_auth=${isAuth}`;
  }

  getRefreshES() {
    return "/horde/v1/city/refresh_es";
  }
  addManualBooking() {
    return `/hydra/v1/stt/manual`;
  }

  getEstimationPrice(version: string): string {
    return `/horde/v${version}/tariff/estimation`;
  }

  downloadTariff(commodity_id: string | number, product_type: string) {
    return `/horde/v2/tariff/download?commodity_id=${commodity_id}&product_type=${product_type}`;
  }

  getPrintData(stt: number, accountType: string) {
    return `hydra/v1/stt/resi/${stt}?account_type=${accountType}`;
  }

  getListStt(
    page: number,
    limit: number,
    destination_city_id: number | string,
    user_type: string,
    booked_by: string | number,
    from_date: string,
    end_date: string,
    stt_number: string,
    booked_type: string
  ) {
    return `hydra/v1/stt/manifest?page=${page}&limit=${limit}&destination_city_id=${destination_city_id ||
      ""}&user_type=${user_type}&booked_by=${booked_by}&from_date=${from_date}&end_date=${end_date}&booked_type=${booked_type}&stt_number=${stt_number}`;
  }

  getListBaggingBulkPrint(bagNumber: string) {
    return `hydra/v1/stt/manifest-bag?bag_number=${bagNumber}`;
  }

  getPartnerOptions(
    page: number,
    limit: number,
    search: string,
    type?: string
  ) {
    return `/horde/v1/partner?page=${page}&limit=${limit}&search=${encodeURIComponent(
      search
    )}&type=${type}`;
  }

  getClientOptions(
    page: number,
    limit: number,
    search: string,
    isParent: boolean
  ) {
    return `/horde/v1/client/name?page=${page}&limit=${limit}&search=${encodeURIComponent(
      search
    )}&is_parent=${isParent}`;
  }

  getCustomerOptions(page: number, limit: number, search: string) {
    return `/horde/${isUrlUpdateVersion(
      "customer"
    )}/customer?page=${page}&limit=${limit}&search=${encodeURIComponent(
      search
    )}`;
  }

  getShipmentIdList(id: string, limit: number, page: number) {
    return `/hydra/v1/stt/shipment-algo/${id}?limit=${limit}&page=${page}`;
  }

  addShipmentBooking(version = 1) {
    return `/hydra/v${version}/stt/shipment`;
  }

  exportSttManifests(
    destination_city_id: number | string,
    user_type: string,
    booked_by: string | number,
    from_date: string,
    end_date: string,
    stt_number: string,
    booked_type: string
  ) {
    return `hydra/v1/stt/manifest/export?destination_city_id=${destination_city_id}&user_type=${user_type}&booked_by=${booked_by}&from_date=${from_date}&end_date=${end_date}&booked_type=${booked_type}&stt_number=${stt_number}`;
  }

  getDrivers() {
    return `/horde/v1/driver`;
  }

  addDriver(): string {
    return `/horde/v1/driver`;
  }

  getVehicles(search: string) {
    return `/horde/v1/vehicle?search=${encodeURIComponent(search)}`;
  }

  getPickupManifestList(
    page: number,
    limit: number,
    startDate: string,
    endDate: string
  ) {
    return `/hydra/v1/pickup/manifest?page=${page}&limit=${limit}&pickup_start_date=${startDate}&pickup_end_date=${endDate}`;
  }

  createNewVehicle() {
    return `/horde/v1/vehicle`;
  }

  getSttListSttChecklist(
    page: number,
    limit: number,
    status: string,
    date: string,
    sortBy: string,
    productType: string,
    cache: boolean
  ) {
    return `/hydra/v1/stt/manifest/checklist?cache=${cache}&page=${page}&limit=${limit}&status=${status}&date=${date}&sort_by=${sortBy}&product_type=${productType}`;
  }

  // STI-SC
  getStiSCList(params: RequestListSTISC) {
    return `/hydra/v1/sti/sc?${params.toQueryString()}`;
  }

  getDetailSttPiecesSTI(sttNumber: string) {
    return `/hydra/v1/sti/stt-detail?stt_or_pickup_code=${sttNumber}`;
  }

  getDetailSttPiecesSTILilo(bagNo: string) {
    return `/hydra/v2/sti/stt-detail?stt_or_pickup_code=${bagNo}`;
  }

  getDetailSttPiecesStiSc(sttNumber: string) {
    return `/hydra/v1/sti/sc/scan-detail?stt_no=${sttNumber}`;
  }

  generateStiSc(): string {
    return "/hydra/v1/sti/sc";
  }

  generateStiDestSc(): string {
    return "/hydra/v1/sti-dest/sc";
  }

  getSTISCSession(params: RequestListSTISCSession): string {
    return `/hydra/v1/sti/sc/detail-session?${params.toQueryString()}`;
  }

  createSTISCSession(): string {
    return `/hydra/v1/sti/sc/create-session`;
  }

  processUpdateSTISC(): string {
    return `/hydra/v1/sti/sc/scan`;
  }

  finishSessionSTISC(): string {
    return `/hydra/v1/sti/sc/finish-session`;
  }

  generateSti(): string {
    return "/hydra/v1/sti/generate";
  }

  postManifestSTIReverseScan() {
    return "hydra/v2/sti/manifest";
  }

  getDetailSTTPieces(sttPiece: string) {
    return `/hydra/v1/pickup/manifest/stt-detail?stt_piece=${sttPiece}`;
  }

  getSTIList(params: StiListRequest, version = "v1"): string {
    return `/hydra/${version}/sti?${params.toQueryString()}`;
  }

  downloadManifest(params: string, version = "v1"): string {
    return `/hydra/${version}/${params}`;
  }

  validationScanSTTPiece(sttPiece: string) {
    return `/hydra/v1/pickup/manifest/stt-detail/scan?stt_piece=${sttPiece}`;
  }

  validationScanSTTStiSc(sttPiece: string) {
    return `hydra/v1/sti/sc/scan?stt_piece=${sttPiece}`;
  }

  getPrintDataPickupManifest(id: number) {
    return `/hydra/v1/pickup/manifest/detail/${id}`;
  }

  updatePickupManifest(): string {
    return `/hydra/v1/pickup/manifest`;
  }

  validationScanSTISTTPiece(sttPiece: string) {
    return `/hydra/v1/sti/scan?stt_piece=${sttPiece}`;
  }

  getListBagging(params: BaggingListRequest) {
    return `/hydra/v1/bag?${params.toQueryString()}`;
  }
  getDetailBagging(id: number) {
    return `/hydra/v1/bag/detail/${id}`;
  }
  getDetailSttNumberBagging(sttNumber: string, partnerId?: number) {
    return `/hydra/v1/bag/stt-detail?bag_or_stt_no=${sttNumber}&partner_id=${partnerId ||
      ""}`;
  }

  createBagging(): string {
    return "/hydra/v1/bag";
  }

  updateBagging(): string {
    return `/hydra/v1/bag`;
  }

  updateBaggingToRTC(): string {
    return `/hydra/v1/bag/bag-rtc`;
  }

  getRegionBaggingGrouping(code: string): string {
    return `/horde/v1/bagging_group_location/:id?bgl_destination_id=${code}`;
  }

  getListStiDest(params: StiDestListRequest) {
    return `/hydra/${params.version}/sti-dest?${params.toQueryString()}`;
  }
  onValidateSttPieceStiDest(sttPiece: string) {
    return `/hydra/v1/sti-dest/scan?stt_piece=${sttPiece}`;
  }
  updateStiDest() {
    return `/hydra/v1/sti-dest`;
  }

  stiDestReverseScan() {
    return `hydra/v2/sti-dest`;
  }

  getStiDestReverseScan(params: RequestListSTIDestTemporary) {
    return `hydra/v2/sti-dest/temporary?${params.toQueryString()}`;
  }

  postManifestStiDestReverseScan() {
    return `hydra/v2/sti-dest/manifest`;
  }

  getListStiDestSc(
    page: number,
    limit: number,
    startDate: string,
    endDate: string,
    search: string
  ) {
    return `/hydra/v1/sti-dest/sc?page=${page}&limit=${limit}&start_date=${startDate}&end_date=${endDate}&search=${encodeURIComponent(
      search
    )}`;
  }

  // Handover
  getHandoverList(params: RequestListHandover) {
    return `/hydra/${params.version}/handover?${params.toQueryString()}`;
  }
  getSttDetailHandover(stt: string, bagNo: string, partnerCode: string) {
    return `/hydra/v1/handover/stt-detail?stt_no=${stt}&bag_no=${bagNo}&partner_code=${partnerCode}`;
  }
  updateHandover(): string {
    return "/hydra/v1/handover";
  }
  getPartnerVendorList(params: RequestPartnerVendorList): string {
    return `/hydra/v1/handover/list-vendor-partner?${params.toQueryString()}`;
  }

  getDetailHandover(handoverId: number) {
    return `/hydra/v1/handover/${handoverId}`;
  }

  getListSubconsole(parentId: number) {
    return `/horde/v1/partner?parent_id=${parentId}&type=sub-console&use_location=false&limit=10&page=1`;
  }

  getStiSCManifestDetail(id: number) {
    return `/hydra/v1/sti/sc/detail/${id}`;
  }

  getPrintDataSTIDestSC(id: number) {
    return `/hydra/v1/sti-dest/sc/detail/${id}`;
  }

  getStiDestManifestDetail(id: number) {
    return `/hydra/v1/sti-dest/detail/${id}`;
  }

  cancelBooking(): string {
    return `/hydra/v1/stt/cancel`;
  }

  editBooking(id: number): string {
    return `/hydra/v1/stt/${id}`;
  }

  getStiManifestData(id: number, version = "v1") {
    return `/hydra/${version}/sti/pdf/${id}`;
  }

  getDetailPickupStt(sttNumber: string) {
    return `/hydra/v1/pickup/manifest/stt-detail?stt_no=${sttNumber}`;
  }

  getExistCustomer(name: string, phone: string, address: string) {
    return `/horde/${isUrlUpdateVersion(
      "customer"
    )}/customer/detail?name=${name}&phone=${phone}&address=${address}`;
  }

  getDetailSttStiDestSc(sttOrBagNumber: string) {
    return `/hydra/v1/sti-dest/sc/stt-detail?stt_or_bag_no=${sttOrBagNumber}`;
  }

  getStiDestSttList(bagOrStt: string) {
    return `/hydra/v1/sti-dest/stt-detail?bag_or_stt_no=${bagOrStt}`;
  }

  getStiDestSttListLilo(bagNo: string) {
    return `/hydra/v2/sti-dest/stt-detail?bag_or_stt_no=${bagNo}`;
  }

  // Shortland
  getShortlandList(
    page: number,
    limit: number,
    startDate: string,
    endDate: string,
    search: string
  ) {
    return `/hydra/v1/shortland?page=${page}&limit=${limit}&start_date=${startDate}&end_date=${endDate}&search=${encodeURIComponent(
      search
    )}`;
  }

  // POD/DEX
  getListReason(params: ReasonListDex) {
    return `/hydra/v1/reason?${params.toQueryString()}`;
  }
  getPodDexList(params: RequestListPodDex): string {
    return `/hydra/${params.version}/pod?${params.toQueryString()}`;
  }
  getPodDownload(params: ApiRequestList): string {
    return `hydra/v1/pod/download?${params.toQueryString()}`;
  }

  // Delivery Manifest
  getDeliveryManifest(
    page: number,
    limit: number,
    startDate: string,
    endDate: string,
    search: string
  ) {
    return `/hydra/v1/delivery_manifest?page=${page}&limit=${limit}&start_date=${startDate}&end_date=${endDate}&search=${encodeURIComponent(
      search
    )}`;
  }

  updateDeliveryManifest() {
    return `/hydra/v1/delivery_manifest`;
  }
  getDetailDeliveryStt(sttNumber: string) {
    return `/hydra/v1/delivery_manifest/stt-detail?stt_or_bag_no=${sttNumber}`;
  }

  getDeliveryManifestPdfData(id: number) {
    return `/hydra/v1/delivery_manifest/detail/${id}`;
  }

  updatePodDex() {
    return `/hydra/v1/pod`;
  }

  checkPaymentStatus() {
    return `/hydra/v1/stt/payment/status`;
  }

  getListSTTPaidUnpaidV2(params: RequestListBooking) {
    return `/hydra/v1/stt/payment?${params.toQueryString()}`;
  }

  getListPos(params: RequestListPartner): string {
    return `/horde/v1/partner?${params.toQueryString()}`;
  }

  getPartnerConsolidatorList(
    page: number,
    limit: number,
    search: string,
    contract_status: string,
    cityCode: string,
    status: string
  ): string {
    return `/horde/v1/partner?search=${encodeURIComponent(
      search
    )}&page=${page}&limit=${limit}&use_location=true&city_code=${cityCode}&type=console&is_default_location=true&contract_status=${contract_status}&status=${status}`;
  }

  getPartnerSubconsoleList(
    search: string,
    page: number,
    limit: number,
    useLocation: boolean,
    isDefaultLocation: boolean,
    cityCode: string,
    districtCode: string,
    status: string,
    contractStatus: string
  ) {
    return `/horde/v1/partner?search=${encodeURIComponent(
      search
    )}&page=${page}&limit=${limit}&use_location=${useLocation}&is_default_location=${useLocation}&city_code=${cityCode}&district_code=${districtCode}&type=sub-console&status=${status}&contract_status=${contractStatus}`;
  }

  getPartnerList(params: RequestListPartner) {
    return `/horde/${params.version}/partner?${params.toQueryString()}`;
  }
  getPartnerDetail(id: number, tieringLevel: boolean) {
    return `/horde/v1/partner/${id}?is_show_tiering_level=${tieringLevel}`;
  }

  checkReferralCode(referralCode: string) {
    return `/horde/v1/partner/referral/${referralCode}`;
  }

  addPartner(type: string, version: string) {
    return `/horde/${version}/partner/${type}`;
  }

  getConsolidatorsByCity(
    search: string,
    page: number,
    limit: number,
    cityCode: string,
    type: string,
    status: string
  ): string {
    return `/horde/v1/partner?search=${encodeURIComponent(
      search
    )}&page=${page}&limit=${limit}&city_code=${cityCode}&type=${type}&status=${status}`;
  }

  patchPartner(type: string, version: string) {
    return `/horde/${version}/partner/${type}`;
  }

  freezeUnFreezePartner(id: number) {
    return `/horde/v1/partner/status/${id}`;
  }

  getListPartnerBank() {
    return `/horde/v1/bank`;
  }

  getClientNamePOS(
    search: string,
    page: number,
    limit: number,
    isBranch: boolean,
    isCbp = false,
    packageType = "",
    cityCode = "",
    isDirectory = false
  ): string {
    return `/horde/v1/client/name/pos?search=${encodeURIComponent(
      search
    )}&limit=${limit}&page=${page}&is_branch=${isBranch}&is_api_cbp=${isCbp}&package_type=${packageType}&city_code=${cityCode}&is_directory=${isDirectory}`;
  }

  getPosClientList(search: string) {
    return `/horde/v1/partner/pos-client?search=${encodeURIComponent(search)}`;
  }

  getCustomProcessList(params: RequestListCustomProcess) {
    return `/hydra/v1/custom-process?${params.toQueryString()}`;
  }
  // Custom Process

  getDetailCustomProcess(id: number) {
    return `/hydra/v1/custom-process/detail/${id}`;
  }

  updateStatusCustomProcess(): string {
    return "/hydra/v1/custom-process";
  }

  patchReverseDestinationStt(id: any): string {
    return `/hydra/v1/stt/reverse-destination/${id}`;
  }

  getCustomProcessSttDetail(
    sttNo: string,
    customProcessStatus: string,
    partnerId?: number
  ): string {
    return `/hydra/v1/custom-process/stt-detail?stt_no=${sttNo}&custom_process_status=${customProcessStatus}&partner_id=${partnerId ||
      ""}`;
  }

  getReasonCustomProcess() {
    return `hydra/v1/custom-process-role/status`;
  }

  getProductsDelivery(
    search: string,
    page: number,
    limit: number,
    status: string
  ): string {
    return `/horde/v1/product?search=${encodeURIComponent(
      search
    )}&page=${page}&limit=${limit}&status=${status}`;
  }

  getInsuranceTypesDelivery(insuranceConfig: number): string {
    return `/horde/v1/configurable/insurance/${insuranceConfig}`;
  }

  validateSttManual(
    clientId: number,
    sttManual: string,
    bookingType: string,
    productType: string
  ) {
    return `/hydra/v1/stt_manual/validate?client_id=${clientId}&stt_manual=${sttManual}&booking_type=${bookingType}&product_type=${productType}`;
  }

  addBookingCorporate() {
    return `/hydra/v1/stt/corporate`;
  }

  // Bulk Booking
  validateBookingPhone() {
    return `/hydra/v1/stt/validate/phone`;
  }
  getSuccessListBooked(id: number) {
    return `/horde/v1/bulk/stt/${id}`;
  }

  downloadFailedFileBulkBooking(id: number) {
    return `/horde/v1/bulk/error/${id}`;
  }
  getListBulkBooking(params: RequestBulkBooking) {
    return `/horde/v${params.version}/bulk?${params.toQueryString()}`;
  }

  getListBulkBookingResi(id: number) {
    return `/horde/v1/bulk/resi/${id}`;
  }

  getDetailRefundConfiguration(id: number) {
    return `/horde/v1/configurable/refund/${id}`;
  }
  getDetailAdjustmentPenalty(id: number) {
    return `/horde/v1/configurable/stt_adjustment/${id}`;
  }

  getListBankAccount(page: number, limit: number, type: string) {
    return `/gober/v1/bank_account?limit=${limit}&page=${page}&bank_account_type=${type}`;
  }

  getListInternalBankAccount() {
    return `/gober/v1/bank_account/internal/list-detail-bank`;
  }

  getDetailInternalBankAccount(id: number) {
    return `/gober/v1/bank_account/internal/detail-bank?bank_id=${id}`;
  }

  getDetailTopupManual(id: number) {
    return `/gober/v1/topup_manual/${id}`;
  }

  addTopupManual() {
    return `/gober/v1/topup_manual`;
  }

  approveTopupApproval() {
    return `/gober/v1/topup_manual/approve`;
  }

  rejectTopupApproval() {
    return `/gober/v1/topup_manual/reject`;
  }

  getListTransaction(params: GetListBalanceApiRequest, apiVersion: string) {
    return `/gober/${apiVersion}/transaction?${params.toQueryString()}`;
  }

  getTrackingStt(sttNo: string, cache: boolean, version: string) {
    const params = {
      cache,
      q: sttNo,
      type: IS_SENDER_ACCOUNT ? "external" : "",
    };
    return `/hydra/${version}/stt/tracking?${
      new QueryParamsEntities2(params).queryString
    }`;
  }

  getTrackingCargo(cargoNo: string) {
    return `/hydra/v1/cargo?cargo_no=${cargoNo}`;
  }

  getCargoCommodityList(page: number, limit: number, search: string) {
    return `/horde/v1/commodity/group?page=${page}&limit=${limit}&search=${encodeURIComponent(
      search
    )}`;
  }

  getSearchCargoOptions(origin: string, destination: string) {
    return `/horde/v1/leg/transport-type?origin=${origin}&destination=${destination}`;
  }

  createCargo() {
    return `/hydra/v2/cargo`;
  }

  updateRetryCargo(id: number) {
    return `/hydra/v1/retry_cargo/${id}`;
  }

  getActiveCashback(id: any) {
    return `/horde/v1/configurable-cashback/active-retail/${id}`
  }

  cashbackConfigList(params: CashbackListRequest) {
    return `/horde/v1/configurable-cashback?${params.toQueryString()}`
  }

  cashbackConfigCreate() {
    return `/horde/v1/configurable-cashback`
  }

  cashbackConfig(id: any) {
    return `/horde/v1/configurable-cashback/${id}`
  }

  // Algo
  loginAlgo() {
    return `/v1/account/auth/login`;
  }

  getPosFavoriteSubscriberList(
    agentCode: string,
    phoneNumber: string,
    page: number,
    limit: number
  ) {
    return `/v1/shipment/elexys/agent/favorite/customer?agent_code=${agentCode}&phone_number=${phoneNumber}&page=${page}&per_page=${limit}`;
  }

  editMemberDiscount() {
    return `/v1/shipment/elexys/agent/favorite/customer/discount`;
  }
  getPosFavoriteSubscriberOutstandingList(
    agentCode: string,
    phoneNumber: string,
    page: number,
    limit: number,
    status: string
  ) {
    return `/v1/shipment/elexys/agent/favorite/invoice?agent_code=${agentCode}&phone_number=${phoneNumber}&page=${page}&per_page=${limit}&status=${status}`;
  }

  getBalance(cache: boolean) {
    return IS_SENDER_ACCOUNT
      ? "/phoenix/v1/account/balance"
      : `/gober/v1/wallet/detail?cache=${cache}`;
  }

  getListLastBalance(params: RequestListLastBalance) {
    return `/gober/v1/wallet/last-balance?${params.toQueryString()}`;
  }

  editDefaultDiscount() {
    return `/v1/shipment/elexys/agent/discount`;
  }

  // COD Income
  getListCodIncome(
    page: number,
    limit: number,
    type: string,
    startDate: string,
    endDate: string,
    search: string
  ): string {
    return `/gober/v1/cod?page=${page}&limit=${limit}&trx_type=${type}&start_date=${startDate}&end_date=${endDate}&search=${encodeURIComponent(
      search
    )}`;
  }
  getExportCodTransaction(
    page: number,
    type: string,
    startDate: string,
    endDate: string
  ) {
    return `/gober/v1/cod/export?page=${page}&trx_type=${type}&start_date=${startDate}&end_date=${endDate}`;
  }

  // Cargo
  getListCargo(params: CargoListRequest): string {
    return `hydra/v1/cargo/list?${params.toQueryString()}`;
  }
  getListRetryCargo(params: CargoListRequest): string {
    return `hydra/v1/retry_cargo?${params.toQueryString()}`;
  }
  getDetailCargo(cargoNo: string): string {
    return `/hydra/v1/cargo/detail-cargo-stt?cargo_no=${encodeURIComponent(
      cargoNo
    )}`;
  }

  getDetailRetryCargo(rcNo: string): string {
    return `/hydra/v1/retry_cargo/detail/${encodeURIComponent(rcNo)}`;
  }

  getDetailDefaultDiscount(agentCode: string) {
    return `/v1/shipment/elexys/agent/${agentCode}/discount`;
  }
  editCargo() {
    return `/hydra/v1/cargo`;
  }

  // dashboard
  getDashboardConsole(params: DashboardConsoleRequest): string {
    return `/hydra/v2/dashboard?${params.toQueryString()}`;
  }
  getCutOffConsoleOutgoingStt(): string {
    return "/hydra/v2/dashboard/cut_off";
  }
  getDashboardDelivery(path = "", queryString = "", version = "v1"): string {
    return `/hydra/${version}/dashboard-delivery${path}?${queryString}`;
  }
  getCorporateDashboard(path = "", queryString = ""): string {
    return `/hydra/v1/corporate-dashboard${path}?${queryString}`;
  }
  getSenderDashboard(path = "", queryString = ""): string {
    return `/phoenix/v1/sender-dashboard${path}?${queryString}`;
  }
  getPosPerformanceDashboard(path = "", queryString = ""): string {
    return `/hydra/v1/pos-dashboard${path}?${queryString}`;
  }

  getPosFollowUpList(params: RequestList) {
    return `/hydra/v2/dashboard/pos/cut_off?${params.toQueryString()}`;
  }

  getDashboardDetailList(params: DashboardDetailListRequest) {
    const { tab: path } = params;
    delete params.tab;
    const endpoint = path === "current-stt" ? "/cut_off" : path || "";
    return `/hydra/v2/dashboard/detail${endpoint}?${params.toQueryString()}`;
  }

  getDownloadDashboard(params: DashboardDownloadRequest) {
    const { tab: path } = params;
    delete params.tab;
    return `/hydra/v2/dashboard/download${
      path === "current-stt" ? "/cut_off" : ""
    }?${params.toQueryString()}`;
  }

  getDownloadPOSDashboard(params: DashboardDetailListRequest) {
    return `/hydra/v2/dashboard/download/pos/cut_off?${params.toQueryString()}`;
  }

  getBeneficiaryAccList(
    page: number,
    limit: number,
    bankAccType: string,
    actortype: string,
    actorId: number
  ) {
    return `/gober/v1/bank_account?limit=${limit}&page=${page}&bank_account_type=${bankAccType}&actor_type=${actortype}&actor_id=${actorId}`;
  }

  validateBankAccount() {
    return `/gober/v1/bank_account/validate`;
  }

  getOnProcessSttList(params: RequestListOnProcessStt) {
    return `/hydra/v${params.version}/on-process?${params.toQueryString()}`;
  }

  getCargoProductList(status: string) {
    return `/horde/v1/cargo-product?status=${status}`;
  }

  appendBagOrSttNumber() {
    return `/hydra/v1/cargo/scan`;
  }

  getDetailManualDeductAddSaldo(id: number) {
    return `/gober/v1/saldo_adjustment/detail/${id}`;
  }

  getListOfActors(search: string) {
    return `/gober/v1/saldo_adjustment/actor_created?search=${encodeURIComponent(
      search
    )}&page=1&limit=10`;
  }

  getListAirport(
    airportCode: string,
    cityCode: string,
    page: number,
    limit: number,
    isLocation?: boolean,
    search?: string
  ): string {
    return `/horde/v1/airport?airport_code=${airportCode}&city_code=${cityCode}&page=${page}&limit=${limit}&is_location=${isLocation ||
      false}&search=${search || ""}`;
  }

  getDetailAirport(airportCode: string): string {
    return `/horde/v1/airport/detail?airport_code=${airportCode}`;
  }

  getListShipmentPrefix() {
    return `/hydra/v1/stt/shipment/prefix`;
  }

  getBookingCommissionDetail(id: number) {
    return `/horde/v1/booking_commission/detail/${id}`;
  }

  bookingCommissionEndpoint() {
    return `/horde/v2/booking_commission`;
  }

  // bulk booking commission
  createBulkBookingCommission() {
    return `/horde/v2/booking_commission/bulk`;
  }

  getBookingCommissionList(params: RequestBookingCommissionList): string {
    return `/horde/v${
      params.version
    }/booking_commission?${params.toQueryString()}`;
  }

  getBulkBookingCommissionList(
    page: number,
    limit: number,
    search: string,
    status: string
  ): string {
    return `/horde/v1/booking_commission/bulk?page=${page}&limit=${limit}&search=${encodeURIComponent(
      search
    )}&status=${status}`;
  }

  getTopUpVerificationDetail(id: number) {
    return `/gober/v1/topup_manual/${id}`;
  }

  getBulkBookingCommissionDetail(id: number) {
    return `/horde/v1/booking_commission/bulk/detail/${id}`;
  }

  createDeductSaldo() {
    return `/gober/v1/saldo_adjustment`;
  }

  getManualDeductAddSaldoList(
    page: number,
    limit: number,
    search: string,
    actorType: string,
    actorId: number,
    startDate: string,
    endDate: string,
    type: string,
    createdBy: string
  ): string {
    return `/gober/v1/saldo_adjustment?page=${page}&limit=${limit}&search=${encodeURIComponent(
      search
    )}&adjustment_type=${type}&actor_type=${actorType}&actor_id=${actorId}&start_date=${startDate}&end_date=${endDate}&created_by=${createdBy}`;
  }

  getDeductReports(
    adjustmentType: string,
    actorType: string,
    actorId: number,
    startDate: string,
    endDate: string,
    createdBy: number
  ): string {
    return `/gober/v1/saldo_adjustment/export?adjustment_type=${adjustmentType}&actor_type=${actorType}&actor_id=${actorId}&start_date=${startDate}&end_date=${endDate}&created_by=${createdBy}`;
  }

  getListTopupApproval(
    page: number,
    limit: number,
    search: string,
    partnerName: string,
    status: string,
    bankName: string,
    verifiedBy: string
  ) {
    return `/gober/v1/topup_manual/list-history?limit=${limit}&page=${page}&partner_name=${partnerName}&bank_name=${bankName}&verified_by=${verifiedBy}&id&status=${status}&search=${encodeURIComponent(
      search
    )}&menu=approved`;
  }

  getTransactionHistoryList(
    page: number,
    limit: number,
    status: string,
    startDate: string,
    endDate: string
  ): string {
    return `/gober/v1/topup_manual/list-history?status=${status}&limit=${limit}&page=${page}&start_date=${startDate}&end_date=${endDate}`;
  }

  getActorVerifiedList(): string {
    return "/gober/v1/topup_manual/list-actor/verified";
  }

  getTopupApprovalDetail(id: number): string {
    return `/gober/v1/topup_manual/${id}`;
  }

  getTopUpVerificationList(
    page: number,
    limit: number,
    search: string,
    partner: string,
    status: string,
    bank: string,
    verifiedBy: string
  ) {
    return `/gober/v1/topup_manual/list-history?limit=${limit}&page=${page}&partner_name=${partner}&bank_name=${bank}&verified_by=${verifiedBy}&search=${encodeURIComponent(
      search
    )}&status=${status}`;
  }

  getListVerifier() {
    return `/gober/v1/topup_manual/list-actor/verified`;
  }

  verifyTopUpVerification() {
    return `/gober/v1/topup_manual/verify`;
  }

  rejectTopUpVerification() {
    return `/gober/v1/topup_manual/verification-reject`;
  }

  getExistingData(service: string, type: string) {
    return `/${service}/${type}`;
  }

  getListDeliveryVendor(page: number, limit: number, search: string) {
    return `/horde/v1/delivery_vendor?page=${page}&limit=${limit}&search=${encodeURIComponent(
      search
    )}`;
  }

  addDeliveryVendor() {
    return `/horde/v1/delivery_vendor`;
  }

  // DTPOL
  getDtpoList(page: number, limit: number, search: string): string {
    return `/horde/v1/dtpol?page=${page}&limit=${limit}&search=${encodeURIComponent(
      search
    )}`;
  }
  getDtpolDetail(id: number): string {
    return `/horde/v1/dtpol/${id}`;
  }
  editDtpol() {
    return `/horde/v1/dtpol`;
  }

  // DEL
  getUpdateDelList(
    page: number,
    limit: number,
    search: string,
    startDate: string,
    endDate: string
  ): string {
    return `/hydra/v1/del?page=${page}&limit=${limit}&search=${encodeURIComponent(
      search
    )}&start_date=${startDate}&end_date=${endDate}`;
  }
  getSttValidationUpdateDel(sttNo: string) {
    return `/hydra/v1/del/stt-detail?stt_no=${sttNo}`;
  }

  createUpdateDelivery() {
    return `/hydra/v1/del`;
  }

  printDelivery(id: number) {
    return `/hydra/v1/del/${id}`;
  }

  // messaging service configuration
  getMessagingServiceConfigurationList(
    params: RequestMessagingServiceConfigurationList
  ) {
    return `/horde/v${params.version}/message?${params.toQueryString()}`;
  }

  partnerApi(params: any) {
    const query = new QueryParamsEntities(params);
    return `/horde/v1/partner?${query.queryString}`;
  }

  getMessagingServiceConfigurationDetail(id: number) {
    return `/horde/v1/message/${id}`;
  }

  createMessagingConfig() {
    return `/horde/v1/message`;
  }

  clientManagementApi(params: any) {
    const query = new QueryParamsEntities(params);
    return `/horde/v1/client/management?${query.queryString}`;
  }

  getMessagingDictionary() {
    return `/horde/v1/message/dictionary`;
  }

  templateWhatsappPremiumApi(params: any) {
    const query = new QueryParamsEntities(params);
    return `/horde/v1/message_wa_premium?${query.queryString}`;
  }

  withdrawBalance(version = 'v1') {
    return `/gober/${version}/withdraw`;
  }

  // report
  getDownloadReport(params: ReportApiRequest) {
    const query = new QueryParamsEntities(params);
    return `/${params.service}/v${params.version}/${params.feature}/download/${
      params.title
    }${params.isCheck ? "/check_data" : ""}?${query.queryString}`;
  }

  getRequestDownloadReport(params: ReportApiRequest) {
    const query = new QueryParamsEntities(params);
    return `/${params.service}/v${params.version}/${params.feature}/download/${params.title}?${query.queryString}`;
  }

  getDownloadBalanceReportMutation(params: BalanceReportMutationApiRequest) {
    return `/gober/${params.apiVersion}/report/download/mutation?activity=${params.activity}&start_date=${params.startDate}&end_date=${params.endDate}&transaction_type=${params.transactionType}&actor_id=${params.actorId}`;
  }

  getListDownloadReport(params: ReportListDownloadRequest) {
    return `/hydra/v2/report/bulk-download?page=${params.page}&limit=${params.limit}`;
  }

  // edit email account
  editEmailAccount() {
    return `/horde/v1/account/email`;
  }

  // Integration Monitoring
  getElexysFailedBookingList(
    params: RequestApiElexysFailedBookingList
  ): string {
    const query = new QueryParamsEntities(params);
    return `/hydra/v1/log-failed-stt-elexys?${query.queryString}`;
  }

  downloadElexysFailedBooking(
    params: RequestDownloadElexysFailedBooking
  ): string {
    const query = new QueryParamsEntities(params);
    return `/hydra/v1/log-failed-stt-elexys/download?${query.queryString}`;
  }

  getLogStt(sttNo: string) {
    return `/hydra/v1/partner-log?stt_no=${sttNo}`;
  }

  retryBookingElexys() {
    return `/hydra/v1/elexys/stt/booking/retry`;
  }

  getBalanceClientPartner(params: any) {
    const query = new QueryParamsEntities2(params);
    return `/gober/v1/wallet/client-partner/detail?${query.queryString}`;
  }

  freezeUnfreezeClient(status: string) {
    return `/horde/v1/client/${status}`;
  }

  retryUploadClientRate() {
    return `/horde/v1/client/retry-upload-rate`;
  }

  // KEJAR CUAN
  kejarCuanApi() {
    return `/horde/v1/kejarcuan`;
  }
  getKejarCuanConfigurationList(params: any) {
    const query = new QueryParamsEntities(params);
    return `${this.kejarCuanApi()}?${query.queryString}`;
  }
  getKejarCuanConfigurationDetail(id: number) {
    return `${this.kejarCuanApi()}/${id}`;
  }
  getKejarCuanProgress() {
    return `${this.kejarCuanApi}/progress`;
  }
  forceStopKejarCuanConfiguration() {
    return `${this.kejarCuanApi()}/stop-campaign`;
  }
  getInformationDetailKejarcuan(campaignId: number, posId: number) {
    return `/hydra/v1/kejarcuan/detail?campaign_id=${campaignId}&pos_id=${posId}`;
  }

  editKejarCuanConfiguration(id: number) {
    return `${this.kejarCuanApi()}/${id}`;
  }

  // instant booking
  getDetailInstantBooking() {
    return "/hydra/v1/instant-booking/detail";
  }
  createInstantBookingSession() {
    return "/hydra/v1/instant-booking/create";
  }
  updateSessionInstantBooking() {
    return "/hydra/v1/instant-booking/update";
  }

  // send email
  sendPrintReceiptEmail() {
    return "/hydra/v1/stt/resi-send-email";
  }

  // external api
  getDetailSttExternal(sttNo: string, clientId: string) {
    return `/hydra/v2/stt/resi/external?stt_no=${sttNo}&client_id=${clientId}`;
  }

  clientManagementGeneral(params: RequestListClientManagement) {
    return `/horde/${
      params.version
    }/client/management/general?${params.toQueryString()}`;
  }

  // NGEN
  loginNGen(): string {
    return "/hydra/v1/ngen/token";
  }
  getScheduleListNGen(): string {
    return String(process.env.VUE_APP_NGEN_GENESIS_SEARCH_FLIGHT_PATH);
  }
  getTrackingCargoHistoryNgen() {
    return "/api/api/Tracking/AWB";
  }
  printAWBLionCargo(): string {
    if (IS_NGEN_V2) {
      return "/api/api/PortToDoorIntegration/PortToDoorAWbPrint";
    }
    return "/nGenAPI/api/PortToDoorIntegration/PortToDoorAWbPrint";
  }

  // MIDDLEWARE
  getShipmentDetailMiddleWare(ticketCode: string) {
    return `/elexys/shipment?q=${encodeURIComponent(ticketCode)}`;
  }

  getLastSyncSttReport() {
    return `/hydra/v2/report/sync`;
  }

  getDetailStiDest(id: number, version = "v1") {
    return `/hydra/${version}/sti-dest/detail/${id}`;
  }

  getListBalanceTransaction(params: RequestListBalanceTransaction) {
    return `gober/${
      params.apiVersion
    }/transaction/balance?${params.toQueryString()}`;
  }

  getBalanceTransactionType(apiVersion: string) {
    return `gober/${apiVersion}/transaction/type`;
  }

  getDetailForwardBookingCommission(id: any) {
    return `horde/v1/configurable/forward_booking_commission/${id}`;
  }

  // Payment Method
  validatePayment() {
    return "hydra/v1/checkout/validate";
  }
  checkInquiry(idTransaction: string) {
    return `gober/v1/checkout/check/${idTransaction}`;
  }
  getPaymentMethod(params: RequestListPaymentMethod) {
    return `gober/v1/payment_method?${params.toQueryString()}`;
  }

  // incoming & outgoing
  getDownloadIncomingOutgoing(params: RequestDownloadIncomingOutgoing) {
    return `hydra/v1/incoming-outgoing?${params.toQueryString()}`;
  }

  // get estimation service level agreement
  getEstimationServiceLevelAgreement(
    params: RequestEstimationServiceLevelAgreement
  ) {
    return `horde/v1/predefine_holiday?${params.toQueryString()}`;
  }
  getEstimateSlaDetail(params: RequestEstimateSlaDetail) {
    return `horde/v2/estimate/sla/detail?${params.toQueryString()}`;
  }

  // total stt paid
  totalSTTPaidUnread(params?: RequestSttPaidUnread) {
    return `hydra/v1/read-stt-paid?${params?.toQueryString() ?? ""}`;
  }
  getDownloadStt(params: RequestListBooking) {
    return `hydra/v1/stt/payment/download?${params.toQueryString()}`;
  }

  // flagging dex
  validateRebuttalDex() {
    return "hydra/v1/rebuttal-dex";
  }

  getSummaryDownload(type: string, filter: DownloadSummaryCodRequest) {
    return `/hydra/v1/dashboard-delivery/download/${type}/summary?${filter.toQueryString()}`;
  }

  getDetailCourierData(queryString = "", type: string) {
    return `/hydra/${FlagsDashboardCod.feature_api_version_dashboard_delivery_summary_courier_cod.getValue()}/dashboard-delivery/summary/${type}/cod?${queryString}`;
  }

  getBonusKomisiDetail(params: BonusKomisiDetailRequest) {
    return `/hydra/v1/progressive-commission/detail?${params.toQueryString()}`;
  }

  getProgressiveCommissionList(params: ProgressiveCommissionListRequest) {
    return `/horde/v1/configurable_progressive_commission?${params.toQueryString()}`;
  }

  progressiveCommissionConfigApi(id = "") {
    return `/horde/v1/configurable_progressive_commission${
      !!id ? `/${id}` : ""
    }`;
  }

  getDownloadCourierData(
    driverPhone: string,
    filter: CodDashboardCourierDetailRequest
  ) {
    return `/hydra/v1/dashboard-delivery/download/courier?${filter.toQueryString()}&driver_phone=${driverPhone}`;
  }

  getDownloadPicData(
    picPhone: string,
    filter: CodDashboardCourierDetailRequest
  ) {
    return `/hydra/v1/dashboard-delivery/download/pic?${filter.toQueryString()}&pic_phone=${picPhone}`;
  }

  getDetailCourierPerStatusCodDashboard(
    params: CodDashboardCourierDetailRequest
  ) {
    return `/hydra/${FlagsDashboardCod.feature_api_version_dashboard_delivery_detail_courier_cod.getValue()}/dashboard-delivery/detail/${
      params.type
    }/cod?${params.toQueryString()}`;
  }

  getProgressiveCommissionDetail(id: number) {
    return `/horde/v1/configurable_progressive_commission/detail/${id}`;
  }

  getDetailClientBookingTemplate(id: number) {
    return `/horde/v1/bulk-booking-template/detail/${id}`;
  }

  getListClientBookingTemplate(params: ClientBookingListParams) {
    return `/horde/v1/bulk-booking-template/client/list?${params.toQueryString()}`;
  }

  editClientTemplateConfiguration(id: number) {
    return `/horde/v1/bulk-booking-template/${id}`;
  }

  createClientTemplateConfiguration() {
    return `/horde/v1/bulk-booking-template`;
  }

  getBaggingGroupingList(params: RequestBaggingGroupingList) {
    return `/horde/v1/bagging_group_location?${params.toQueryString()}`;
  }

  getDetailClaim(sttNo: string) {
    return `/hydra/v1/claim-status/detail?stt_no=${sttNo}`;
  }

  createTrackDeliveryClaim() {
    return "/phoenix/v1/claim-status";
  }

  getDataClaimList(path: string, params: ClaimListRequest) {
    return `/hydra/v1/${path}?${params.toQueryString()}`;
  }

  // SALDO & POIN SENDER DASHBOARD
  getListHistoryPoint(params: RequestHistoryPoinSaldoList) {
    return `/phoenix/v1/point/history?${params.toQueryString()}`;
  }

  getListHistorySaldo(params: RequestHistoryPoinSaldoList) {
    return `/phoenix/v1/balance/history?${params.toQueryString()}`;
  }

  getDataTrackList(path: string, filter: TrackDeliveryListRequest) {
    return `/hydra/v1/${path}?${filter.toQueryString()}`;
  }
  // cargo configuration
  getDetailCargoConfiguration(id: number) {
    return `/horde/v1/cargo-configuration/${id}`;
  }
  editCargoConfiguration(): string {
    return "/horde/v1/cargo-configuration";
  }
  deleteCargoConfiguration(id: number): string {
    return `/horde/v1/cargo-configuration/${id}`;
  }
  getCargoRecomendations(params: CargoRecomendationsListRequest): string {
    return `/horde/v1/cargo-configuration/recommendation?${params.toQueryString()}`;
  }
  getLastReadCargo() {
    return `/hydra/v1/retry_cargo/check`;
  }

  updateLastReadCargo() {
    return `/hydra/v1/retry_cargo/last_view`;
  }

  addCargoConfiguration() {
    return `/horde/v1/cargo-configuration`;
  }
  getCargoConfigList(params: CargoConfigListRequest) {
    return `/horde/v1/cargo-configuration?${params.toQueryString()}`;
  }

  getReferralAffiliate() {
    return `/horde/v1/partner/affiliate-link`;
  }

  getDistrictClient(params: {
    id: number | string;
    args: DistrictClientRequest;
  }): string {
    return `/horde/v1/district/client/${
      params.id
    }?${params.args.toQueryString()}`;
  }

  getListRegion(params: RegionListRequest) {
    return `/horde/v1/region?${params.toQueryString()}`;
  }

  // service delay network
  getServiceDelayList(params: ApiRequestList) {
    return `/horde/v1/service_delay?${params.toQueryString()}`;
  }
  getServiceDelayDetail(id: number) {
    return `/horde/v1/service_delay/${id}`;
  }
  serviceDelayForm() {
    return "/horde/v1/service_delay";
  }

  // courier management
  getCourierManagementList(params: ApiRequestCourierManagementList) {
    return `/horde/v1/courier_management?${params.toQueryString()}`;
  }
  getCourierManagementApprovalList(params: ApiRequestCourierManagementList) {
    return `/horde/v1/partner/courier-approval-delivery?${params.toQueryString()}`;
  }
  getCourierManagementApprovalListHistory(params: ApiRequestCourierManagementListHistory, id: number) {
    console.log(id, "id endpoint")
    return `/horde/v1/partner/courier-approval-delivery/history/courier/${id}?${params.toQueryString()}`;
  }
  downloadCourierManagementList(params: ApiRequestCourierManagementList) {
    return `/horde/v1/courier_management/export_csv?${params.toQueryString()}`;
  }
  createCourierManagement() {
    return "/horde/v1/courier_management";
  }
  getCourierManagementDetail(id: number) {
    return `/horde/v1/courier_management/${id}`;
  }
  updateStatusCourierManagement() {
    return `/horde/v1/partner/courier-approval-delivery/update-status`;
  }
  getCourierVehicleList(params: ApiRequestCourierVehicleList): string {
    return `/horde/v1/courier_management/vehicle?${params.toQueryString()}`
  }

  // config price cod
  getConfigPriceCodDetail(id: number): string {
    return `/horde/v1/configurable/cod/${id}`;
  }

  // cod configuration
  getCodConfigurationList(params: ApiRequestList) {
    return `/horde/v1/cod_configuration?${params.toQueryString()}`;
  }

  getCodConfigurationDetail(id: number) {
    return `/horde/v1/cod_configuration/${id}`;
  }

  // balance minus penalty
  getBalanceMinusPenalty(params: ApiRequestList) {
    return `/horde/v1/balance_minus_penalty?${params.toQueryString()}`;
  }

  getBalanceMinusPenaltyDetail(id: number) {
    return `/horde/v1/balance_minus_penalty/${id}`;
  }
  getAllNotifications() {
    return `/hydra/v1/notification-penalty/list`;
  }
  getBalancePenaltyNotification() {
    return "/hydra/v1/notification-penalty/last";
  }

  // hold balance history
  getHoldBalanceHistoryList(params: ApiRequestList) {
    return `/hydra/v1/hold_balance_history?${params.toQueryString()}`;
  }

  postToGetPosterProduct() {
    return `/horde/v1/tariff/upselling`;
  }

  getListMiddleware(type: string, params: RequestMiddlewareList) {
    return `v2/api/internal/${type}?${params.toQueryString()}`;
  }

  formMiddleware(type: string, id: number) {
    return `v2/api/internal/${type}${id ? `/${id}` : ""}`;
  }

  getDetailMiddleware(params: { type: string; id: number }) {
    return `v2/api/internal/${params.type}/${params.id}`;
  }

  getTokenMiddleware() {
    return `/horde/v1/auth/pegasus`;
  }

  uploadHydraFile() {
    return `/hydra/v1/upload`;
  }

  deleteHydraFile() {
    return `/hydra/v1/upload/delete`;
  }

  getTopupFeeConfigList(params: RequestTopupFeeConfigurationList) {
    return `/horde/v1/configurable-top-up-fee?${params.toQueryString()}`
  }

  detailTopupFeeConfig(id: any) {
    return `/horde/v1/configurable-top-up-fee/${id}`;
  }

  saveTopupFeeConfig() {
    return `/horde/v1/configurable-top-up-fee`;
  }

  getActiveTopupFeeConfig(id: any) {
    return `/horde/v1/configurable-top-up-fee/active-retail/${id}`
  }
  getTrackingByBookingRequestIdNgen() {
    return "/api/Booking/v3/Tracking"
  }

  // Urgent Delivery Management Endpoints
  getUrgentDeliveryManagementPODList(
    params: UrgentDeliveryManagementPODListRequest
  ): string {
    return `/hydra/v1/priority-delivery-management/pod?${params.toQueryString()}`
  }

  getUrgentDeliveryManagementRTSList(
    params: UrgentDeliveryManagementRTSListRequest
  ): string {
    return `/hydra/v1/priority-delivery-management/rts?${params.toQueryString()}`
  }

  getUrgentDeliveryManagementHALList(
    params: UrgentDeliveryManagementHALListRequest
  ): string {
    return `/hydra/v1/priority-delivery-management/hal?${params.toQueryString()}`
  }

  getUrgentDeliveryManagementTakenByRecipientList(
    params: UrgentDeliveryManagementHALListRequest
  ): string {
    return `/hydra/v1/priority-delivery-management/taken-by-recipient?${params.toQueryString()}`
  }

  downloadUrgentManagementList(status: 'pod' | 'rts' | 'hal', params: UrgentDeliveryManagementPODListRequestDownload | UrgentDeliveryManagementRTSListRequestDownload | UrgentDeliveryManagementHALListRequestDownload): string {
    return `/hydra/v1/priority-delivery-management/download/${status}?${params.toQueryString()}`
  }

  // STI Reverse Scan Endpoints
  getSTIReverseScanTemporaryList(
    params: STIReverseScanTemporaryListRequest
  ): string {
    return `/hydra/v2/sti/temporary?${params.toQueryString()}`;
  }

  generateSTIReverseScan(): string {
    return `/hydra/v2/sti/generate`;
  }

  getSTIReverseScanDetail(
    id: number
  ): string {
    return `/hydra/v2/sti/pdf/${id}`
  }

  saveUserConsent() {
    return '/horde/v1/user-consent';
  }

  getSTISTTDueSummary(
    params: STISTTDueSummaryListRequest
  ): string {
    return `/hydra/v1/stt-due/STI/summary?${params.toQueryString()}`;
  }

  getSTIBookedList() {
    return '/hydra/v1/stt-due/STI/list-booked?page=1&limit=500&is_total_data=true'
  }

  getSTISTTDueList(params: STISTTDueListRequest) {
    return `/hydra/v1/stt-due/STI?${params.toQueryString()}`;
  }

  downloadSTISTTDue(params: STISTTDueListRequest) {
    return `/hydra/v1/stt-due/STI/excel?${params.toQueryString()}`;
  }
}
