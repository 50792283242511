/* eslint-disable @typescript-eslint/camelcase */
import { ConvertObjectCamelToSnakeCase } from "@/app/infrastructures/misc/Utils";
import { AccountRequestInterface } from "@/data/payload/contracts/AccountRequest";
import { QueryParamsEntities } from "@/domain/entities/MainApp";

export class EditEmailAccountRequest implements AccountRequestInterface {
  newEmail: string;
  constructor(newEmail: string) {
    this.newEmail = newEmail;
  }

  public toJSON() {
    return JSON.stringify({
      email: this.newEmail
    });
  }
}

export class PairTokenRequest implements AccountRequestInterface {
  token: string;
  accountId: number;
  constructor(token: string, accountId: number) {
    this.token = token;
    this.accountId = accountId;
  }
  public toJSON() {
    return JSON.stringify({
      token: this.token,
      account_id: this.accountId
    });
  }
}
export class UnpairTokenRequest implements AccountRequestInterface {
  accountId: number;
  constructor(accountId: number) {
    this.accountId = accountId;
  }

  public toJSON() {
    return JSON.stringify({
      account_id: this.accountId
    });
  }
}

export class DashboardDeliveryRequest {
  cache = false; // cache api
  constructor(fields?: Partial<DashboardDeliveryRequest>) {
    Object.assign(this, fields);
  }

  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
}

export class PostCsAccountApiRequest implements AccountRequestInterface {
  action = "";
  constructor(fields?: Partial<PostCsAccountApiRequest>) {
    Object.assign(this, fields);
  }

  public toJSON() {
    return JSON.stringify(ConvertObjectCamelToSnakeCase(this));
  }
}
