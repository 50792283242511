import {
  CutOffConsoleData,
  CutOffConsoleIncomingCycle,
  CutOffConsoleIncomingProcess,
  CutOffConsoleOutgoingCycle,
  CutOffConsoleOutgoingProcess,
  CutOffConsoleOutgoingStt,
  DashboardConsoleData,
  DashboardDetailList,
  DashboardDetailListData,
  PosFollowUpDashboard,
  ListCourierPartnerCodDashboard,
  CourierPartnerCodDashboard,
  SummaryCodDashboardData,
  CodDashboardDetailCourier,
  DetailCourierPerStatusCodDashboardData,
  DataCorporateDashboard,
  DeliveryPerRouteData,
  SttByProductTypeData,
  ClaimReason,
  DistributionProductData,
  PerformancePerRouteData,
  DetailPerformanceData,
  AreaBarChart,
  ReferralAffiliate,
  paymentMethod
} from "@/domain/entities/Dashboard";
import { Pagination, PaginationV2 } from "@/domain/entities/Pagination";
import {
  ResponsePayload,
  ResponsePayloadV2
} from "@/domain/entities/ResponsePayload";
import { AxiosResponse } from "axios";

export class DashboardMapper {
  public convertDashboardConsoleDataFromApi(
    res: AxiosResponse<any>
  ): DashboardConsoleData {
    const {
      data: { data }
    } = res;

    return new DashboardConsoleData({
      total: data.total
    });
  }

  public convertDashboardDetailListFromApi(
    res: AxiosResponse<any>
  ): DashboardDetailList {
    const { data } = res;
    const list = !data.data
      ? []
      : data.data.map(
          (item: any) =>
            new DashboardDetailListData({
              sttId: item.stt_id,
              sttNo: item.stt_no,
              sttOriginCityId: item.stt_origin_city_id,
              sttOriginCityName: item.stt_origin_city_name,
              sttOriginDistrictId: item.stt_origin_district_id,
              sttOriginDistrictName: item.stt_origin_district_name,
              sttDestinationCityId: item.stt_destination_city_id,
              sttDestinationCityName: item.stt_destination_city_name,
              sttDestinationDistrictId: item.stt_destination_district_id,
              sttDestinationDistrictName: item.stt_destination_district_name,
              sttLastStatusId: item.stt_last_status_id,
              sttCreatedAt: item.stt_created_at,
              sttCreatedName: item.stt_created_name,
              sttBookedName: item.stt_booked_name,
              sttUpdatedAt: item.stt_updated_at,
              sttUpdatedName: item.stt_updated_name,
              sttUpdatedActorName: item.stt_updated_actor_name,
              sttTotalPiece: item.stt_total_piece,
              sttChargeableWeight: item.stt_chargeable_weight,
              sttStatusLabel: item.stt_status_label
            })
        );
    return new DashboardDetailList({
      pagination: new Pagination(
        data.meta.page,
        data.meta.limit,
        data.meta.total_records,
        data.meta.total_data || 0
      ),
      data: list
    });
  }

  convertDashboardDownloadDataFromApi(
    res: AxiosResponse<any>
  ): ResponsePayload {
    const { data } = res;
    return new ResponsePayload({
      success: data.success,
      message: data.message
    });
  }

  convertDashboardCutOffOutgoingDataFromApi(
    res: AxiosResponse<any>
  ): CutOffConsoleData {
    const {
      data: {
        data: { outgoing, incoming }
      }
    } = res;

    return new CutOffConsoleData({
      outgoing: new CutOffConsoleOutgoingStt({
        cycle1: new CutOffConsoleOutgoingCycle({
          bkdSti: outgoing.on_process_cycle_1.bkd_sti,
          stiCargoPlane: outgoing.on_process_cycle_1.sti_cargo_plane,
          stiCargoTruck: outgoing.on_process_cycle_1.sti_cargo_truck,
          cycleStart: outgoing.on_process_cycle_1.cycle_start,
          targetSti: outgoing.on_process_cycle_1.target_sti,
          cycleEndCargoPlane: outgoing.on_process_cycle_1.cycle_end_cargo_plane,
          cycleEndCargoTruck: outgoing.on_process_cycle_1.cycle_end_cargo_truck,
          total: outgoing.on_process_cycle_1.total
        }),
        cycle2: new CutOffConsoleOutgoingCycle({
          bkdSti: outgoing.on_process_cycle_2.bkd_sti,
          stiCargoPlane: outgoing.on_process_cycle_2.sti_cargo_plane,
          stiCargoTruck: outgoing.on_process_cycle_2.sti_cargo_truck,
          cycleStart: outgoing.on_process_cycle_2.cycle_start,
          targetSti: outgoing.on_process_cycle_2.target_sti,
          cycleEndCargoPlane: outgoing.on_process_cycle_2.cycle_end_cargo_plane,
          cycleEndCargoTruck: outgoing.on_process_cycle_2.cycle_end_cargo_truck,
          total: outgoing.on_process_cycle_2.total
        }),
        backlog: new CutOffConsoleOutgoingProcess({
          bkdSti: outgoing.backlog.bkd_sti,
          stiCargoPlane: outgoing.backlog.sti_cargo_plane,
          stiCargoTruck: outgoing.backlog.sti_cargo_truck,
          total: outgoing.backlog.total
        }),
        backlogToday: new CutOffConsoleOutgoingProcess({
          bkdSti: outgoing.backlog_today.bkd_sti,
          stiCargoPlane: outgoing.backlog_today.sti_cargo_plane,
          stiCargoTruck: outgoing.backlog_today.sti_cargo_truck,
          total: outgoing.backlog_today.total
        }),
        onTime: new CutOffConsoleOutgoingProcess({
          bkdSti: outgoing.on_time.bkd_sti,
          stiCargoPlane: outgoing.on_time.sti_cargo_plane,
          stiCargoTruck: outgoing.on_time.sti_cargo_truck,
          total: outgoing.on_time.total
        }),
        late: new CutOffConsoleOutgoingProcess({
          bkdSti: outgoing.late.bkd_sti,
          stiCargoPlane: outgoing.late.sti_cargo_plane,
          stiCargoTruck: outgoing.late.sti_cargo_truck,
          total: outgoing.late.total
        }),
        hanging: new CutOffConsoleOutgoingProcess({
          bkdSti: outgoing.hanging.bkd_sti,
          stiCargoPlane: outgoing.hanging.sti_cargo_plane,
          stiCargoTruck: outgoing.hanging.sti_cargo_truck,
          total: outgoing.hanging.total
        }),
        invalid: outgoing.invalid
      }),
      incoming: new CutOffConsoleOutgoingStt({
        cycle1: new CutOffConsoleIncomingCycle({
          stiDestDel: incoming.on_process_cycle_1.sti_dest_del,
          delPod: incoming.on_process_cycle_1.del_pod,
          cycleStart: incoming.on_process_cycle_1.cycle_start,
          targetDel: incoming.on_process_cycle_1.target_del,
          targetPod: incoming.on_process_cycle_1.target_pod,
          cycleEndCargoPlane: incoming.on_process_cycle_1.cycle_end_cargo_plane,
          cycleEndCargoTruck: incoming.on_process_cycle_1.cycle_end_cargo_truck,
          total: incoming.on_process_cycle_1.total
        }),
        cycle2: new CutOffConsoleIncomingCycle({
          stiDestDel: incoming.on_process_cycle_2.sti_dest_del,
          delPod: incoming.on_process_cycle_2.del_pod,
          cycleStart: incoming.on_process_cycle_2.cycle_start,
          targetDel: incoming.on_process_cycle_2.target_del,
          targetPod: incoming.on_process_cycle_2.target_pod,
          cycleEndCargoPlane: incoming.on_process_cycle_2.cycle_end_cargo_plane,
          cycleEndCargoTruck: incoming.on_process_cycle_2.cycle_end_cargo_truck,
          total: incoming.on_process_cycle_2.total
        }),
        backlog: new CutOffConsoleIncomingProcess({
          stiDestDel: incoming.backlog.sti_dest_del,
          delPod: incoming.backlog.del_pod,
          total: incoming.backlog.total
        }),
        backlogToday: new CutOffConsoleIncomingProcess({
          stiDestDel: incoming.backlog_today.sti_dest_del,
          delPod: incoming.backlog_today.del_pod,
          total: incoming.backlog_today.total
        }),
        onTime: new CutOffConsoleIncomingProcess({
          stiDestDel: incoming.on_time.sti_dest_del,
          delPod: incoming.on_time.del_pod,
          total: incoming.on_time.total
        }),
        late: new CutOffConsoleIncomingProcess({
          stiDestDel: incoming.late.sti_dest_del,
          delPod: incoming.late.del_pod,
          total: incoming.late.total
        }),
        hanging: new CutOffConsoleIncomingProcess({
          stiDestDel: incoming.hanging.sti_dest_del,
          delPod: incoming.hanging.del_pod,
          total: incoming.hanging.total
        }),
        invalid: incoming.invalid
      })
    });
  }

  convertDashboardCutOffPosFollowUpDataFromApi(
    res: AxiosResponse<any>
  ): ResponsePayloadV2 {
    const { data } = res;
    return new ResponsePayloadV2({
      pagination: new PaginationV2({
        page: data.meta.page,
        limit: data.meta.limit,
        totalData: data.meta.total_records
      }),
      data: data.data.length
        ? data.data.map(
            (item: any) =>
              new PosFollowUpDashboard({
                posCode: item.pos_code,
                posName: item.pos_name,
                phoneNumber: item.pos_phone,
                unprocessed: item.on_process,
                backlogToday: item.backlog_today,
                backlog: item.backlog,
                hanging: item.hanging
              })
          )
        : []
    });
  }

  convertListCourierPartnerCodDashboardFromApi(
    res: AxiosResponse
  ): ListCourierPartnerCodDashboard {
    const { data } = res;

    const dataList = !data.data
      ? []
      : data.data.map(
          (item: any) =>
            new CourierPartnerCodDashboard({
              courierId: item.delivery_driver_phone,
              courierName: item.delivery_driver_name,
              courierPhone: item.delivery_driver_phone,
              partnerId: item.delivery_partner_id,
              partnerName: item.delivery_partner_name,
              partnerPhone: item.delivery_partner_phone,
              partnerContactPhone: item.delivery_partner_contact_phone,
              totalStt: item.total_stt,
              totalSttPodSameDay: item.total_stt_pod_same_day,
              totalSttPodDifferentDay: item.total_stt_pod_different_day,
              totalQrisCollected: item.total_qris_collected,
              totalCollected: item.total_collected,
              totalOutstanding: item.total_outstanding,
              totalSttDexCollected: item.total_stt_dex_collected,
              totalSttDexOutstanding: item.total_stt_dex_outstanding,
              totalSttCodrejCollected: item.total_stt_codrej_collected,
              totalSttCodrejOutstanding: item.total_stt_codrej_outstanding,
              totalSttDel: item.total_stt_del
            })
        );

    return new ListCourierPartnerCodDashboard({
      pagination: new Pagination(data.meta.page, data.meta.limit),
      data: dataList
    });
  }

  public convertDownloadSummary(res: AxiosResponse<any>) {
    const { data } = res;
    return data;
  }

  convertSummaryCodDashboardDataFromApi(
    res: AxiosResponse
  ): SummaryCodDashboardData {
    const {
      data: { data }
    } = res;

    return new SummaryCodDashboardData({
      sttQuantity: data.stt_quantity || 0,
      sttTotalCodAmount: data.stt_total_cod_amount || 0,
      lastSyncDate: data.last_sync_date || ""
    });
  }

  convertDetailCourierPerStatusCodDashboardDataFromApi(
    res: AxiosResponse
  ): DetailCourierPerStatusCodDashboardData[] {
    const {
      data: { data }
    } = res;

    return !data
      ? []
      : data.map(
          (item: any) =>
            new DetailCourierPerStatusCodDashboardData({
              sttNo: item.stt_no,
              sttStatus: item.stt_status,
              sttReason: item.stt_reason,
              sttCodAmount: item.stt_cod_amount,
              sttCollectionStatus: item.stt_collection_status,
              sttRecipientName: item.stt_recipient_name,
              sttRecipientPhone: item.stt_recipient_phone,
              sttDriverName: item.stt_driver_name,
              sttDriverPhone: item.stt_driver_phone,
              sttPaymentMethod: paymentMethod[item.stt_payment_method] ?? "-"
            })
        );
  }
  convertDetailCourierPartnerCodDashboardFromApi(
    res: AxiosResponse
  ): CodDashboardDetailCourier {
    const { data } = res;
    return new CodDashboardDetailCourier({
      courierPhone:
        data.data.delivery_driver_phone || data.data.delivery_pic_phone,
      courierName:
        data.data.delivery_driver_name || data.data.delivery_pic_name,
      courierId: data.data.delivery_partner_id,
      courierPartnerName: data.data.delivery_partner_name,
      courierPartnerPhone: data.data.delivery_partner_phone,
      courierPartnerContactPhone: data.data.delivery_partner_contact_phone,
      totalStt: data.data.total_stt,
      totalSttPod: data.data.total_stt_pod,
      totalCollected: data.data.total_collected,
      totalQrisCollected: data.data.total_qris_collected,
      totalOutstanding: data.data.total_outstanding,
      totalSttDel: data.data.total_stt_del,
      totalSttCodrejCollected: data.data.total_stt_codrej_collected,
      totalSttCodrejOutstanding: data.data.total_stt_codrej_outstanding,
      totalSttDexCollected: data.data.total_stt_dex_collected,
      totalSttDexOutstanding: data.data.total_stt_dex_outstanding,
      performanceSameDay: data.data.performance_same_day,
      percentageSameDay: data.data.percentage_same_day,
      performanceDifferentDay: data.data.performance_different_day,
      percentageDifferentDay: data.data.percentage_different_day
    });
  }

  convertDataCorporateDashboardFromApi(
    res: AxiosResponse
  ): DataCorporateDashboard {
    const {
      data: { data }
    } = res;
    const isDataArray = Array.isArray(data);
    return isDataArray
      ? new DataCorporateDashboard({
          claimReasons: data.map((item: any) => {
            return new ClaimReason({
              reason: item.reason || "",
              count: item.count || 0
            });
          }),
          deliveryPerRouteData: data.map((item: any) => {
            return new DeliveryPerRouteData({
              routeName: item.route_name || "",
              totalStt: item.total_stt || 0,
              sttByProductType:
                item.stt_by_product_type?.map((item2: any) => {
                  return new SttByProductTypeData({
                    productType: item2.product_type || "",
                    totalStt: item2.total_stt || 0
                  });
                }) || []
            });
          }),
          totalResi: new AreaBarChart({
            series: data.map((item: any) => item.value),
            categories: data.map((item: any) => item.label)
          }),
          performaDelivery: new AreaBarChart({
            series: data.map((item: any) => item.value?.toFixed(2)),
            categories: data.map((item: any) => item.label)
          })
        })
      : new DataCorporateDashboard({
          totalStt: data.total_stt || 0,
          totalSttCod: data.total_stt_cod || 0,
          totalSttNonCod: data.total_stt_noncod || 0,
          totalCodAmount: data.total_cod_amount || 0,
          totalAmount: data.total_amount || 0,
          totalTariffAmount: data.total_tariff_amount || 0,
          lastUpdatedAt: data.last_updated_at || "",
          totalClaim: data.total_claim || 0,
          totalClaimSubmitted: data.total_claim_submitted || 0,
          totalClaimApproved: data.total_claim_approved || 0,
          totalClaimCompleted: data.total_claim_completed || 0,
          totalClaimRejected: data.total_claim_rejected || 0,
          totalClaimMbg: data.total_value_claim_mbg || 0,
          totalReceipt: data.total_receipt || 0,
          distributionProduct: data.distribution_product,
          percentageSttOnTime: data.percentage_stt_ontime || 0,
          totalSttOnTime: data.ontime_total || 0,
          totalSttLate: data.late_total || 0,
          totalRoute: data.total_data || 0,
          performancePerRouteData:
            data.routes?.map((item: any) => {
              return new PerformancePerRouteData({
                routeName: item.route || "",
                performance: item.performance || 0,
                detailPerformance: item.detail.map((val: any) => {
                  return new DetailPerformanceData({
                    productType: val.type || "",
                    totalResi: val.total || 0
                  });
                })
              });
            }) || []
        });
  }

  convertDataReferralAffiliateFromApi(res: AxiosResponse): ReferralAffiliate {
    const {
      data: { data }
    } = res;

    return new ReferralAffiliate({
      posId: data.POS_ID,
      affiliatelink: data.affiliate_link,
      expiredAt: data.expired_at
    });
  }

  convertChangeDataFromApi(result: AxiosResponse): ResponsePayload {
    const { data } = result;
    return new ResponsePayload({
      success: data.success,
      message: data.message
    });
  }
}
