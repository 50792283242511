import { FlagsPermissionCodReconcile } from "@/feature-flags/cod-reconcile";

const Layout = () =>
  import(/* webpackChunkName: "Layout" */ "@/app/ui/layout/index.vue");
const DetailCourier = () =>
  import(
    /* webpackChunkName: "CodDelivery" */ "@/app/ui/views/cod-delivery/components/detail.vue"
  );
const CodReconcile = () =>
  import(
    /* webpackChunkName: "CodDelivery" */ "@/app/ui/views/cod-delivery/cod-reconcile/index.vue"
  );
const CodReconcileCourier = () =>
  import(
    /* webpackChunkName: "CodDelivery" */ "@/app/ui/views/cod-delivery/cod-reconcile/tabs/cod-courier.vue"
  );
const CodReconcilePicShuttle = () =>
  import(
    /* webpackChunkName: "CodDelivery" */ "@/app/ui/views/cod-delivery/cod-reconcile/tabs/cod-pic-shuttle.vue"
  );
const ReportCodCourier = () =>
  import(
    /* webpackChunkName: "CodDelivery" */ "@/app/ui/views/cod-delivery/cod-report-reconcile/tabs/report-courier.vue"
  );
const ReportCodPicShuttle = () =>
  import(
    /* webpackChunkName: "CodDelivery" */ "@/app/ui/views/cod-delivery/cod-report-reconcile/tabs/report-pic-shuttle.vue"
  );
const CodReportReconcile = () =>
  import(
    /* webpackChunkName: "CodDelivery" */ "@/app/ui/views/cod-delivery/cod-report-reconcile/index.vue"
  );

export default [
  {
    path: "/cod-delivery",
    component: Layout,
    name: "cod-delivery",
    meta: {
      icon: "cod-delivery",
      children: true,
      title: "Cod Delivery",
      name: "Cod Delivery",
      permission: FlagsPermissionCodReconcile.permission_cod_reconcile_enable
    },
    children: [
      {
        path: "cod-reconcile",
        name: "cod-reconcile",
        redirect: "/cod-delivery/cod-reconcile/cod-courier",
        component: CodReconcile,
        meta: {
          header: true,
          icon: "cod-reconcile",
          title: "COD Reconcile",
          name: "COD Reconcile",
          permission:
            FlagsPermissionCodReconcile.permission_cod_reconcile_enable
        },
        children: [
          {
            path: "cod-courier",
            name: "cod-courier",
            component: CodReconcileCourier,
            meta: {
              permission:
                FlagsPermissionCodReconcile.permission_cod_reconcile_enable
            }
          },
          {
            path: "cod-pic-shuttle",
            name: "cod-pic-shuttle",
            component: CodReconcilePicShuttle,
            meta: {
              permission:
                FlagsPermissionCodReconcile.permission_cod_reconcile_pic_enable
            }
          }
        ]
      },
      {
        path: "cod-report-reconcile",
        name: "cod-report-reconcile",
        component: CodReportReconcile,
        redirect: "/cod-delivery/cod-report-reconcile/report-courier",
        meta: {
          header: false,
          icon: "cod-report-reconcile",
          title: "Laporan Reconcile",
          name: "Laporan Reconcile",
          permission:
            FlagsPermissionCodReconcile.permission_cod_reconcile_enable
        },
        children: [
          {
            path: "report-courier",
            name: "report-courier",
            component: ReportCodCourier,
            meta: {
              permission:
                FlagsPermissionCodReconcile.permission_cod_reconcile_enable
            }
          },
          {
            path: "report-pic-shuttle",
            name: "report-pic-shuttle",
            component: ReportCodPicShuttle,
            meta: {
              permission:
                FlagsPermissionCodReconcile.permission_cod_reconcile_pic_enable
            }
          }
        ]
      }
    ]
  },
  {
    path: "/cod-delivery/cod-reconcile/cod-courier",
    component: Layout,
    meta: {
      hidden: true,
      parent: "COD Reconcile",
      permission:
        FlagsPermissionCodReconcile.permission_cod_reconcile_view_detail
    },
    children: [
      {
        path: "detail-courier",
        name: "Detail Kurir",
        component: DetailCourier,
        meta: {
          header: true,
          title: "COD Reconcile | Detail Kurir",
          name: "Detail Kurir",
          before: "/cod-delivery/cod-reconcile/cod-courier"
        }
      }
    ]
  },
  {
    path: "/cod-delivery/cod-reconcile/cod-pic-shuttle",
    component: Layout,
    meta: {
      hidden: true,
      parent: "COD Reconcile",
      permission:
        FlagsPermissionCodReconcile.permission_cod_reconcile_view_detail
    },
    children: [
      {
        path: "detail-pic-shuttle",
        name: "Detail PIC Shuttle",
        component: DetailCourier,
        meta: {
          header: true,
          title: "COD Reconcile | Detail PIC Shuttle ",
          name: "Detail PIC Shuttle",
          before: "/cod-delivery/cod-reconcile/cod-pic-shuttle"
        }
      }
    ]
  }
];
