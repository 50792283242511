import { HandoverApiRepository } from "@/app/infrastructures/repositories/api/HandoverApiRepository";
import {
  HandoverList,
  HandoverDetail,
  PartnerVendorList
} from "@/domain/entities/Handover";
import { UpdateHandoverRequestInterface } from "@/data/payload/contracts/HandoverRequest";
import {
  ResponsePayload,
  ResponseSttDetail
} from "@/domain/entities/ResponsePayload";
import { SubconsoleEntities } from "@/domain/entities/Subconsole";
import { injectable } from "tsyringe";
import {
  RequestListHandover,
  RequestPartnerVendorList
} from "@/data/payload/api/HandoverApiRequest";

@injectable()
export class HandoverPresenter {
  private repository: HandoverApiRepository;

  constructor(repository: HandoverApiRepository) {
    this.repository = repository;
  }

  public getListSubconsole(parentId: number): Promise<SubconsoleEntities> {
    return this.repository.getListSubconsole(parentId);
  }

  public getHandoverList(params: RequestListHandover): Promise<HandoverList> {
    return this.repository.getHandoverList(params);
  }

  public getDetailHandover(handoverId: number): Promise<HandoverDetail> {
    return this.repository.getDetailHandover(handoverId);
  }

  public getSttDetail(stt: string, bagNo:string, partnerCode: string): Promise<ResponseSttDetail> {
    return this.repository.getSttDetail(stt, bagNo, partnerCode);
  }

  public updateHandover(
    payload: UpdateHandoverRequestInterface
  ): Promise<ResponsePayload> {
    return this.repository.updateHandover(payload);
  }

  public getPartnerVendorList(
    params: RequestPartnerVendorList
  ): Promise<PartnerVendorList[]> {
    return this.repository.getPartnerVendorList(params);
  }
}
