import { QueryParamsEntities } from "./MainApp";
import { PaginationV2 } from "./Pagination";
import formatDateNormal from "@/app/infrastructures/misc/common-library/FormatDateNormal";

export class ListPickup {
    listPickupShipmentId = "";
    listPickupClientRef = "";
    listPickupPickupSchedule = "";
    listPickupTransportType = "";
    listPickupEstTotalKoli = 0;
    listPickupActualTotalKoli = 0;
    listPickupPickupType = "";
    listPickupServiceCodes = "";
    listPickupEstTotalShipment = 0;
    listPickupLatestStatus = "";
    isEllipsis= true;
    constructor(fields?: Partial<ListPickup>) {
      Object.assign(this, fields);
    }

    get formatPickupSchedule() {
      return formatDateNormal(this.listPickupPickupSchedule, "DD MMMM YYYY HH:mm");
    }
}

export class ListPickupList {
  pagination = new PaginationV2();
  data: ListPickup[] = [new ListPickup()];
  constructor(fields?: Partial<ListPickupList>) {
    Object.assign(this, fields);
  }
}

export class RequestListPickupList {
  shipmentId = "";
  pickupSchedule = "";
  transportType = "";
  serviceCode = "";
  lastStatus = "";
  sortBy = "";
  page = 1;
  perPage = 20;
  isTotalData = false;
  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
  constructor(fields?: Partial<RequestListPickupList>) {
    Object.assign(this, fields);
  }
}