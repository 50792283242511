import { FlightPlanForECargo } from "@/data/payload/api/CargoApiRequest";
import { Pagination } from "./Pagination";
import { OptionsClass } from "./MainApp";
import { FlightDetail } from "./NGen";
import { flags } from "@/feature-flags";

export class CargoEntities {
  pagination: Pagination;
  data: CargoData[];
  constructor(pagination: Pagination, data: CargoData[]) {
    this.pagination = pagination;
    this.data = data;
  }
}

export class DimensionData {
  length = 30;
  width = 20;
  height = 10;
  constructor(fields?: Partial<DimensionData>) {
    Object.assign(this, fields);
  }
}

export class DestinationCityData {
  id? = 0;
  code = "";
  name = "";
  label = "";
  constructor(fields?: Partial<DestinationCityData>) {
    Object.assign(this, fields);
  }
}

export class CargoData {
  id = 0;
  cargoNumber = "";
  bookingRequestId = "";
  isFromRtc = false;
  cargoType = "";
  vehiclePoliceNumber = "";
  originCityCode = "";
  originCityName = "";
  destinationCityCode = "";
  destinationCityName = "";
  airportOriginCityCode = "";
  airportOriginCityName = "";
  airportDestinationCityCode = "";
  airportDestinationCityName = "";
  originDistrictCode = "";
  destinationDistrictCode = "";
  totalStt = 0;
  totalPieces = 0;
  totalGrossWeight = 0;
  totalVolumeWeight = 0;
  totalEstCargoGrossWeight = 0;
  totalEstCargoVolumeWeight = 0;
  totalActualCargoGrossWeight: any = 0;
  totalActualCargoVolumeWeight: any = 0;
  cargoActualPiece = 0;
  partnerName = "";
  partnerType = "";
  cargoCommodity = "";
  cargoCommodityCode = "";
  cargoProduct = "";
  cargoProductCode = "";
  shcCode = "";
  shcDescription = "";
  estimationDepartureData = "";
  departureDate = "";
  arrivalDate = "";
  createdAt = "";
  nGenCreatedAt = "";
  createdBy = "";
  updatedAt = "";
  updatedBy = "";
  detailStt = [] as CargoDetailStt[];
  flightsDetail = [] as CargoFlightDetail[];
  totalPiecesBagStt = 0;
  actualDimension: DimensionData = new DimensionData();
  estDimension: DimensionData = new DimensionData();
  nog = "";
  partnerId = 0;
  cargoStatus = "";
  flightNumbers = [] as any;
  reasonFailed = "";
  constructor(fields?: Partial<CargoData>) {
    Object.assign(this, fields);
  }
}

export class CargoFlightDetail {
    cfNumber = "";
    cfEstDepartureTime = "";
    cfAirportOrigin = "";

    constructor(fields?: Partial<CargoFlightDetail>) {
      Object.assign(this, fields);
    }
}
export class CargoDetailStt {
  baggingNumber = "";
  baggingVendorNumber = "";
  baggingVendorNo = "";
  sttNumber = "";
  totalPieces = 0;
  grossWeigth = 0;
  volumeWeight = 0;
  productType = "";
  commodity = "";
  action = "";
  origin = "";
  destination = "";
  destinationCityCode = "";
  destinationCityName = "";
  regionId = "";
  regionName = "";
  commodityGroupCode = "";
  commodityGroupName = "";
  commodityGroup = "";
  shcCode = "";
  shcName = "";
  sttElexysNumber? = "";
  bagCustomGrossWeight? = 0;
  isFromRebooking? = false;
  sttNoRefExternal? = "";
  constructor(fields?: Partial<CargoDetailStt>) {
    Object.assign(this, fields);
  }
}

export class CargoCommodityList {
  pagination: Pagination;
  data: CargoCommodityData[];
  constructor(pagination: Pagination, data: CargoCommodityData[]) {
    this.pagination = pagination;
    this.data = data;
  }
}

export class CargoCommodityData {
  commodityGroupId: number;
  commodityGroupCode: string;
  commodityGroupName: string;
  createdAt: string;
  updatedAt: string;
  constructor(
    commodityGroupId: number,
    commodityGroupCode: string,
    commodityGroupName: string,
    createdAt: string,
    updatedAt: string
  ) {
    this.commodityGroupId = commodityGroupId;
    this.commodityGroupCode = commodityGroupCode;
    this.commodityGroupName = commodityGroupName;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }
}

export class SearchCargoOptions {
  data: SearchCargoOption[];
  constructor(data: SearchCargoOption[]) {
    this.data = data;
  }
}

export class SearchCargoOption {
  transportLabel: string;
  transportType: string;
  constructor(transportLabel: string, transportType: string) {
    this.transportLabel = transportLabel;
    this.transportType = transportType;
  }
}

export class CargoSttList {
  data: CargoSttValidaton[];
  constructor(data: CargoSttValidaton[]) {
    this.data = data;
  }
}

export class CargoSttValidaton {
  isAllowUpdateStatus: boolean;
  errorMessage: string;
  stt: CargoSttDetail;
  constructor(
    isAllowUpdateStatus: boolean,
    errorMessage: string,
    stt: CargoSttDetail
  ) {
    this.isAllowUpdateStatus = isAllowUpdateStatus;
    this.errorMessage = errorMessage;
    this.stt = stt;
  }
}

export class CargoSttDetail {
  bagNo = "";
  sttId = 0;
  sttNo = "";
  sttTotalPiece = 0;
  sttGrossWeight = 0;
  sttVolumeWeight = 0;
  sttProductType = "";
  sttCommodityCode = "";
  sttCommodityName = "";
  piece = [] as CargoPieceDetail[];
  constructor(fields?: Partial<CargoSttDetail>) {
    Object.assign(this, fields);
  }
}
export class CargoPieceDetail {
  sttPieceId: number;
  sttPieceGrossWeight: number;
  sttPieceVolumeWeight: number;
  sttPieceNo: number;
  constructor(
    sttPieceId: number,
    sttPieceGrossWeight: number,
    sttPieceVolumeWeight: number,
    sttPieceNo: number
  ) {
    this.sttPieceId = sttPieceId;
    this.sttPieceGrossWeight = sttPieceGrossWeight;
    this.sttPieceVolumeWeight = sttPieceVolumeWeight;
    this.sttPieceNo = sttPieceNo;
  }
}

export class CargoEditResponse {
  cargoId: number;
  cargoNo: string;
  totalSttSuccess: number;
  totalSttFailed: number;
  listSttFailed: string[];
  totalSttAddSuccess: number;
  totalSttRemoveeSuccess: number;
  constructor(
    cargoId: number,
    cargoNo: string,
    totalSttSuccess: number,
    totalSttFailed: number,
    listSttFailed: string[],
    totalSttAddSuccess: number,
    totalSttRemoveeSuccess: number
  ) {
    this.cargoId = cargoId;
    this.cargoNo = cargoNo;
    this.totalSttSuccess = totalSttSuccess;
    this.totalSttFailed = totalSttFailed;
    this.listSttFailed = listSttFailed;
    this.totalSttAddSuccess = totalSttAddSuccess;
    this.totalSttRemoveeSuccess = totalSttRemoveeSuccess;
  }
}

export class CargoProductEntities {
  data: CargoProductData[];
  constructor(data: CargoProductData[]) {
    this.data = data;
  }
}

export class CargoProductData {
  cargoProductTypeId: number;
  cargoProductTypeCode: string;
  cargoProductTypeStatus: string;

  constructor(
    cargoProductTypeId: number,
    cargoProductTypeCode: string,
    cargoProductTypeStatus: string
  ) {
    this.cargoProductTypeId = cargoProductTypeId;
    this.cargoProductTypeCode = cargoProductTypeCode;
    this.cargoProductTypeStatus = cargoProductTypeStatus;
  }
}

export class AirportEntities {
  pagination: Pagination;
  data: AirportData[];
  constructor(pagination: Pagination, data: AirportData[]) {
    this.pagination = pagination;
    this.data = data;
  }
}

export class AirportDetail {
  airportCode: string;
  airportName: string;
  constructor(airportCode: string, airportName: string) {
    this.airportCode = airportCode;
    this.airportName = airportName;
  }
}

export class AirportData {
  airportCode: string;
  airportName: string;
  cityCode: string;
  cityName: string;
  name?: string;
  value?: string;
  constructor(
    airportCode: string,
    airportName: string,
    cityCode: string,
    cityName: string,
    name?: string,
    value?: string
  ) {
    this.airportCode = airportCode;
    this.airportName = airportName;
    this.cityCode = cityCode;
    this.cityName = cityName;
    this.name = name;
    this.value = value;
  }
}

export class CargoAddResponse {
  cargoNo: string;
  totalSttSuccess: number;
  totalSttFailed: number;
  listSttFailed: string[];
  isCargoUsingAwb: boolean;
  bookingRequestId: string;
  constructor(
    cargoNo: string,
    totalSttSuccess: number,
    totalSttFailed: number,
    listSttFailed: string[],
    isCargoUsingAwb: boolean,
    bookingRequestId: string
  ) {
    this.cargoNo = cargoNo;
    this.totalSttSuccess = totalSttSuccess;
    this.totalSttFailed = totalSttFailed;
    this.listSttFailed = listSttFailed;
    this.isCargoUsingAwb = isCargoUsingAwb;
    this.bookingRequestId = bookingRequestId;
  }
}

export class ListLastReadCargo {
  cargoNumber = "";
  awbNumber = "";
  status = "";
  destination = "";
  message = "";
  constructor(fields?: Partial<ListLastReadCargo>) {
    Object.assign(this, fields);
  }
}

export class CargoPlaneAutoData {
  route = "";
  cargoNumber = "";
  vehicleNumber = "";
  estDepartureDate = "";
  estArrivalDate = "";
  flightData: Array<FlightPlanForECargo> | undefined = undefined;
  constructor(fields?: Partial<CargoPlaneAutoData>) {
    Object.assign(this, fields);
  }
}

export class OriginCityData {
  code = "";
  name = "";
  constructor(fields?: Partial<OriginCityData>) {
    Object.assign(this, fields);
  }
}

export class CargoInputData {
  id? = 0;
  totalActualCargoGrossWeight: number | string = 0;
  calcuateActualCargoGrossWeight = 0;
  dimension: DimensionData = new DimensionData();
  totalActualCargoVolumeWeight: number | string = 0;
  totalActualCargoColi = 0;
  estTotalCargoGrossWeight = 0;
  estTotalCargoVolumeWeight = 0;
  originCityData = new OriginCityData();
  cargoComodity = new OptionsClass();
  nog = "";
  cargoNumber = "";
  cargoProduct = "Express";
  vehicleNumber = "";
  airportOriginCode = "";
  airportDestinationCode = "";
  estDepartureDate = new Date() as any;
  estArrivalDate = new Date(new Date().setDate(new Date().getDate() + 6)).toISOString() as any;
  destinationCityData: DestinationCityData = new DestinationCityData();
  flightData: FlightDetail[] = [];
  airportOrigin: AirportData = new AirportData("", "", "", "", "", "");
  airportDestination: AirportData = new AirportData("", "", "", "", "", "");
  thresholdTime = flags.cargo_config_flight_treshold_default_value.getValue()
  isFromRebookingRTC = false;
  constructor(fields?: Partial<CargoInputData>) {
    Object.assign(this, fields);
  }
}
