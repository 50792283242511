import { Pagination } from "@/domain/entities/Pagination";

export class DeliveryManifestEntities {
  pagination: Pagination;
  data: DeliveryManifestData[];

  constructor(pagination: Pagination, data: DeliveryManifestData[]) {
    this.pagination = pagination;
    this.data = data;
  }
}

export class DeliveryManifestData {
  deliveryManifestId: number;
  deliveryManifestTotalStt: string;
  deliveryManifestTotalPiece: string;
  deliveryManifestNo: string;
  deliveryManifestTotalGrossWeight: string;
  deliveryManifestTotalVolumeWeight: string;
  deliveryManifestCreatedAt: string;
  deliveryManifestCreatedBy: string;

  constructor(
    deliveryManifestId: number,
    deliveryManifestTotalStt: string,
    deliveryManifestTotalPiece: string,
    deliveryManifestNo: string,
    deliveryManifestTotalGrossWeight: string,
    deliveryManifestTotalVolumeWeight: string,
    deliveryManifestCreatedAt: string,
    deliveryManifestCreatedBy: string
  ) {
    this.deliveryManifestId = deliveryManifestId;
    this.deliveryManifestTotalStt = deliveryManifestTotalStt;
    this.deliveryManifestTotalPiece = deliveryManifestTotalPiece;
    this.deliveryManifestNo = deliveryManifestNo;
    this.deliveryManifestTotalGrossWeight = deliveryManifestTotalGrossWeight;
    this.deliveryManifestTotalVolumeWeight = deliveryManifestTotalVolumeWeight;
    this.deliveryManifestCreatedAt = deliveryManifestCreatedAt;
    this.deliveryManifestCreatedBy = deliveryManifestCreatedBy;
  }
}

export class STTToBeGenerate {
  sttNumber: string;
  bagNumber: string;
  sttPieces: DetailPieces[];
  sttElexysNumber?: string;

  constructor(
    sttNumber: string,
    bagNumber: string,
    sttPieces: DetailPieces[],
    sttElexysNumber?: string
  ) {
    this.sttNumber = sttNumber;
    this.bagNumber = bagNumber;
    this.sttPieces = sttPieces;
    this.sttElexysNumber = sttElexysNumber;
  }
}

export class UpdateDeliveryResponse {
  deliveryManifestId: number;
  totalSttSuccess: number;
  totalSttFailed: number;
  sttFailed: Array<string>;

  constructor(
    deliveryManifestId: number,
    totalSttSuccess: number,
    totalSttFailed: number,
    sttFailed: Array<string>
  ) {
    this.deliveryManifestId = deliveryManifestId;
    this.totalSttSuccess = totalSttSuccess;
    this.totalSttFailed = totalSttFailed;
    this.sttFailed = sttFailed;
  }
}

export class DetailSttDeliveryManifest {
  isAllowUpdateStatus: boolean;
  errorMessage: string;
  isPaid?: boolean;
  sttNumber: string;
  bagNumber: string;
  sttTotalPiece: number;
  destinationCity: string;
  product: string;
  woodPacking: string;
  commodity: string;
  codAmount: string;
  grossWeight: number;
  volumeWeight: number;
  chargeAbleWeight: number;
  deliveryCount: number;
  recipientName: string;
  recipientAddress: string;
  recipientPhone: string;
  sttElexysNumber?: string;
  sttNoRefExternal?: string;
  constructor(
    isAllowUpdateStatus: boolean,
    errorMessage: string,
    sttNumber: string,
    bagNumber: string,
    sttTotalPiece: number,
    destinationCity: string,
    product: string,
    woodPacking: string,
    commodity: string,
    codAmount: string,
    grossWeight: number,
    volumeWeight: number,
    chargeAbleWeight: number,
    deliveryCount: number,
    recipientName: string,
    recipientAddress: string,
    recipientPhone: string,
    isPaid?: boolean,
    sttElexysNumber?: string,
    sttNoRefExternal?: string
  ) {
    this.bagNumber = bagNumber;
    this.sttNumber = sttNumber;
    this.isAllowUpdateStatus = isAllowUpdateStatus;
    this.errorMessage = errorMessage;
    this.isPaid = isPaid;
    this.sttTotalPiece = sttTotalPiece;
    this.destinationCity = destinationCity;
    this.product = product;
    this.woodPacking = woodPacking;
    this.commodity = commodity;
    this.codAmount = codAmount;
    this.grossWeight = grossWeight;
    this.volumeWeight = volumeWeight;
    this.chargeAbleWeight = chargeAbleWeight;
    this.deliveryCount = deliveryCount;
    this.recipientName = recipientName;
    this.recipientAddress = recipientAddress;
    this.recipientPhone = recipientPhone;
    this.sttElexysNumber = sttElexysNumber;
    this.sttNoRefExternal = sttNoRefExternal
  }
}

export class DetailPieces {
  sttNumber: string;
  sttPieceNumber: string;
  pieceNumber: number;
  destinationCity: string;
  product: string;
  woodPacking: string;
  commodity: string;
  codAmount: string;
  grossWeight: number;
  volumeWeight: number;
  chargeAbleWeight: number;
  deliveryCount: number;
  recipientName: string;
  recipientAddress: string;
  recipientPhone: string;
  constructor(
    sttNumber: string,
    sttPieceNumber: string,
    pieceNumber: number,
    cityDestination: string,
    product: string,
    woodPacking: string,
    commodity: string,
    codAmount: string,
    grossWeight: number,
    volumeWeight: number,
    chargeAbleWeight: number,
    deliveryCount: number,
    recipientName: string,
    recipientAddress: string,
    recipientPhone: string
  ) {
    this.sttNumber = sttNumber;
    this.sttPieceNumber = sttPieceNumber;
    this.pieceNumber = pieceNumber;
    this.destinationCity = cityDestination;
    this.product = product;
    this.woodPacking = woodPacking;
    this.commodity = commodity;
    this.codAmount = codAmount;
    this.grossWeight = grossWeight;
    this.volumeWeight = volumeWeight;
    this.chargeAbleWeight = chargeAbleWeight;
    this.deliveryCount = deliveryCount;
    this.recipientAddress = recipientAddress;
    this.recipientName = recipientName;
    this.recipientPhone = recipientPhone;
  }
}

export class DeliveryManifestPdfData {
  deliveryManifestId = 0;
  deliveryManifestNo = "";
  deliveryManifestDestinationCityId = "";
  deliveryManifestDestinationCityName = "";
  deliveryManifestPartnerId = 0;
  deliveryManifestPartnerCode = "";
  deliveryManifestPartnerName = "";
  deliveryManifestTotalStt = 0;
  deliveryManifestTotalSttPiece = 0;
  deliveryManifestTotalGrossWeight = 0;
  deliveryManifestTotalVolumeWeight = 0;
  deliveryManifestCreatedAt = "";
  deliveryManifestCreatedName = "";
  stt = [] as DeliveryManifestPdfStt[];
  deliveryManifestUrsaCode = "";
  constructor(fields?: Partial<DeliveryManifestPdfData>) {
    Object.assign(this, fields);
  }
}

export class DeliveryManifestPdfStt {
  sttId: number;
  sttNo: string;
  sttProductType: string;
  sttTotalPiece: number;
  sttDestinationCityId: string;
  sttDestinationCityName: string;
  sttWoodPacking: string;
  sttCommodityCode: string;
  sttCommodityName: string;
  sttGrossWeight: number;
  sttVolumeWeight: number;
  sttChargeableWeight: number;
  sttCodAmount: number;
  sttRecipientName: string;
  sttRecipientAddress: string;
  sttRecipientPhone: string;
  piece: DeliveryManifestPdfPieces[];
  sttElexysNumber?: string;
  constructor(
    sttId: number,
    sttNo: string,
    sttProductType: string,
    sttTotalPiece: number,
    sttDestinationCityId: string,
    sttDestinationCityName: string,
    sttWoodPacking: string,
    sttCommodityCode: string,
    sttCommodityName: string,
    sttGrossWeight: number,
    sttVolumeWeight: number,
    sttChargeableWeight: number,
    sttCodAmount: number,
    sttRecipientName: string,
    sttRecipientAddress: string,
    sttRecipientPhone: string,
    piece: DeliveryManifestPdfPieces[],
    sttElexysNumber?: string
  ) {
    this.sttId = sttId;
    this.sttNo = sttNo;
    this.sttProductType = sttProductType;
    this.sttTotalPiece = sttTotalPiece;
    this.sttDestinationCityId = sttDestinationCityId;
    this.sttDestinationCityName = sttDestinationCityName;
    this.sttWoodPacking = sttWoodPacking;
    this.sttCommodityCode = sttCommodityCode;
    this.sttCommodityName = sttCommodityName;
    this.sttGrossWeight = sttGrossWeight;
    this.sttVolumeWeight = sttVolumeWeight;
    this.sttChargeableWeight = sttChargeableWeight;
    this.sttCodAmount = sttCodAmount;
    this.sttRecipientName = sttRecipientName;
    this.sttRecipientAddress = sttRecipientAddress;
    this.sttRecipientPhone = sttRecipientPhone;
    this.piece = piece;
    this.sttElexysNumber = sttElexysNumber;
  }
}

export class DeliveryManifestPdfPieces {
  sttPieceId: number;
  sttPieceGrossWeight: number;
  sttPieceVolumeWeight: number;
  sttPieceNo: 1;
  constructor(
    sttPieceId: number,
    sttPieceGrossWeight: number,
    sttPieceVolumeWeight: number,
    sttPieceNo: 1
  ) {
    this.sttPieceId = sttPieceId;
    this.sttPieceGrossWeight = sttPieceGrossWeight;
    this.sttPieceVolumeWeight = sttPieceVolumeWeight;
    this.sttPieceNo = sttPieceNo;
  }
}
