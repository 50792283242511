/* eslint-disable @typescript-eslint/camelcase */
import {
  CheckPaymentStatusApiRequest,
  ReasonListDex,
  RequestListPodDex,
  UpdatePodDexApiRequest
} from "@/data/payload/api/PodDexRequest";
import {
  CheckPaymentStatusRequestInterface,
  UpdatePodDexRequestInterface
} from "@/data/payload/contracts/PodDexRequest";
import { PodDexMapper } from "@/data/persistences/mappers/PodDexMapper";
import { PodDexRepositoryInterface } from "@/data/persistences/repositories/contracts/PodDexRepositoryInterface";
import { CheckPaymentStatus, ReasonData } from "@/domain/entities/PodDex";
import {
  ResponsePayload,
  Entities,
  ResponsePayloadV2
} from "@/domain/entities/ResponsePayload";
import { Endpoints } from "../../misc/Endpoints";
import ApiService from "../../services/ApiService";
import { ApiRequestList } from "@/domain/entities/MainApp";

export class PodDexApiRepository implements PodDexRepositoryInterface {
  private service: ApiService;
  private mapper: PodDexMapper;
  private endpoints: Endpoints;

  constructor(service: ApiService, mapper: PodDexMapper, endpoints: Endpoints) {
    this.service = service;
    this.mapper = mapper;
    this.endpoints = endpoints;
  }
  async getListReason(params: ReasonListDex): Promise<ReasonData[]> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getListReason(params),
      {}
    );
    return this.mapper.convertListReasonDataFromApi(resp);
  }

  async getPodDownload(params: ApiRequestList): Promise<ResponsePayloadV2> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getPodDownload(params),
      {}
    );
    return this.mapper.convertDownloadData(resp);
  }

  async updatePodDex(
    payload: UpdatePodDexRequestInterface
  ): Promise<ResponsePayload> {
    const resp = await this.service.invokePostWithFormData(
      "post",
      this.endpoints.updatePodDex(),
      undefined,
      payload as UpdatePodDexApiRequest
    );
    return this.mapper.convertPayloadData(resp);
  }
  async getPodDexList(params: RequestListPodDex): Promise<Entities> {
    const resp = await this.service.invoke(
      "get",
      this.endpoints.getPodDexList(params),
      {}
    );
    return this.mapper.convertPodDexListDataFromApi(resp);
  }

  async checkPaymentStatus(
    payload: CheckPaymentStatusRequestInterface
  ): Promise<CheckPaymentStatus> {
    const resp = await this.service.invoke(
      "post",
      this.endpoints.checkPaymentStatus(),
      undefined,
      payload as CheckPaymentStatusApiRequest
    );
    return this.mapper.convertPayloadCheckPaymentStatusData(resp);
  }
}
