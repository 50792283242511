import {
  ALLOWED,
  CUSTOM_PROCESS,
  INCOMING_PROCESS_DELIVERY_MANIFEST,
  INCOMING_PROCESS_HANDOVER,
  INCOMING_PROCESS_POD_DEX,
  INCOMING_PROCESS_SHORTLAND,
  INCOMING_PROCESS_STI_DEST,
  INCOMING_PROCESS_STI_DEST_SC,
  OUTGOING_SHIPMENT_BAGGING,
  OUTGOING_SHIPMENT_PICKUP_MANIFEST,
  OUTGOING_SHIPMENT_STI,
  OUTGOING_SHIPMENT_STI_SC,
  UPDATE_DELIVERY,
  OUTGOING_SHIPMENT_STO_SC
} from "@/app/infrastructures/misc/RolePermission";

import CargoModules from "@/app/ui/router/modules/cargo";

const Layout = () =>
  import(/* webpackChunkName: "Layout" */ "@/app/ui/layout/index.vue");

// Pickup
const PickupManifest = () =>
  import(
    /* webpackChunkName: "PUP" */ "@/app/ui/views/out-going-shipment/pickup-manifest/index.vue"
  );
const CreatePickupManifest = () =>
  import(
    /* webpackChunkName: "PUP" */ "@/app/ui/views/out-going-shipment/pickup-manifest/component/create-form/index.vue"
  );

// Sti SC
const StiSC = () =>
  import(
    /* webpackChunkName: "STISC" */ "@/app/ui/views/out-going-shipment/sti-sc/index.vue"
  );
const UpdateStiSC = () =>
  import(
    /* webpackChunkName: "STISC" */ "@/app/ui/views/out-going-shipment/sti-sc/component/update-v2/index.vue"
  );

// Sto SC
const StoSC = () =>
  import(
    /* webpackChunkName: "STOSC" */ "@/app/ui/views/out-going-shipment/sto-sc/index.vue"
  );
const tabProcessSTOSC = () =>
  import(
    /* webpackChunkName: "STOSC" */ "@/app/ui/views/out-going-shipment/sto-sc/tab-process-to-sto-sc.vue"
  );
const tabSTOSC = () =>
  import(
    /* webpackChunkName: "STOSC" */ "@/app/ui/views/out-going-shipment/sto-sc/tab-sto-sc.vue"
  );

// STI
const STI = () =>
  import(
    /* webpackChunkName: "STI" */ "@/app/ui/views/out-going-shipment/sti/sti-list.vue"
  );
const UpdateSTI = () =>
  import(
    /* webpackChunkName: "STI" */ "@/app/ui/views/out-going-shipment/sti/component/update/sti-update.vue"
  );
const DetailSTI = () =>
  import(
    /* webpackChunkName: "STI" */ "@/app/ui/views/out-going-shipment/sti/component/detail/sti-detail.vue"
  );
const NeedToSTI = () =>
  import(
    /* webpackChunkName: "STI" */ "@/app/ui/views/out-going-shipment/sti/reverse-scan/sti-need-to-sti-reverse-scan.vue"
  );

// Bagging
const Bagging = () =>
  import(
    /* webpackChunkName: "Bagging" */ "@/app/ui/views/out-going-shipment/bagging/index.vue"
  );
const DetailBagging = () =>
  import(
    /* webpackChunkName: "Bagging" */ "@/app/ui/views/out-going-shipment/bagging/component/detail.vue"
  );
const UpdateBagging = () =>
  import(
    /* webpackChunkName: "Bagging" */ "@/app/ui/views/out-going-shipment/bagging/component/update/index.vue"
  );
const CreateBagging = () =>
  import(
    /* webpackChunkName: "Bagging" */ "@/app/ui/views/out-going-shipment/bagging/component/create/index.vue"
  );

// Clearance
const Clearance = () =>
  import(
    /* webpackChunkName: "Clearance" */ "@/app/ui/views/out-going-shipment/clearance/index.vue"
  );
const ClearanceDetail = () =>
  import(
    /* webpackChunkName: "Clearance" */ "@/app/ui/views/out-going-shipment/clearance/detail/index.vue"
  );

// HANDOVER
const HandoverList = () =>
  import(
    /* webpackChunkName: "Handover" */ "@/app/ui/views/incoming-process/handover/index.vue"
  );
const UpdateHandover = () =>
  import(
    /* webpackChunkName: "Handover" */ "@/app/ui/views/incoming-process/handover/component/update-handover.vue"
  );
const DetailHandover = () =>
  import(
    /* webpackChunkName: "Handover" */ "@/app/ui/views/incoming-process/handover/component/detail.vue"
  );

// STI-DEST
const StiDest = () =>
  import(
    /* webpackChunkName: "STIDEST" */ "@/app/ui/views/incoming-process/sti-dest/index.vue"
  );
const UpdateStiDest = () =>
  import(
    /* webpackChunkName: "STIDEST" */ "@/app/ui/views/incoming-process/sti-dest/component/update/sti-dest-update.vue"
  );
const DetailStiDest = () =>
  import(
    /* webpackChunkName: "STIDEST" */ "@/app/ui/views/incoming-process/sti-dest/component/sti-dest-detail.vue"
  );

// DELIVERY MANIFEST
const DeliveryManifest = () =>
  import(
    /* webpackChunkName: "DeliveryManifest" */ "@/app/ui/views/incoming-process/delivery-manifest/index.vue"
  );
const UpdateDeliveryManifest = () =>
  import(
    /* webpackChunkName: "DeliveryManifest" */ "@/app/ui/views/incoming-process/delivery-manifest/component/update/index.vue"
  );

// STI DEST-SC
const StiDestSc = () =>
  import(
    /* webpackChunkName: "STIDESTSC" */ "@/app/ui/views/incoming-process/sti-dest-sc/index.vue"
  );
const UpdateStiDestSc = () =>
  import(
    /* webpackChunkName: "STIDESTSC" */ "@/app/ui/views/incoming-process/sti-dest-sc/component/update/index.vue"
  );

// SHORTLAND
const Shortland = () =>
  import(
    /* webpackChunkName: "Shortland" */ "@/app/ui/views/incoming-process/shortland/index.vue"
  );

// POD/DEX
const DelDex = () =>
  import(
    /* webpackChunkName: "PODEX" */ "@/app/ui/views/incoming-process/delivery/del-dex/index.vue"
  );
const Codrej = () =>
  import(
    /* webpackChunkName: "PODEX" */ "@/app/ui/views/incoming-process/delivery/codrej/index.vue"
  );
const Pod = () =>
  import(
    /* webpackChunkName: "PODEX" */ "@/app/ui/views/incoming-process/delivery/pod/index.vue"
  );
const Delivery = () =>
  import(
    /* webpackChunkName: "PODEX" */ "@/app/ui/views/incoming-process/delivery/index.vue"
  );

// DELIVERY
const UpdateDeliveryList = () =>
  import(
    /* webpackChunkName: "DEL" */ "@/app/ui/views/incoming-process/update-delivery/index.vue"
  );
const UpdateDelivery = () =>
  import(
    /* webpackChunkName: "DEL" */ "@/app/ui/views/incoming-process/update-delivery/update-delivery-form/index.vue"
  );

// Custom Status
const CustomStatus = () =>
  import(
    /* webpackChunkName: "CustomProcess" */ "@/app/ui/views/custom-process/index.vue"
  );
// Release
const Release = () =>
  import(
  /* webpackChunkName: "Release" */ "@/app/ui/views/out-going-shipment/release/index.vue"
  );
const ReleaseDetail = () =>
  import(
  /* webpackChunkName: "ReleaseDetail" */ "@/app/ui/views/out-going-shipment/release/detail/index.vue"
  );
import CustomStatusModules from "./custom-process";
import CODReconcile from "../cod-reconcile";
const Cargo = () =>
  import(/* webpackChunkName: "Cargo" */ "@/app/ui/views/cargo/index.vue");
import { flags } from "@/feature-flags";

import { FlagsPermissionOutgoingShipmentClearance } from "@/feature-flags/flags-outgoing-shipment-clearance";
import { FlagsPermissionOutgoingShipmentRelease } from "@/feature-flags/flags-out-going-shipment-release";
import { FlagsPermissionShortland } from "@/feature-flags/flags-permission-shortland";
import { FlagsPermissionHandover } from "@/feature-flags/flags-permission-handover";
import { FlagsPermissionDelivery } from "@/feature-flags/flags-permission-delivery";
import { FlagsPermissionPodDex } from "@/feature-flags/flags-pod-dex";
import UrgentDeliveryManagement from "../urgent-delivery-management";
export default [
  // pickup
  {
    path: "/pickup-manifest",
    component: Layout,
    name: "pickup-manifest",
    meta: {
      header: true,
      icon: "pickup-manifest",
      title: "Pickup",
      name: "Pickup",
      isFreeze: true,
      permission: OUTGOING_SHIPMENT_PICKUP_MANIFEST.ENABLE
    },
    children: [
      {
        path: "/pickup-manifest",
        name: "Pickup",
        component: PickupManifest,
        meta: {
          header: true
        }
      }
    ]
  },
  {
    path: "/pickup-manifest",
    component: Layout,
    name: "create-pickup-manifest",
    meta: {
      hidden: true,
      parent: "Pickup",
      permission: OUTGOING_SHIPMENT_PICKUP_MANIFEST.CREATE
    },
    children: [
      {
        path: "create",
        name: "create-pickup-manifest",
        component: CreatePickupManifest,
        meta: {
          header: true,
          title: "Pickup | Buat ",
          name: "Buat Pickup",
          before: "/pickup-manifest",
          validateAccount: true
        }
      }
    ]
  },
  // cod reconcile
  ...CODReconcile,
  // sti-sc
  {
    path: "/sti-sc",
    component: Layout,
    name: "sti-sc",
    meta: {
      header: true,
      icon: "sti-sc",
      title: "STI-SC",
      name: "STI-SC",
      permission: OUTGOING_SHIPMENT_STI_SC.ENABLE
    },
    children: [
      {
        path: "/sti-sc",
        name: "STI-SC",
        component: StiSC,
        meta: {
          header: true
        }
      }
    ]
  },
  {
    path: "/sto-sc",
    component: Layout,
    name: "sto-sc",
    meta: {
      header: true,
      icon: "sto-sc",
      title: "STO-SC",
      name: "STO-SC",
      permission: OUTGOING_SHIPMENT_STO_SC.ENABLE
    },
    children: [
      {
        path: "/sto-sc",
        redirect: "/sto-sc/process-to-sto-sc",
        name: "STO-SC",
        component: StoSC,
        meta: {
          header: true,
          icon: "sto-sc",
          permission: OUTGOING_SHIPMENT_STO_SC.VIEW
        },
        children: [
          {
            path: "process-to-sto-sc",
            name: "process-to-sto-sc",
            component: tabProcessSTOSC,
            meta: {
              header: true
            }
          },
          {
            path: "list",
            name: "list-sto-sc",
            component: tabSTOSC,
            meta: {
              header: true
            }
          }
        ]
      }
    ]
  },
  {
    path: "/sti-sc",
    component: Layout,
    name: "update-sti-sc",
    meta: {
      hidden: true,
      parent: "STI-SC",
      permission: OUTGOING_SHIPMENT_STI_SC.CREATE
    },
    children: [
      {
        path: "update",
        name: "update-sti-sc",
        component: UpdateStiSC,
        meta: {
          header: true,
          title: "STI-SC | Update",
          name: "Update STI SC",
          before: "/sti-sc"
        }
      }
    ]
  },
  // sti
  {
    path: "/station-transit-in",
    component: Layout,
    name: "sti",
    meta: {
      header: true,
      icon: "sti",
      title: "STI",
      name: "STI",
      isFreeze: true,
      permission: OUTGOING_SHIPMENT_STI.ENABLE
    },
    children: [
      {
        path: "/station-transit-in",
        name: "STI",
        component: STI,
        meta: {
          header: true
        }
      }
    ]
  },
  {
    path: "/station-transit-in",
    component: Layout,
    name: "update-sti",
    meta: {
      hidden: true,
      parent: "STI",
      permission: OUTGOING_SHIPMENT_STI.CREATE
    },
    children: [
      {
        path: "update",
        name: "update-sti",
        component: UpdateSTI,
        meta: {
          header: true,
          title: "STI | Update",
          name: "Update STI",
          before: "/station-transit-in"
        }
      }
    ]
  },
  {
    path: "/station-transit-in",
    component: Layout,
    name: "detail-sti",
    meta: {
      hidden: true,
      parent: "Station Transit In Consolidator",
      isFreeze: true,
      permission: OUTGOING_SHIPMENT_STI.DETAIL
    },
    children: [
      {
        path: "detail/:id",
        name: "detail-sti",
        component: DetailSTI,
        meta: {
          header: false,
          title: "STI | Detail",
          name: "Detail STI",
          before: "/station-transit-in"
        }
      }
    ]
  },
  {
    path: "/station-transit-in/update",
    component: Layout,
    name: "need-to-sti",
    meta: {
      hidden: true,
      parent: "Update STI",
      permission: OUTGOING_SHIPMENT_STI.CREATE
    },
    children: [
      {
        path: "need-to-sti",
        name: "need-to-sti",
        component: NeedToSTI,
        meta: {
          header: true,
          title: "STI | Daftar harus STI",
          name: "Daftar harus STI",
          before: "/station-transit-in/update"
        }
      }
    ]
  },
  // bagging
  {
    path: "/bagging",
    component: Layout,
    name: "bagging",
    meta: {
      header: true,
      icon: "outgoing-bagging",
      title: "Bagging",
      name: "Bagging",
      isFreeze: true,
      permission: OUTGOING_SHIPMENT_BAGGING.ENABLE
    },
    children: [
      {
        path: "/bagging",
        name: "bagging",
        component: Bagging,
        meta: {
          header: true
        }
      }
    ]
  },
  {
    path: "/bagging",
    component: Layout,
    name: "Bagging-detail",
    meta: {
      title: "Bagging | Detail",
      hidden: true,
      parent: "Bagging",
      isFreeze: true,
      permission: OUTGOING_SHIPMENT_BAGGING.DETAIL
    },
    children: [
      {
        path: ":id",
        name: "detail-bagging",
        component: DetailBagging,
        meta: {
          header: true,
          title: "Bagging | Detail Bagging",
          name: "Detail Bagging",
          before: "/bagging"
        }
      }
    ]
  },
  {
    path: "/bagging",
    component: Layout,
    name: "create-bagging",
    meta: {
      hidden: true,
      parent: "Bagging",
      permission: OUTGOING_SHIPMENT_BAGGING.CREATE
    },
    children: [
      {
        path: "create",
        name: "create-bagging",
        component: CreateBagging,
        meta: {
          header: true,
          title: "Bagging | Buat Bagging",
          name: "Buat Bagging",
          before: "/bagging"
        }
      }
    ]
  },
  {
    path: "/bagging",
    component: Layout,
    name: "update-bagging",
    meta: {
      hidden: true,
      parent: "Bagging",
      permission: OUTGOING_SHIPMENT_BAGGING.EDIT
    },
    children: [
      {
        path: ":id/edit",
        name: "update-bagging",
        component: UpdateBagging,
        meta: {
          header: true,
          name: "Ubah Bagging",
          title: "Bagging | Ubah Bagging",
          before: "/bagging/:id"
        }
      }
    ]
  },
  {
    path: "/cargo",
    component: Layout,
    name: "Cargo",
    meta: {
      header: true,
      icon: "cargo",
      title: "Cargo",
      name: "Cargo",
      permission: flags.permission_single_menu_cargo_enable
    },
    children: [
      {
        path: "/cargo",
        name: "Cargo",
        redirect: "/cargo/booking-success",
        component: Cargo,
        meta: {
          header: true
        }
      }
    ]
  },
  {
    path: "/clearance",
    component: Layout,
    name: "Clearance",
    meta: {
      header: true,
      icon: "outgoing-clearance",
      title: "Clearance",
      name: "Clearance",
      permission: FlagsPermissionOutgoingShipmentClearance.permission_clearance_enable
    },
    children: [
      {
        path: "/clearance",
        name: "Clearance",
        component: Clearance,
        meta: {
          header: true
        }
      }
    ]
  },
  {
    path: "/clearance",
    component: Layout,
    name: "clearance-detail",
    meta: {
      title: "Clearance | Detail",
      hidden: true,
      parent: "Clearance",
      permission: FlagsPermissionOutgoingShipmentClearance.permission_clearance_detail_view
    },
    children: [
      {
        path: ":id",
        name: "detail-Clearance",
        component: ClearanceDetail,
        meta: {
          header: true,
          title: "Clearance | Detail Clearance",
          name: "Detail Clearance",
          before: "/clearance"
        }
      }]
  },
  {
    path: "/release",
    component: Layout,
    name: "Release",
    meta: {
      header: true,
      icon: "outgoing-release",
      title: "Release",
      name: "Release",
      permission: FlagsPermissionOutgoingShipmentRelease.permission_release_enable
    },
    children: [
      {
        path: "/release",
        name: "Release",
        component: Release,
        meta: {
          header: true
        }
      }
    ]
  },
  {
    path: "/clearance",
    component: Layout,
    name: "clearance-detail",
    meta: {
      title: "Clearance | Detail",
      hidden: true,
      parent: "Clearance",
      permission: FlagsPermissionOutgoingShipmentClearance.permission_clearance_detail_view
    },
    children: [
      {
        path: ":id",
        name: "detail-Clearance",
        component: ClearanceDetail,
        meta: {
          header: true,
          title: "Clearance | Detail Clearance",
          name: "Detail Clearance",
          before: "/clearance"
        }
      }
    ]
  },
  {
    path: "/release",
    component: Layout,
    name: "release-detail",
    meta: {
      title: "Release | Detail",
      hidden: true,
      parent: "Release",
      permission: FlagsPermissionOutgoingShipmentRelease.permission_release_detail_view
    },
    children: [
      {
        path: ":id",
        name: "detail-release",
        component: ReleaseDetail,
        meta: {
          header: true,
          title: "Release | Detail Release",
          name: "Detail Release",
          before: "/release"
        }
      }
    ]
  },
  ...CargoModules,
  // sti dest-sc
  {
    path: "/sti-dest-sc",
    component: Layout,
    name: "sti-dest-sc",
    meta: {
      header: true,
      icon: "sti-dest-sc",
      title: "Station Transit In Destination Sub Consolidator",
      name: "STI DEST-SC",
      isFreeze: true,
      permission: INCOMING_PROCESS_STI_DEST_SC.ENABLE
    },
    children: [
      {
        path: "/sti-dest-sc",
        name: "sti-dest-sc",
        component: StiDestSc,
        meta: {
          header: true
        }
      }
    ]
  },
  {
    path: "/sti-dest-sc",
    component: Layout,
    name: "update-sti-dest-sc",
    meta: {
      hidden: true,
      parent: "STI DEST-SC",
      permission: INCOMING_PROCESS_STI_DEST_SC.ENABLE
    },
    children: [
      {
        path: "update",
        name: "update-sti-dest-sc",
        component: UpdateStiDestSc,
        meta: {
          header: true,
          isFreeze: true,
          title: "STI DEST-SC | Update STI DEST-SC",
          name: "Update STI DEST-SC",
          before: "/sti-dest-sc"
        }
      }
    ]
  },
  // sti-dest
  {
    path: "/sti-dest",
    component: Layout,
    name: "sti-dest",
    meta: {
      header: true,
      icon: "sti-dest",
      title: "Station Transit In Destination",
      name: "STI DEST",
      isFreeze: true,
      permission: INCOMING_PROCESS_STI_DEST.ENABLE
    },
    children: [
      {
        path: "/sti-dest",
        name: "sti-dest",
        component: StiDest,
        meta: {
          header: true
        }
      }
    ]
  },
  {
    path: "/sti-dest",
    component: Layout,
    name: "update-sti-dest",
    meta: {
      hidden: true,
      parent: "Station Transit In Destination",
      permission: INCOMING_PROCESS_STI_DEST.CREATE
    },
    children: [
      {
        path: "update",
        name: "update-sti-dest",
        component: UpdateStiDest,
        meta: {
          header: true,
          isFreeze: true,
          title: "STI-DEST | Update STI-DEST",
          name: "Update STI-DEST",
          before: "/sti-dest"
        }
      }
    ]
  },
  {
    path: "/sti-dest",
    component: Layout,
    name: "detail-sti-dest",
    meta: {
      hidden: true,
      parent: "Station Transit In Destination",
      permission: INCOMING_PROCESS_STI_DEST.DETAIL
    },
    children: [
      {
        path: "detail/:id",
        name: "detail-sti-dest",
        component: DetailStiDest,
        meta: {
          header: true,
          title: "STI-DEST | Detail STI-DEST",
          name: "Detail STI-DEST"
        }
      }
    ]
  },
  // Urgent Delivery Management
  ...UrgentDeliveryManagement,
  // shortland
  {
    path: "/shortland",
    component: Layout,
    name: "shortland",
    meta: {
      header: true,
      icon: "shortland",
      title: "Shortland Cargo",
      name: "Shortland Cargo",
      isFreeze: true,
      permission: FlagsPermissionShortland.shortland_enable
    },
    children: [
      {
        path: "/shortland",
        name: "shortland",
        component: Shortland,
        meta: {
          header: true
        }
      }
    ]
  },
  // handover
  {
    path: "/handover",
    component: Layout,
    name: "handover",
    meta: {
      header: true,
      icon: "handover",
      title: "Handover",
      name: "Handover",
      isFreeze: true,
      permission: FlagsPermissionHandover.handover_enable
    },
    children: [
      {
        path: "/handover",
        name: "handover",
        component: HandoverList,
        meta: {
          header: true
        }
      }
    ]
  },
  {
    path: "/handover",
    component: Layout,
    name: "update-handover",
    meta: {
      hidden: true,
      parent: "Handover",
      permission: FlagsPermissionHandover.handover_create
    },
    children: [
      {
        path: "update",
        name: "update-handover",
        component: UpdateHandover,
        meta: {
          header: true,
          isFreeze: true,
          title: "HANDOVER | Update Handover",
          name: "Update Handover",
          before: "/handover"
        }
      }
    ]
  },
  {
    path: "/handover",
    component: Layout,
    name: "handover-detail",
    meta: {
      hidden: true,
      parent: "Handover",
      permission: FlagsPermissionHandover.handover_detail_view
    },
    children: [
      {
        path: ":id",
        name: "detail-handover",
        component: DetailHandover,
        meta: {
          header: true,
          title: "Handover | Detail",
          name: "Detail Handover",
          before: "/handover"
        }
      }
    ]
  },
  // delivery manifest
  {
    path: "/delivery-manifest",
    component: Layout,
    name: "delivery-manifest",
    meta: {
      header: true,
      icon: "delivery-manifest",
      title: "Delivery Manifest",
      name: "Delivery Manifest",
      permission: INCOMING_PROCESS_DELIVERY_MANIFEST.ENABLE
    },
    children: [
      {
        path: "/delivery-manifest",
        name: "delivery-manifest",
        component: DeliveryManifest,
        meta: {
          header: true
        }
      }
    ]
  },
  {
    path: "/delivery-manifest",
    component: Layout,
    name: "update-delivery-manifest",
    meta: {
      hidden: true,
      parent: "Delivery Manifest",
      permission: INCOMING_PROCESS_DELIVERY_MANIFEST.CREATE,
      isFreeze: true
    },
    children: [
      {
        path: "update",
        name: "update-delivery-manifest",
        component: UpdateDeliveryManifest,
        meta: {
          header: true,
          title: "DEL | Buat Manifest",
          name: "Buat Manifest",
          before: "/delivery-manifest"
        }
      }
    ]
  },
  // update del
  {
    path: "/update-delivery",
    component: Layout,
    name: "update-delivery",
    meta: {
      header: true,
      icon: "delivery",
      title: "Delivery",
      name: "Delivery",
      isFreeze: true,
      permission: FlagsPermissionDelivery.update_del_enable
    },
    children: [
      {
        path: "/update-delivery",
        name: "update-delivery",
        component: UpdateDeliveryList,
        meta: {
          header: true
        }
      }
    ]
  },
  {
    path: "/update-delivery",
    component: Layout,
    name: "update-delivery-update",
    meta: {
      hidden: true,
      parent: "Delivery",
      permission: FlagsPermissionDelivery.update_del_create,
      isFreeze: true
    },
    children: [
      {
        path: "update",
        name: "update-delivery-update",
        component: UpdateDelivery,
        meta: {
          header: true,
          title: "DEL | Update DEL",
          name: "Update DEL",
          before: "/update-delivery"
        }
      }
    ]
  },
  // pod/dex
  {
    path: "/pod-dex",
    component: Layout,
    name: "pod-dex",
    meta: {
      header: true,
      icon: "pod-dex",
      title: "POD/DEX",
      name: "POD/DEX",
      isFreeze: true,
      permission: FlagsPermissionPodDex.pod_dex_enable
    },
    children: [
      {
        path: "/pod-dex",
        redirect: "/pod-dex/del-dex",
        name: "pod-dex",
        component: Delivery,
        meta: {
          header: true
        },
        children: [
          {
            path: "del-dex",
            name: "del/dex",
            component: DelDex,
            meta: {
              header: true
            }
          },
          {
            path: "codrej",
            name: "codrej",
            component: Codrej,
            meta: {
              header: true
            }
          },
          {
            path: "pod",
            name: "pod",
            component: Pod,
            meta: {
              header: true
            }
          }
        ]
      }
    ]
  },
  // custom status
  {
    path: "/custom-status",
    component: Layout,
    name: "custom-status",
    meta: {
      header: true,
      icon: "custom-process",
      title: "Custom Status",
      name: "Custom Status",
      permission: CUSTOM_PROCESS.VIEW
    },
    children: [
      {
        path: "/custom-status",
        name: "custom-status",
        component: CustomStatus,
        meta: {
          header: true
        }
      }
    ]
  },
  ...CustomStatusModules
];
