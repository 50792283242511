import { Pagination } from "@/domain/entities/Pagination";
import { FlagsMedusa } from "@/feature-flags/flags-misc-medusa";

export class CustomerListEntities {
  pagination: Pagination;
  data: Customer[];

  constructor(pagination: Pagination, data: Customer[]) {
    this.pagination = pagination;
    this.data = data;
  }
}

export class Customer {
  customerId: number;
  customerName: string;
  customerPhoneNumber: string;
  customerAddress: string;
  customerDistrictId: number;
  customerDistrictName: string;
  customerZipCode: string;
  customerAddressType: string;

  constructor(
    customerId: number,
    customerName: string,
    customerPhoneNumber: string,
    customerAddress: string,
    customerDistrictId: number,
    customerDistrictName: string,
    customerZipCode: string,
    customerAddressType: string
  ) {
    this.customerId = customerId;
    this.customerName = customerName;
    this.customerPhoneNumber = customerPhoneNumber;
    this.customerAddress = customerAddress;
    this.customerDistrictId = customerDistrictId;
    this.customerDistrictName = customerDistrictName;
    this.customerZipCode = customerZipCode;
    this.customerAddressType = customerAddressType;
  }
}

export class ManualBooking {
  sttId = 0;
  sttNo = "";
  sttManual = "";
  sttShipmentId = "";
  referenceExternal = "";
  productName = "";
  originCity = "";
  originDistrict = "";
  destinationCity = "" as any;
  destinationDistrict = "";
  phoneSender = "";
  phoneCodeSender = "+62";
  phoneFlagSender = "indonesia";
  phoneReceiver = "";
  phoneCodeReceiver = "+62";
  phoneFlagReceiver = "indonesia";
  sender = "";
  receiver = "";
  addressSender = "";
  addressReceiver = "";
  sttGrossWeight = 0;
  sttVolumeWeight = 0;
  sttChargeableWeight = 0;
  addressReceiverType = "home";
  commodityName = "";
  commodityId = 0;
  commodityCode = "";
  insurance = "free";
  statusGoods = "";
  estimationPrice = "";
  taxNumber = "";
  isWoodpack = false;
  isCOD = false;
  isPAD = false;
  isDFOD = false;
  isSaveSender = false;
  isSaveReceiver = false;
  sttPieces: Array<any> = [];
  clientName: any = "";
  piecePerPack: any = "";
  nextCommodity = "";
  codAmount = 0;
  postalCodeDestination: any = "";
  photoAdjustments: string[] = []
  sttRecipientEmail = "";
  constructor(fields?: Partial<ManualBooking>) {
    Object.assign(this, fields);
  }
}

export class EstimationPrice {
  cityRates = 0;
  forwardRates = 0;
  chargeableWeight = 0;
  shippingCost = 0;
  commoditySurcharge = 0;
  heavyWeightSurcharge = 0;
  documentSurcharge = 0;
  insuranceRates = 0;
  insuranceName = "";
  insuranceLabel = "";
  woodpackingRates = 0;
  totalTariff = 0;
  taxRates = 0;
  bmTaxRate = 0;
  ppnTaxRate = 0;
  pphTaxRate = 0;
  originDistrictRate = 0;
  destinationDistrictRate = 0;
  publishRate = 0;
  shippingSurchargeRate = 0;
  discount = 0;
  isPromo = false;
  totalDiscount = 0;
  totalTariffAfterDiscount = 0;
  productType = "";
  codAmount = 0;
  codFee = 0;
  padFee = 0;
  dataElexys = "";
  listDiscount: any = [];
  additionalCost = 0;
  insuranceAdminFee = 0;

  constructor(fields?: Partial<EstimationPrice>) {
    Object.assign(this, fields);
  }
}

export class PrintDataEntitas {
  [x: string]: any;

  sttId = 0;
  sttNo = "";
  sttProductType = "";
  sttCreatedAt = "";
  sttOriginCityId = "";
  sttOriginCityName = "";
  sttOriginCityTransit = "";
  sttDestinationCityId = "";
  sttDestinationCityTransit = "";
  sttOriginDistrictId = "";
  sttDestinationDistrictId = "";
  sttDestinationDistrictName = "";
  sttDistrictUrsaCode = "";
  sttDistrictType = "";
  sttDestinationDistrictType = "";
  sttInformationDistrictType = "";
  sttShipmentSource = "";
  sttSenderName = "";
  sttSenderPhone = "";
  sttSenderAddress = "";
  sttRecipientName = "";
  sttRecipientAddress = "";
  sttRecipientAddressType = "";
  sttRecipientPhone = "";
  sttBookedName = "";
  sttNoRefExternal = "";
  sttCommodityCode = "";
  sttCommodityName = "";
  sttIsCod = false;
  sttIsDfod = false;
  sttIsDo = false;
  sttCodAmount = 0;
  sttTotalAmount = 0;
  sttBmTaxRate = "";
  sttPpnTaxRate = "";
  sttPphTaxRate = "";
  sttShippingSurchargeRate = 0;
  sttPublishRate = 0;
  sttOriginDistrictRate = 0;
  sttDestinationDistrictRate = 0;
  sttDocumentSurchargeRate = 0;
  sttCommoditySurchargeRate = 0;
  sttHeavyweightSurchargeRate = 0;
  sttInsuranceRate = 0;
  sttWoodpackingRate = 0;
  sttGrossWeight = 0;
  sttVolumeWeight = 0;
  sttChargeableWeight = 0;
  sttTotalPiece = 0;
  estimateSla = "";
  sttTotalSurcharge = 0;
  sttAmount = 0;
  sttPieces: Array<any> = [];
  sttVendorCode = "";
  sttVendorName = "";
  sttShipmentId = "";
  sttClientId: any = "";
  sttClientName = "";
  sttVendorRefferenceNo = "";
  sttCodFee = 0;
  otherShipperTicketCode: Array<any> = [];
  sttBookedForName = "";
  sttBookedByCountry = "";
  totalTariffCodRetail = 0;
  sttForwardRate? = 0;
  totalTariff? = 0;
  totalRate? = 0;
  sttElexysNo?: string = "";
  isDangerousGoods = false;
  sttIsRts = false;
  sttIsRerouteMisroute = false;
  sttIsRerouteMisbooking = false;
  sttGoodsEstimatePrice = 0;
  sttCommodityDescription = "";
  sttPostalCode = "";
  isReverseJourneyAfterCancel = false;
  sttGoodsPrice = 0;
  sttReturnFee = 0;
  sttReverseJourneySttNo = "";
  isReverseJourney = false;
  sttReverseJourneyShipmentId = "";
  sttReverseJourneyCodHandling = false;
  sttIsPromo = false;
  sttDocumentSurchargeRateAfterDiscount = 0;
  sttCommoditySurchargeRateAfterDiscount = 0;
  sttHeavyweightSurchargeRateAfterDiscount = 0;
  sttWoodpackingAfterRateDiscount = 0;
  sttIsWoodpacking = false;
  isHubToHub = false;
  sttFlag: Array<string> = [];
  campaignSttQuote = "";
  sttCodFeeAfterDiscount = 0;
  sttInsuranceRateAfterDiscount = 0;
  sttShippingSurchargeRateAfterDiscount = 0;
  sttPublishRateAfterDiscount = 0;
  sttOriginDistrictRateAfterDiscount = 0;
  sttDestinationDistrictRateAfterDiscount = 0;

  constructor(fields?: Partial<PrintDataEntitas>) {
    Object.assign(this, fields);
  }
}

export class ShipmentIdList {
  pagination: Pagination = {
    page: 1,
    limit: 10,
  };
  data: ShipmentId[] = [];

  constructor(fields?: Partial<ShipmentIdList>) {
    Object.assign(this, fields);
  }
}

export class ShipmentIdSTT {
  sttId = 0;
  sttNo = "";
  sttClientId = 0;
  sttPosId = 0;
  sttTaxNumber = "";
  sttGoodsEstimatePrice = 0;
  sttGoodsStatus = "";
  sttTotalAmount = 0;
  sttNoRefExternal = "";
  sttSource = "";
  sttOriginCityId = "";
  sttDestinationCityId = "";
  sttOriginDistrictId = "";
  sttDestinationDistrictId = "";
  sttSenderName = "";
  sttSenderPhone = "";
  sttSenderAddress = "";
  sttRecipientName = "";
  sttRecipientAddress = "";
  sttRecipientPhone = "";
  sttProductType = "";
  sttOriginDistrictRate = 0;
  sttDestinationDistrictRate = 0;
  sttPublishRate = 0;
  sttShippingSurchargeRate = 0;
  sttDocumentSurchargeRate = 0;
  sttCommoditySurchargeRate = 0;
  sttHeavyweightSurchargeRate = 0;
  sttBmTaxRate = 0;
  sttPpnTaxRate = 0;
  sttPphTaxRate = 0;
  sttGrossWeight = 0;
  sttVolumeWeight = 0;
  sttChargeableWeight = 0;
  sttCommodityCode = "";
  sttInsuranceType = "";
  sttTotalPiece = 0;
  sttWarningStatus = "";
  sttCounter = 0;
  sttLastStatusId = "";
  sttClientSttId = "";
  sttVendorSttId = "";
  sttBilledTo = "";
  sttCodAmount = 0;
  sttIsCod = false;
  sttIsDo = false;
  sttMeta = "";
  sttBookedAt = "";
  sttBookedName = "";
  sttBookedBy = 0;
  sttCreatedAt = "";
  sttCreatedName = "";
  sttCreatedBy = 0;
  sttUpdatedAt = "";
  sttUpdatedBy = 0;
  sttUpdatedName = "";
  shipmentAlgoId = "";
  sttPieces: any[] = [];

  constructor(fields?: Partial<ShipmentIdSTT>) {
    Object.assign(this, fields);
  }
}

export class ShipmentId {
  shipmentId = "";
  shipmentClientIdBranch = 0;
  shipmentBookingId = "";
  shipmentAlgoId = "";
  shipmentProduct = "";
  shipmentProductTemp = "";
  shipmentOriginCity = "";
  shipmentOriginDistrict = "";
  shipmentDestinationCity: any;
  shipmentDestinationCityKR: any;
  shipmentDestinationDistrict: any;
  shipmentSenderName = "";
  shipmentSenderAddress = "";
  shipmentSenderPhone = "";
  shipmentSenderPhoneOriginal = "";
  shipmentSenderPhoneCode = "";
  shipmentSenderPhoneFlag = "";
  shipmentRecipientName = "";
  shipmentRecipientNameKeeped = "";
  shipmentRecipientAddress = "";
  shipmentRecipientAddressKeeped = "";
  shipmentRecipientPhone = "";
  shipmentRecipientPhoneKeeped = "";
  shipmentRecipientPhoneCode = "";
  shipmentRecipientPhoneFlag = "";
  shipmentCommodityId = 0;
  shipmentCommodityCode: object | string = "";
  shipmentCommodityName: object | string = "";
  shipmentCommodityHsCode = "";
  shipmentCommodityDocumentSurcharge = "";
  shipmentGoodsStatus = "";
  shipmentInsurance = "";
  shipmentGoodsPriceEstimation = 0;
  shipmentNpwpNumber = "";
  shipmentGoodDetail: any[] = [];
  shipmentGoodDetailKeeped: any[] = [];
  shipmentIsCod = false;
  shipmentIsDfod = false;
  shipmentCodHandling = "";
  shipmentAbleToCod = false;
  shipmentIsWoodpacking = false;
  shipmentAbleToWoodpacking = false;
  shipmentPacketId = 0;
  shipmentChargeableWeight = 0;
  customerName = "";
  isAdjustable = false;
  maxAdjustable = false;
  STT: ShipmentIdSTT | null = new ShipmentIdSTT();
  isDO = false;
  piecePerPack: any;
  nextCommodity = "";
  codAmount = 0;
  codFee = 0;
  dataRaw?: any;
  postalCodeDestination: any = "";
  shipmentSttClientId = 0;
  unixInstantBookId = 0;
  senderPhoneInstantBooking = "";
  recipientPhoneInstantBooking = "";
  sttIdInstantBooking = 0;
  sttNoInstantBooking = "";
  sttAmountInstantbooking = 0;
  statusShipment = "";
  reasonFailed = "";
  autoPrintStatus = "Sedang Diproses";
  isKeepCod = false;
  isKeepDfod = false;
  codDfodValue = "";
  discountFavoritePercentage = 0;
  sttRecipientAddressType = "";

  constructor(fields?: Partial<ShipmentId>) {
    Object.assign(this, fields);
  }
}

export class CustomerExist {
  customerId: number;

  constructor(customerId: number) {
    this.customerId = customerId;
  }
}

export class ProductDelivery {
  pagination: Pagination;
  data: Array<ProductDeliveryData>;

  constructor(pagination: Pagination, data: Array<ProductDeliveryData>) {
    this.pagination = pagination;
    this.data = data;
  }
}

export class ProductDeliveryData {
  id = 0;
  name = "";
  status = "";

  constructor(fields?: Partial<ProductDeliveryData>) {
    Object.assign(this, fields);
  }
}

export class InsuranceTypeDelivery {
  data: Array<InsuranceTypeDeliveryData>;

  constructor(data: Array<InsuranceTypeDeliveryData>) {
    this.data = data;
  }
}

export class InsuranceTypeDeliveryData {
  id = 0;
  name = "";
  value = "";
  status = "";
  cost = 0;
  commissionPos = 0;
  maxProtection = 0;

  constructor(fields?: Partial<InsuranceTypeDeliveryData>) {
    Object.assign(this, fields);
  }
}

export class WeightDimensions {
  data: WeightDimension[];

  constructor(data: WeightDimension[]) {
    this.data = data;
  }
}

export class WeightDimension {
  grossWeight = 1;
  dimension = {};
  volumeWeight = 0.16;
  convertVolumeWeight = "0,16";
  errorMaxPerPieces = false;
  isDimensionDisabled = false;
  errorHws = false;

  constructor(fields?: Partial<WeightDimension>) {
    Object.assign(this, fields);
  }
}

export class EstimationServiceLevelAgreement {
  minDate = "";
  maxDate = "";
  estimateSlaMin = 1;
  estimateSlaMax = 1;

  constructor(fields?: Partial<EstimationServiceLevelAgreement>) {
    Object.assign(this, fields);
  }
}

export class EstimationCodRetail {
  returnFee = 0;

  constructor(fields?: Partial<EstimationCodRetail>) {
    Object.assign(this, fields);
  }
}

export class FooterColorPosterProduct {
  firstColor = "";
  secondColor = "";

  constructor(fields?: Partial<FooterColorPosterProduct>) {
    Object.assign(this, fields);
  }
}

export class PosterProduct {
  name = "";
  icon = "";
  motto = "";
  price: string | number = "";
  sla = "";
  color = "";
  footer = new FooterColorPosterProduct();
  discountAgentPrecentage: string | number = "";
  discountAgentNominal: any = "";
  statusCard = false;
  statusSelect = false;
  statusActive = false;
  isEmbargo = false;

  constructor(fields?: Partial<PosterProduct>) {
    Object.assign(this, fields);
  }
}

export class PiecesPosterProduct {
  pieceLength = 10;
  pieceWidth = 10;
  pieceHeight = 10;
  pieceGrossWeight = 1;

  constructor(fields?: Partial<PiecesPosterProduct>) {
    Object.assign(this, fields);
  }
}

export class ComodityProductPoster {
  commodityId = 0;
  commodityCode = "";
  commodityName = "";
  description = "";
  commodityCategoryVendor = "";
  commodityGroup = "";
  commodityGroupId = 0;
  commodityGroupCode = "";
  commodityGroupName = "";
  commoditySurchargeApplicable = "";
  commodityDocumentSurcharge = false;

  constructor(fields?: Partial<ComodityProductPoster>) {
    Object.assign(this, fields);
  }
}

export class PayloadPosterProduct {
  originId = "";
  destinationId = "";
  accountType = "pos";
  commodityId = 499;
  productType = "";
  accountRefId = 0;
  goodsPrice = 0;
  codAmount = 0;
  isCod = false;
  isDfod = false;
  isPad = false;
  insuranceType = "free";
  isWoodpacking = false;
  isHaveTaxId = false;
  pieces = [new PiecesPosterProduct()];
  promoAppliedTo = "";
  isDisablePromo = true;
  isBookingPuposes = true;
  commodity = { data: new ComodityProductPoster() };
  shipmentPrefix = "";

  constructor(fields?: Partial<PayloadPosterProduct>) {
    Object.assign(this, fields);
  }
}

export class ParameterActive {
  index = 0;
  product = "";

  constructor(fields?: Partial<ParameterActive>) {
    Object.assign(this, fields);
  }
}

export const checkEnableCommodity = (shipmentId: string) => {
  const prefix =
    shipmentId.startsWith("T1") ||
    shipmentId.startsWith("B1") ||
    shipmentId.startsWith("B2") ||
    shipmentId.startsWith("TKLP");
  if (prefix) {
    return {
      id: 0,
      code: "",
      name: "",
    };
  } else {
    return {
      id: FlagsMedusa.config_commodity_general_others_id.getValue(),
      code: FlagsMedusa.config_commodity_general_others_code.getValue(),
      name: FlagsMedusa.config_commodity_general_others_name.getValue(),
    };
  }
};
