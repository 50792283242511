import { Pagination } from "@/domain/entities/Pagination";
import { ShipmentIdSTT } from "./Booking";
import { QueryParamsEntities } from "./MainApp";
import { getHubData } from "@/app/infrastructures/misc/Cookies";

export class StiDestEntities {
  pagination: Pagination;
  data: StiDestData[];

  constructor(pagination: Pagination, data: StiDestData[]) {
    this.pagination = pagination;
    this.data = data;
  }
}

export class StiDestData {
  stiDestId: number;
  stiDestCargoNo: string;
  stiDestCargoType: string;
  stiDestArrivalCityCode: string;
  stiDestArrivalCityName: string;
  stiDestTotalStt: number;
  stiDestTotalPiece: number;
  stiDestTotalGrossWeight: number;
  stiDestTotalVolumeWeight: number;
  stiDestCreatedAt: string;
  stiDestCreatedBy: string;
  stiDestStatusReturnPod?: number;
  stiDestStatusReturnRts?: number;
  stiDestStatusReturnHal?: number;
  stiDestMisroute?: number;
  stiDestTransit?: number;

  constructor(
    stiDestId: number,
    stiDestCargoNo: string,
    stiDestCargoType: string,
    stiDestArrivalCityCode: string,
    stiDestArrivalCityName: string,
    stiDestTotalStt: number,
    stiDestTotalPiece: number,
    stiDestTotalGrossWeight: number,
    stiDestTotalVolumeWeight: number,
    stiDestCreatedAt: string,
    stiDestCreatedBy: string,
    stiDestStatusReturnPod?: number,
    stiDestStatusReturnRts?: number,
    stiDestStatusReturnHal?: number,
    stiDestMisroute?: number,
    stiDestTransit?: number
  ) {
    this.stiDestId = stiDestId;
    this.stiDestCargoNo = stiDestCargoNo;
    this.stiDestCargoType = stiDestCargoType;
    this.stiDestArrivalCityCode = stiDestArrivalCityCode;
    this.stiDestArrivalCityName = stiDestArrivalCityName;
    this.stiDestTotalStt = stiDestTotalStt;
    this.stiDestTotalPiece = stiDestTotalPiece;
    this.stiDestTotalGrossWeight = stiDestTotalGrossWeight;
    this.stiDestTotalVolumeWeight = stiDestTotalVolumeWeight;
    this.stiDestCreatedAt = stiDestCreatedAt;
    this.stiDestCreatedBy = stiDestCreatedBy;
    this.stiDestStatusReturnPod = stiDestStatusReturnPod;
    this.stiDestStatusReturnRts = stiDestStatusReturnRts;
    this.stiDestStatusReturnHal = stiDestStatusReturnHal;
    this.stiDestMisroute = stiDestMisroute;
    this.stiDestTransit = stiDestTransit;
  }
}

export class STTToBeGenerate {
  stt: StiDestSttDetail;

  constructor(stt: StiDestSttDetail) {
    this.stt = stt;
  }
}

export class PieceDetail {
  sttNumber: string;
  sttPieceNumber: string;
  pieceNumber: number;
  product: string;
  commodity: string;
  grossWeight: number;
  volumeWeight: number;
  lastStatus: string;
  woodPacking: string;
  ppl: string;
  cod: number;
  transit: string;
  misRoute: string;
  bagNumber?: string;
  constructor(
    sttNumber: string,
    sttPieceNumber: string,
    pieceNumber: number,
    product: string,
    commodity: string,
    grossWeight: number,
    volumeWeight: number,
    lastStatus: string,
    woodPacking: string,
    ppl: string,
    cod: number,
    transit: string,
    misRoute: string,
    bagNumber?: string
  ) {
    this.sttNumber = sttNumber;
    this.sttPieceNumber = sttPieceNumber;
    this.pieceNumber = pieceNumber;
    this.product = product;
    this.commodity = commodity;
    this.grossWeight = grossWeight;
    this.volumeWeight = volumeWeight;
    this.lastStatus = lastStatus;
    this.woodPacking = woodPacking;
    this.ppl = ppl;
    this.cod = cod;
    this.transit = transit;
    this.misRoute = misRoute;
    this.bagNumber = bagNumber;
  }
}

export class StiDestManifestData {
  stiDestPartnerId: number;
  stiDestCPartnerName: string;
  stiDestCityName: string;
  stiDestCityCode: string;
  stiDestCargoNo: string;
  stiDestCustomVehicleNo: string;
  stiDestCargoType: string;
  stiDestTotalStt: number;
  stiDestTotalPieces: number;
  stiDesttotalGrossWeight: number;
  stiDesttotalVolumeWeight: number;
  stiDestCreatedAt: string;
  stt: StiDestSttResult[];
  constructor(
    stiDestPartnerId: number,
    stiDestCPartnerName: string,
    stiDestCityName: string,
    stiDestCityCode: string,
    stiDestCargoNo: string,
    stiDestCustomVehicleNo: string,
    stiDestCargoType: string,
    stiDestTotalStt: number,
    stiDestTotalPieces: number,
    stiDesttotalGrossWeight: number,
    stiDesttotalVolumeWeight: number,
    stiDestCreatedAt: string,
    stt: StiDestSttResult[]
  ) {
    this.stiDestPartnerId = stiDestPartnerId;
    this.stiDestCPartnerName = stiDestCPartnerName;
    this.stiDestCityName = stiDestCityName;
    this.stiDestCityCode = stiDestCityCode;
    this.stiDestCargoNo = stiDestCargoNo;
    this.stiDestCustomVehicleNo = stiDestCustomVehicleNo;
    this.stiDestCargoType = stiDestCargoType;
    this.stiDestTotalStt = stiDestTotalStt;
    this.stiDestTotalPieces = stiDestTotalPieces;
    this.stiDesttotalGrossWeight = stiDesttotalGrossWeight;
    this.stiDesttotalVolumeWeight = stiDesttotalVolumeWeight;
    this.stiDestCreatedAt = stiDestCreatedAt;
    this.stt = stt;
  }
}

export class ResponseUpdate {
  stiDescId: number;
  totalSttSuccess: number;
  totalSttFailed: number;
  sttFailed: Array<string>;
  constructor(
    stiDescId: number,
    totalSttSuccess: number,
    totalSttFailed: number,
    sttFailed: Array<string>
  ) {
    this.stiDescId = stiDescId;
    this.totalSttSuccess = totalSttSuccess;
    this.totalSttFailed = totalSttFailed;
    this.sttFailed = sttFailed;
  }
}

export class StiDestSttResult {
  sttId = 0;
  sttNo = "";
  sttNoRefExternal = "";
  sttElexysNumber = "";
  sttProductType = "";
  sttTotalPiece = "";
  sttDestinationCityId = "";
  sttDestinationCityName = "";
  sttWoodPacking = "";
  sttCommodityCode = "";
  sttCommodityName = "";
  sttGrossWeight = 0;
  sttVolumeWeight = 0;
  sttChargeableWeight = 0;
  sttCodAmount = 0;
  sttBagNo = "";
  sttPiece = [];
  origin = "";
  destination = "";
  sttBagVendorNo = "";
  statusReturn = "";
  isMisroute = 0;
  isTransit = 0
  constructor(fields?: Partial<StiDestSttResult>) {
    Object.assign(this, fields);
  }
}

export class SttPiece {
  sttPieceId = 0;
  sttPieceGrossWeight = 0;
  sttPieceVolumeWeight = 0;
  sttPieceNo = 0;

  constructor(fields?: Partial<SttPiece>) {
    Object.assign(this, fields);
  }
}

export class StiDestSttList {
  data: StiDestSttValidaton[];
  constructor(data: StiDestSttValidaton[]) {
    this.data = data;
  }
}

export class StiDestSttDetail {
  bagNo = "";
  cargoNo = "";
  isTransit = false;
  isMissroute = false;
  sttId = 0;
  sttNo = "";
  sttElexysNumber? = "";
  sttProductType = "";
  sttTotalPiece = 0;
  sttDestinationCityId = "";
  sttDestinationCityName = "";
  sttWoodPacking = "";
  sttCommodityCode = "";
  sttCommodityName = "";
  sttGrossWeight = 0;
  sttVolumeWeight = 0;
  sttChargeableWeight = 0;
  sttCodAmount = 0;
  sttLastStatusId = "";
  posName = "";
  bookedAt = "";
  bagVendorNo = "";
  isOneBagScan = false;
  piece: StiDestPieceDetail[] = [];
  sttReferenceNo = ""
  constructor(fields?: Partial<StiDestSttDetail>) {
    Object.assign(this, fields);
  }
}

export class StiDestSttValidaton {
  isAllowUpdateStatus = false;
  errorMessage = "";
  isPaid = false;
  lastStatus = "";
  stt: StiDestSttDetail = new StiDestSttDetail();
  constructor(fields?: Partial<StiDestSttValidaton>) {
    Object.assign(this, fields);
  }
}

export class StiDestPieceDetail {
  sttPieceId: number;
  sttPieceGrossWeight: number;
  sttPieceVolumeWeight: number;
  sttPieceNo: number;
  sttPieceLastStatusId: string;
  constructor(
    sttPieceId: number,
    sttPieceGrossWeight: number,
    sttPieceVolumeWeight: number,
    sttPieceNo: number,
    sttPieceLastStatusId: string
  ) {
    this.sttPieceId = sttPieceId;
    this.sttPieceGrossWeight = sttPieceGrossWeight;
    this.sttPieceVolumeWeight = sttPieceVolumeWeight;
    this.sttPieceNo = sttPieceNo;
    this.sttPieceLastStatusId = sttPieceLastStatusId;
  }
}

export class StiDestDetail {
  stiDestId = 0;
  stiDestPartnerName = "";
  consolidatorName = "";
  originConsolidator = "";
  destinationConsolidator = "";
  originCityCode = "";
  destinationCityCode = "";
  stiDestDate = "";
  stiDestCargoNo = "";
  stiDestCustomVehicleNo = "";
  stiDestCargoType = "";
  stt: any = [];
  totalGrossWeight = 0;
  totalPiece = 0;
  totalStt = 0;
  totalVolumeWeight = 0;
  sttCreatedName = "";
  stiDestTotalTransit = 0;
  stiDestTotalMisroute = 0;
  stiDestTotalStatusReturnPOD = 0;
  stiDestTotalStatusReturnRTS = 0;
  stiDestTotalStatusReturnHal = 0;

  constructor(fields?: Partial<StiDestDetail>) {
    Object.assign(this, fields);
  }

}

export class ReverseScan {
  bagNo = "";
  bagVendorNo = "";
  cargoNo = "";
  isTransit = false;
  isMissroute = false;
  flag = "";
  statusReturn = "";
  deadlineReturn = "";
  sttId = "";
  sttNo = "";
  sttNoRefExternal = "";
  sttDestinationCityId = "";
  sttDestinationCityName = "";
  sttProductType = "";
  sttCommodityCode = "";
  sttCommodityName = "";
  sttTotalPiece = 0;
  sttLastStatusId = "";
  sttBookedAt = "";
  sttBookedName = "";
  sttPieces: any[] = [];
  sttOriginCityId = "";
  sttOriginCityName = "";
  sttGrossWeight = 0;
  constructor(fields?: Partial<ReverseScan>) {
    Object.assign(this, fields);
  }
}

export class STIDestReverseScan {
  stt = new ReverseScan();
  bag: ReverseScan[] = [];
  constructor(fields?: Partial<STIDestReverseScan>) {
    Object.assign(this, fields);
  }
}

export class STIDestTemporary {
  sdtId = 0;
  sdtAccountId = 0;
  sdtIsActive = 0;
  sdtSttNo = "";
  sdtProduct = "";
  sdtOrigin = "";
  sdtOriginName = "";
  sdtDestination = "";
  sdtDestinationName = "";
  sdtDeadlineReturn = "";
  sdtBagNo = "";
  sdtIsMisroute = 0;
  sdtIsTransit = 0;
  sdtStatusReturn = "";
  sdtFlag = "";
  sdtPieces = 0;
  sdtRefNo = "";
  sdtCreatedAt = "";
  sdtUpdatedAt = "";
  pieces = 0;
  sdtGrossWeight = 0;
  constructor(fields?: Partial<STIDestTemporary>) {
    Object.assign(this, fields);
  }
}

export class ListSTIDestTemporary {
  data = [new STIDestTemporary()];
  constructor(fields?: Partial<ListSTIDestTemporary>) {
    Object.assign(this, fields);
  }
}

export class RequestListSTIDestTemporary {
  hubId = getHubData()?.hubId || "0";
  sttOrBagNo = "";
  isMisroute = false;
  statusReturn = "";
  constructor(fields?: Partial<RequestListSTIDestTemporary>) {
    Object.assign(this, fields);
  }

  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
}
