import {
  DeliveryManifestEntities,
  DeliveryManifestData,
  DetailSttDeliveryManifest,
  DeliveryManifestPdfData,
  DeliveryManifestPdfStt,
  DeliveryManifestPdfPieces,
  UpdateDeliveryResponse
} from "@/domain/entities/DeliveryManifest";
import { Pagination } from "@/domain/entities/Pagination";
import { AxiosResponse } from "axios";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";

export class DeliveryManifestMapper {
  public convertDeliveryManifestDataFromApi(
    res: AxiosResponse<any>
  ): DeliveryManifestEntities {
    const { data } = res;
    const deliveryManifest: DeliveryManifestData[] = [];
    if (data.data.length === 0) {
      return new DeliveryManifestEntities(
        new Pagination(data.meta.page, data.meta.limit),
        []
      );
    } else {
      data.data.map((item: any) => {
        deliveryManifest.push(
          new DeliveryManifestData(
            item.delivery_manifest_id,
            item.delivery_manifest_total_stt,
            item.delivery_manifest_total_stt_piece,
            item.delivery_manifest_no,
            item.delivery_manifest_total_gross_weight,
            item.delivery_manifest_total_volume_weight,
            item.delivery_manifest_created_at,
            item.delivery_manifest_created_name
          )
        );
      });
    }
    return new DeliveryManifestEntities(
      new Pagination(data.meta.page, data.meta.limit),
      deliveryManifest
    );
  }

  public convertResponseUpdateDelivery(
    result: AxiosResponse<any>
  ): ResponsePayload {
    const { data } = result;
    return new ResponsePayload({
      success: data.success,
      message: data.message,
      data: new UpdateDeliveryResponse(
        data.data.delivery_manifest_id,
        data.data.total_stt_success,
        data.data.total_stt_failed,
        data.data.stt_failed
      )
    });
  }

  public convertDetailSttInformationFromApi(
    res: AxiosResponse
  ): DetailSttDeliveryManifest[] {
    const {
      data: { data }
    } = res;
    const listOfDetailStt: Array<DetailSttDeliveryManifest> = [];
    if (data[0].stt !== null) {
      for (const index in data) {
        if (data[index].piece !== null) {
          listOfDetailStt.push(
            new DetailSttDeliveryManifest(
              data[index].is_allow_update_status,
              data[index].error_message,
              data[index].stt.stt_no,
              data[index].stt.bag_no,
              data[index].stt.stt_total_piece,
              `${data[index].stt.stt_destination_city_id} - ${data[index].stt.stt_destination_city_name}`,
              data[index].stt.stt_product_type,
              data[index].stt.stt_wood_packing.toLowerCase() === "Yes"
                ? "Ya"
                : "Tidak",
              data[index].stt.stt_commodity_name,
              data[index].stt.stt_cod_amount,
              data[index].stt.stt_gross_weight,
              data[index].stt.stt_volume_weight,
              data[index].stt.stt_chargeable_weight,
              data[index].stt.stt_delivery_attempt
                ? Number(data[index].stt.stt_delivery_attempt) + 1
                : 1,
              data[index].stt.stt_recipient_name,
              data[index].stt.stt_recipient_address,
              data[index].stt.stt_recipient_phone,
              data[index].is_paid,
              data[index].stt.stt_elexys_no
            )
          );
        }
      }
      return listOfDetailStt;
    } else {
      return [
        new DetailSttDeliveryManifest(
          false,
          data[0].error_message,
          "",
          "",
          0,
          "",
          "",
          "",
          "",
          "",
          0,
          0,
          0,
          0,
          "",
          "",
          "",
          false,
          "",
          ""
        )
      ];
    }
  }

  public convertDeliveryManifestPdfDataFromApi(
    res: AxiosResponse
  ): DeliveryManifestPdfData {
    const {
      data: { data }
    } = res;
    if (data.stt === null) {
      return new DeliveryManifestPdfData();
    } else {
      return new DeliveryManifestPdfData({
        deliveryManifestId: data.delivery_manifest_id,
        deliveryManifestNo: data.delivery_manifest_no,
        deliveryManifestDestinationCityId:
          data.delivery_manifest_destination_city_id,
        deliveryManifestDestinationCityName:
          data.delivery_manifest_destination_city_name,
        deliveryManifestPartnerId: data.delivery_manifest_partner_id,
        deliveryManifestPartnerCode: data.delivery_manifest_partner_code,
        deliveryManifestPartnerName: data.delivery_manifest_partner_name,
        deliveryManifestTotalStt: data.delivery_manifest_total_stt,
        deliveryManifestTotalSttPiece: data.delivery_manifest_total_stt_piece,
        deliveryManifestTotalGrossWeight:
          data.delivery_manifest_total_gross_weight,
        deliveryManifestTotalVolumeWeight:
          data.delivery_manifest_total_volume_weight,
        deliveryManifestCreatedAt: data.delivery_manifest_created_at,
        deliveryManifestCreatedName: data.delivery_manifest_created_name,
        stt: data.stt.map(
          (item: any) =>
            new DeliveryManifestPdfStt(
              item.stt_id,
              item.stt_no,
              item.stt_product_type,
              item.stt_total_piece,
              item.stt_destination_city_id,
              item.stt_destination_city_name,
              item.stt_wood_packing,
              item.stt_commodity_code,
              item.stt_commodity_name,
              item.stt_gross_weight,
              item.stt_volume_weight,
              item.stt_chargeable_weight,
              item.stt_cod_amount,
              item.stt_recipient_name,
              item.stt_recipient_address,
              item.stt_recipient_phone,
              item.piece.map(
                (itemPiece: any) =>
                  new DeliveryManifestPdfPieces(
                    itemPiece.stt_piece_id,
                    itemPiece.stt_piece_gross_weight,
                    itemPiece.stt_piece_volume_weight,
                    itemPiece.stt_piece_no
                  )
              ),
              item.stt_elexys_no
            )
        ),
        deliveryManifestUrsaCode: data.delivery_manifest_ursa_code
      });
    }
  }
}
