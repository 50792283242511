import { container } from "tsyringe";
import {
  VuexModule,
  Module,
  Mutation,
  Action,
  getModule
} from "vuex-module-decorators";
import store from "@/store";
import { AuthPresenter } from "@/app/ui/presenters/AuthPresenter";
import {
  AuthEntities,
  TokenVerificationAccountEntities
} from "@/domain/entities/Auth";
import {
  ForgotPasswordApiRequest,
  ResetPasswordApiRequest,
  LoginApiRequest,
  LogoutApiRequest,
  EditPasswordApiRequest,
  VerificationAccountRequest
} from "@/data/payload/api/AuthRequest";
import router from "../router";
import {
  setCredential,
  getToken,
  setRememberMe,
  removeAuthCredential,
  setHubData
} from "@/app/infrastructures/misc/Cookies";
import { ResponsePayload, ResponsePayloadV2 } from "@/domain/entities/ResponsePayload";
import { MainAppController } from "./MainAppController";
import {
  parsingErrorResponse,
  clearDataLocalStorage,
  removeDataLocalStorage
} from "@/app/infrastructures/misc/Utils";
import { AccountController } from "./AccountController";
import { dataLayer } from "@/app/infrastructures/misc/UtilsGtm";
import { IS_SENDER_ACCOUNT } from "@/app/infrastructures/misc/Utils";
import { LocationController } from "@/app/ui/controllers/LocationController";
import { DistrictData } from "@/domain/entities/Location";
import jwtDecode from "jwt-decode";
import { HubController } from "./HubController";
import { Hub, RequestHubList } from "@/domain/entities/Hub";
import { NotificationController } from "./NotificationController";
import { FirebaseController } from "./FirebaseController";
import { CargoController } from "./CargoController";
import { PairTokenRequest, UnpairTokenRequest } from "@/data/payload/api/AccountRequest";
import { FlagsPermissionMiddleware } from "@/feature-flags/flags-middleware";
import { MiddlewareController } from "@/app/ui/controllers/MiddlewareController";
import { Credential } from "@/domain/entities/Auth";
import { flags } from "@/feature-flags";

export interface AuthState {
  authData: AuthEntities;
  isLoading: boolean;
  token: any;
  isError: boolean;
  isErrorInternetOrSystem: boolean;
  errorCause: string;
  responsePayload: ResponsePayload | null;
  openModalEditPassword: boolean;
  isErrorForget: boolean;
  openModalSuccess: boolean;
  openModalFailed: boolean;
  closeModal: boolean;
  isErrorWrongEmail: boolean;
  isErrorWrongPassword: boolean;
}

@Module({ namespaced: true, dynamic: true, store, name: "auth" })
class AuthStore extends VuexModule implements AuthState {
  public authData = new AuthEntities();
  public isLoading = false;
  public token = getToken() || "";
  public isError = false;
  public isErrorInternetOrSystem = false;
  public errorCause = "";
  public isErrorForget = false;
  public openModalSuccess = false;
  public openModalFailed = false;
  public closeModal = false;
  public isErrorWrongEmail = false;
  public emailErrorMessage = "";
  public passwordErrorMessage = "";
  public isErrorWrongPassword = false;
  public isInactiveAccount = false;
  public isErrorReset = false;
  public isErrorLogout = false;
  public isverified = false;
  public isChecked = false;
  public responsePayload = new ResponsePayload();
  public openModalEditPassword = false;
  public changePasswordErrorMessage = "";
  public tokenVerificationAccount = new TokenVerificationAccountEntities({
    token: ""
  });
  public verificationAcountErrorMessage = "";
  public showHubList = false;
  public accountId = 0;

  @Mutation
  public setLoading(bool: boolean) {
    this.isLoading = bool;
  }

  @Mutation
  public setAccountId(id: number) {
    this.accountId = id;
  }

  @Mutation
  public setShowHubList(bool: boolean) {
    this.showHubList = bool;
  }

  @Mutation
  public setModalEditPassword(bool: boolean) {
    this.openModalEditPassword = bool;
  }

  @Mutation
  public setModalSuccess(bool: boolean) {
    this.openModalSuccess = bool;
  }

  @Mutation
  public setModalFailed(bool: boolean) {
    this.openModalFailed = bool;
  }

  @Mutation
  public setCloseModal(bool: boolean) {
    this.openModalFailed = bool;
    this.openModalSuccess = bool;
    router.push("/");
  }

  @Mutation
  public setError(bool: boolean) {
    this.isError = bool;
  }

  @Mutation
  public setErrorInternetOrSystem(bool: boolean) {
    this.isErrorInternetOrSystem = bool;
  }

  @Mutation
  public setErrorCause(value: string) {
    this.errorCause = value;
  }

  @Mutation
  public setErrorForget(bool: boolean) {
    this.isErrorForget = bool;
  }

  @Mutation
  public setErrorReset(bool: boolean) {
    this.isErrorReset = bool;
  }

  @Mutation
  public setErrorWrongEmail(bool: boolean) {
    this.isErrorWrongEmail = bool;
  }

  @Mutation
  public setEmailErrorMessage(value: string) {
    this.emailErrorMessage = value;
  }

  @Mutation
  public setErrorPassword(bool: boolean) {
    this.isErrorWrongPassword = bool;
  }

  @Mutation
  public setPasswordErrorMessage(value: string) {
    this.passwordErrorMessage = value;
  }

  @Mutation
  public setInactiveAccount(bool: boolean) {
    this.isInactiveAccount = bool;
  }

  @Mutation
  public setChecked(bool: boolean) {
    this.isChecked = bool;
  }

  @Mutation
  public setAuthData(auth: AuthEntities) {
    this.authData = new AuthEntities(); // save temporary in Vuex State
    setCredential({ token: auth.token, expired: auth.expiredAt }); // save permanently in Cookies
  }

  @Mutation
  public setToken(token: string) {
    this.token = token;
  }

  @Mutation
  public SET_RESPONSE_PAYLOAD(res: ResponsePayload) {
    this.responsePayload = res;
  }

  @Mutation
  public setVerified(bool: boolean) {
    this.isverified = bool;
  }

  @Mutation
  public setVerificationAcountErrorMessage(str: string) {
    this.verificationAcountErrorMessage = str;
  }

  @Action
  public sendLogin(params: { email: string; password: string }) {
    this.setLoading(true);
    if (
      params.email.includes("horde") ||
      params.email.includes("hydra") ||
      params.email.includes("gober")
    ) {
      this.setErrorPassword(true);
      this.setLoading(false);
      return;
    }
    const presenter = container.resolve(AuthPresenter);
    presenter
      .onLogin(new LoginApiRequest(params.email, params.password))
      .then(async (res: AuthEntities) => {
        this.setAuthData(res);
        this.setToken(res.token || "");
        if (this.isChecked) {
          setRememberMe(params.email, params.password);
        }
        this.setErrorPassword(false);
        this.setErrorWrongEmail(false);
        this.setError(false);
        await AccountController._getData();
        this.setAccountId(AccountController.accountData.account_id);
        FirebaseController.getTokenFirebase()
          .then(currentToken => {
            try {
              AccountController.pairToken(
                new PairTokenRequest(currentToken, this.accountId)
              );
            } catch (e) {
              console.warn(e);
            }
          })
          .catch(err => {
            console.log(err);
          });
        const token: any = jwtDecode(res.token);
        this.checkConsoleAccount(token);
        const eventName = IS_SENDER_ACCOUNT
          ? "sender_login_success"
          : "login_success";
        const loginDetail: any = {
          firstLoginAt: new Date(
            AccountController.accountData.account_type_detail.firstLogin
          ).getTime(),
          lastLoginAt: new Date().getTime()
        };
        if (
          AccountController.accountData.account_type_detail.type ===
            "console" ||
          AccountController.accountData.account_type_detail.type ===
            "sub-console"
        ) {
          CargoController.getLastReadCargo();
          CargoController.setLastReadCargoSession({
            start: new Date().getTime(),
            end: new Date(new Date().getTime() + 5 * 60000).getTime()
          });
        }
        dataLayer(eventName, loginDetail, [
          "userId",
          "browserVersion",
          "osVersion",
          "deviceType",
          "userType",
          "timestamp"
        ]);

        if (FlagsPermissionMiddleware.permission_middleware.isEnabled()) {
          MiddlewareController.getTokenMiddleware()
        }
      })
      .catch(async (error: any) => {
        this.setError(true);

        if (error.response === undefined) {
          this.setErrorInternetOrSystem(true);
          this.setErrorCause(error.response ? "server" : "internet");
          return;
        }

        if (
          error.response.data.message.en === "This partner is banned" ||
          error.response.data.message.en === "This client is banned"
        ) {
          this.setErrorPassword(false);
          this.setInactiveAccount(true);
          return;
        }

        if (
          error.response.status === 422 &&
          error.response.data.message.en === "This email is not active"
        ) {
          this.setErrorPassword(false);
          this.setInactiveAccount(true);
          return;
        }

        if (
          error.response.status === 422 &&
          error.response.data.message.en === "This email not registered"
        ) {
          this.setErrorPassword(false);
          this.setErrorWrongEmail(true);
          return;
        }

        if (
          error.response.status === 400 &&
          error.response.data.message.en === "Phone number not found"
        ) {
          this.setErrorPassword(false);
          this.setErrorWrongEmail(true);
          this.setEmailErrorMessage(error.response.data.message.id);
          return;
        }

        // if email/phone number true, error responses for password
        if (error.response.status === 400) {
          this.setErrorWrongEmail(false);
          this.setErrorPassword(true);
          return;
        }

        if (error.response.status === 403) {
          AccountController.setAccountType(
            error.response.data.data.account_type
          );
          AccountController.setAccountEmail(params.email);
          await router.replace("/account/verification-account");
        }
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Action
  public _onCheckPassword(params: { email: string; password: string }) {
    const presenter = container.resolve(AuthPresenter);
    return presenter
      .onLogin(new LoginApiRequest(params.email, params.password))
      .then(() => {
        this.setErrorPassword(false);
        return true;
      })
      .catch(error => {
        if (error.response.status === 400) {
          this.setErrorPassword(true);
        }
        return false;
      });
  }

  @Action
  public async checkConsoleAccount(param: any) {
    if (window.screen.width < 768) {
      AccountController.setIsCollapseSidebar(true);
    }
    if (param.partner_type === "console") {
      if (!flags.flag_console_select_hub.isEnabled()) {
        setHubData(new Hub());
        this.redirectPageHomeConsole();
        return;
      }
      await HubController.getHubList(
        new RequestHubList({
          city:
            AccountController.accountData.account_type_detail?.partnerLocation
              ?.city_code,
          status: "active",
          partnerId: AccountController.accountData.account_type_detail?.id
        })
      );
      if (this.errorHubList) {
        this.setShowHubList(false);
        return;
      } else if (this.hubList?.length === 1) {
        setHubData(this.hubList[0]);
        this.redirectPageHomeConsole()
        return;
      } else if (!this.hubList?.length) {
        setHubData(new Hub());
        this.redirectPageHomeConsole()
        return;
      }
      this.setShowHubList(true);
      return;
    }
    this.redirectPageHomePOS();
    return false;
  }

  @Action
  public redirectPageHomeConsole() {
    if (AccountController.accountData.accountIsForeign) {
      router.replace("/shipment-rates");
    } else {
      router.replace(flags.flag_default_path_genesis.getValue());
    }
  }

  @Action
  public redirectPageHomePOS() {
    if (AccountController.accountData.accountIsForeign) {
      router.replace("/shipment/booking");
    } else {
      router.replace(flags.flag_default_path_genesis.getValue());
    }
  }

  @Action
  public setRemember() {
    this.setChecked(true);
  }

  @Action
  public sendEmail(params: { email: string }) {
    this.setLoading(true);
    const presenter = container.resolve(AuthPresenter);
    return presenter
      .forgotPassword(new ForgotPasswordApiRequest(params.email))
      .then((res: ResponsePayload) => {
        this.SET_RESPONSE_PAYLOAD(res);
        this.setVerified(true);
        this.setModalSuccess(true);
        return {
          success: true,
          resp: res
        };
      })
      .catch((err: any) => {
        if (err.response === undefined) {
          this.setErrorInternetOrSystem(true);
          this.setErrorCause(err.response ? "server" : "internet");
        } else {
          if (err.response.status === 422) {
            this.setErrorForget(true);
          }

          if (err.response.status === 400) {
            this.setModalSuccess(true);
          }
        }
        return {
          success: false,
          resp: err
        };
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Action
  public editPassword(params: { oldPassword: string; newPassword: string }) {
    MainAppController.showLoading();
    const presenter = container.resolve(AuthPresenter);
    return presenter
      .editPassword(
        new EditPasswordApiRequest(params.oldPassword, params.newPassword)
      )
      .then((res: ResponsePayload) => {
        this.SET_RESPONSE_PAYLOAD(res);
        this.setModalEditPassword(true);
        return true;
      })
      .catch((err: any) => {
        MainAppController.showErrorMessage(parsingErrorResponse(err, ""));
        this.setErrorForget(true);
        return false;
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }

  @Action
  public changePassword(params: {
    email: string;
    password: string;
    token: string;
  }) {
    MainAppController.showLoading();
    const presenter = container.resolve(AuthPresenter);
    presenter
      .resetPassword(
        new ResetPasswordApiRequest(params.email, params.password, params.token)
      )
      .then((res: ResponsePayload) => {
        this.SET_RESPONSE_PAYLOAD(res);
        this.setModalSuccess(true);
      })
      .catch((error: any) => {
        MainAppController.showErrorMessage(
          parsingErrorResponse(error, "Password Reset Failed !", () => {
            MainAppController.closeErrorMessage();
            this.changePassword(params);
          })
        );
      })
      .finally(() => {
        MainAppController.closeLoading();
      });
  }

  @Action
  async onLoggingOut() {
    clearDataLocalStorage();
    removeAuthCredential();
    LocationController.setDetailDistrict(new DistrictData());
    AccountController.setActiveCasbackConfig(new ResponsePayloadV2());
    AccountController.setActiveTopupFeeConfig(new ResponsePayloadV2());
    await router.replace({ name: "login" });
  }
  @Action
  public async logout() {
    this.setError(false);
    this.setErrorCause("");
    this.setLoading(true);
    this.setAccountId(AccountController.accountData.account_id);
    try {
      await AccountController.unpairToken(new UnpairTokenRequest(this.accountId));
    } catch (e) {
      console.warn(e);
    }
    const presenter = container.resolve(AuthPresenter);
    return presenter
      .logout(new LogoutApiRequest())
      .then(async (res: ResponsePayload) => {
        this.SET_RESPONSE_PAYLOAD(res);
        NotificationController.setIsOpen(false);
        await this.onLoggingOut();
        return true;
      })
      .catch(async (error: any) => {
        if (error.response) {
          await this.onLoggingOut();
        } else {
          this.setError(true);
          this.setErrorCause(error.response ? "server" : "internet");
        }
        return false;
      })
      .finally(() => {
        this.setLoading(false);
        this.setShowHubList(false);
      });
  }

  // verification account
  @Action
  public async verificationAccount(params: {
    emailOrUsername: string;
    question: string;
    answer: string;
  }) {
    this.setLoading(true);
    const presenter = container.resolve(AuthPresenter);
    return presenter
      .verificationAccount(
        new VerificationAccountRequest(
          params.emailOrUsername,
          params.question,
          params.answer
        )
      )
      .then((res: TokenVerificationAccountEntities) => {
        this.setError(false);
        this.setErrorCause("");
        router.replace(
          `/account/reset-password?token=${res.token}&email=${params.emailOrUsername}`
        );
      })
      .catch((error: any) => {
        this.setError(true);
        this.setVerificationAcountErrorMessage(error.response?.data.message.id);
        if (!(error.response?.status <= 500)) {
          this.setErrorInternetOrSystem(true);
          this.setError(false);
          this.setErrorCause(error.response ? "server" : "internet");
        }
      })
      .finally(() => {
        this.setLoading(false);
      });
  }

  @Action
  public handleError() {
    this.setError(false);
    this.setErrorInternetOrSystem(false);
    this.setInactiveAccount(false);
  }

  @Action
  public handleErrorLogout() {
    this.setError(false);
  }

  get hubList() {
    return HubController.hubList.data;
  }

  get errorHubList() {
    return HubController.isError;
  }
}

export const AuthController = getModule(AuthStore);
