import {
  ClientRegistrationData,
  ClientRegistrationEntities,
  ClientRegistrationDetail,
  ClientCurrentRate,
  ClientLocation,
  ClientPartner,
  ClientContact,
  ClientCod,
  ClientCodAsDefault,
  ClientTax,
  ClientBilling,
  BankDetail,
  clientPaymentMethodData,
  clientCodConfigAmountData
} from "@/domain/entities/ClientRegistration";
import { OptionsClass } from "@/domain/entities/MainApp";
import { Pagination } from "@/domain/entities/Pagination";
import { ResponsePayload } from "@/domain/entities/ResponsePayload";
import { AxiosResponse } from "axios";
import startCase from "lodash/startCase";
import { ConfigBucketStorage } from "@/feature-flags/flags-config-bucket-storage";
import parsingLinkGcpToAws from "@/app/infrastructures/misc/common-library/ParsingLinkGcpToAws";

export class ClientRegistrationMapper {
  public convertClientRegistrationFromApi(
    result: AxiosResponse<any>
  ): ClientRegistrationEntities {
    const { data } = result;
    return new ClientRegistrationEntities(
      new Pagination(data.meta.page, data.meta.limit, data.meta.total_records),
      data.data.length > 0
        ? data.data.map((item: any) => {
            return new ClientRegistrationData(
              item.id,
              item.company_name,
              item.code,
              item.start_date,
              item.end_date,
              item.status,
              item.type
            );
          })
        : []
    );
  }

  public convertDetailClientRegistrationFromApi(
    result: AxiosResponse<any>,
    version = "v1"
  ): ClientRegistrationDetail {
    const {
      data: { data }
    } = result;

    const isV1 = version === "v1";

    return new ClientRegistrationDetail({
      id: data.client_id,
      clientCode: data.client_code,
      clientCodeElexys: data.client_elexys_code,
      clientName: data.client_name,
      clientPhone: data.client_phone.substr(2),
      clientEmail: data.client_email,
      clientType: data.client_type,
      clientCategory: data.client_category,
      clientCompanySize: startCase(data.client_company_size),
      clientTaxNumber: isV1
        ? data.client_tax_number
        : data.client_tax_number.replaceAll("-", "").replaceAll(".", ""),
      clientStatus: data.client_status,
      clientAddress: data.client_address,
      clientDistrict: new ClientLocation({
        id: data.client_district_id,
        code: data.client_district_code,
        name: data.client_district_name,
        cityCode: data.client_city_code
      }),
      clientCity: new ClientLocation({
        id: data.client_city_id,
        code: data.client_city_code,
        name: data.client_city_name,
        cityCode: data.client_city_code
      }),
      clientStatusUpdatedAt: data.client_status_updated_at,
      clientStatusUpdatedBy: data.client_status_updated_by,
      clientStatusUpdatedReason: data.client_status_updated_reason,
      clientParentId: data.client_parent_id,
      clientParentName: data.client_parent_name,
      clientPartner: new ClientPartner({
        clientPartnerId: data.client_partner_id,
        clientPartnerName: data.client_partner_name,
        clientPartnerQuantifierCommission:
          data.client_partner_quantifier_commission,
        clientPartnerCommissionAmount: data.client_partner_commission_amount,
        clientPartnerNumeratorCommision:
          data.client_partner_numerator_commission
      }),
      clientContact: new ClientContact({
        clientContactName: data.client_contact_name,
        clientContactJobTitle: data.client_contact_job_title,
        clientContactEmail: data.client_contact_email,
        clientContactPhone: data.client_contact_phone.startsWith("0")
          ? data.client_contact_phone.substr(1)
          : data.client_contact_phone.substr(2)
      }),
      clientIsCustomRate:
        data.client_current_rate?.client_file_base_rate &&
        data.client_current_rate?.client_file_custom_rate
          ? "mix"
          : data.client_current_rate?.client_file_base_rate
          ? "basic"
          : "custom",
      clientIsRenewalCustomRate: data.client_is_renewal_custom_rate,
      clientIsCod: data.client_is_cod,
      clientIsDfod: data.client_is_dfod,
      clientIsMixpack: data.client_is_mixpack,
      clientIsDo: data.client_is_do,
      clientBranchDoPaymentType: data.client_branch_do_payment_type,
      clientCreatedAt: data.client_created_at,
      clientUpdatedAt: data.client_updated_at,
      clientCreatedBy: data.client_created_by,
      clientUpdatedBy: data.client_updated_by,
      clientCurrentRate: new ClientCurrentRate({
        clientCustomRateId:
          data.client_current_rate?.client_custom_rate_id || "",
        clientStatusCustomRate:
          data.client_current_rate?.client_status_custom_rate || "",
        clientRateVersionStartDate:
          data.client_current_rate?.client_rate_version_start_date ||
          new Date(0),

        clientRateVersionEndate:
          data.client_current_rate?.client_rate_version_end_date || new Date(0),
        clientStatusUpdatedAt:
          data.client_current_rate?.client_status_updated_at || "",
        clientStatusUpdatedBy:
          data.client_current_rate?.client_status_updated_by || "",
        clientStatusUpdatedReason:
          data.client_current_rate?.client_status_updated_reason || "",
        clientVersionBaseRate:
          data.client_current_rate?.client_version_base_rate || "",
        clientDiscountBaseRate:
          data.client_current_rate?.client_discount_base_rate || 0,
        clientFileNameBaseRate:
          data.client_current_rate?.client_file_name_base_rate || "-",
        clientFileBaseRate:
          data.client_current_rate?.client_file_base_rate || "",
        clientVersionCustomRate:
          data.client_current_rate?.client_version_custom_rate || "",
        clientFileNameCustomRate:
          data.client_current_rate?.client_file_name_custom_rate || "",
        clientFileCustomRate:
          data.client_current_rate?.client_file_custom_rate || "",
        clientErrorNameCustomRate:
          data.client_current_rate?.client_error_name_custom_rate || "",
        clientErrorFileCustomRate:
          data.client_current_rate?.client_error_file_custom_rate || "",
        clientStatusBulk: data.client_current_rate?.client_status_bulk || "",
        clientRateVersionCustom3lc:
          data.client_current_rate?.client_rate_version_custom_3lc || "",
        clientRatePriceType:
          data.client_current_rate?.client_rate_price_type || "",
        clientRateInsuranceType:
          data.client_current_rate?.client_rate_insurance_type || "",
        clientRateInsuranceValuePercentage:
          data.client_current_rate?.client_rate_insurance_value_percentage || 0
      }),
      clientRenewalRate: data.client_renewal_rate,
      clientCodReject: new ClientCod({
        clientCodName: data.client_branch_cod_return_receiver_name,
        clientCodAddress: data.client_branch_cod_return_receiver_address,
        clientCodDistrict: new ClientLocation({
          id: data.client_branch_cod_return_receiver_district_id,
          code: data.client_branch_cod_return_receiver_district_code,
          name: data.client_branch_cod_return_receiver_district_name,
          cityCode: data.client_branch_cod_return_receiver_city_code
        }),
        clientCodPhone: data.client_branch_cod_return_receiver_phone.substr(2),
        clientCodCommission: data.client_branch_cod_fee
      }),
      clientCodRejectAsDefault: new ClientCodAsDefault({
        defaultCodAddress: data.client_branch_cod_return_receiver_address,
        defaultCodDistrict: new ClientLocation({
          id: data.client_branch_cod_return_receiver_district_id,
          code: data.client_branch_cod_return_receiver_district_code,
          name: data.client_branch_cod_return_receiver_district_name,
          cityCode: data.client_branch_cod_return_receiver_city_code
        }),
        defaultCodName: data.client_branch_cod_return_receiver_name,
        defaultCodPhone: data.client_branch_cod_return_receiver_phone
      }),
      clientBankName: data.client_beneficiary_account_name,
      clientBankNumber: data.client_beneficiary_account_number,
      clientBankEmail: data.client_beneficiary_email,
      clientBankLabel: data.client_beneficiary_bank_name,
      dataProcessed: data.data_processed_current,
      totalData: data.total_data_current,
      clientTax: new ClientTax({
        NIK: data.client_nik_id,
        NIKName: data.client_nik_name,
        NIKAddress: data.client_nik_address,
        isTaxPayer: data.client_efaktur_with_nik,
        taxNumber: isV1
          ? data.client_tax_number
          : data.client_tax_number.replaceAll(".", "").replaceAll("-", ""),
        taxPayerAddress: data.client_taxpayer_address,
        taxPayerName: data.client_taxpayer_name,
        transactionCode: data.client_tax_transaction_no
      }),
      clientBilling: new ClientBilling({
        noWhatsApp: data.client_billing_wa_number.startsWith("0")
          ? data.client_billing_wa_number.substr(1)
          : data.client_billing_wa_number.substr(2),
        billingSchedule: data.client_billing_schedule_so.toLowerCase(),
        timeRangePayment: data.client_billing_payment_period.replace(
          " Days",
          ""
        ),
        email: data.client_billing_email
          ? data.client_billing_email.split(",")
          : [],
        startDate: data.client_contract_start_date,
        endDate: data.client_contract_end_date,
        attachmentUrl: parsingLinkGcpToAws(data.client_contract_attachment_url)
      }),
      bankSettlement: new BankDetail({
        bankLabel: `VA ${startCase(data.client_va_bank_name)}`,
        bankName: data.client_va_bank_name,
        accountNo: data.client_va_bank_number,
        accountName: data.client_va_bank_account_name,
        logo: `${ConfigBucketStorage.config_bucket_bank_logo.getValue()}${
          data.client_va_bank_name
        }.png`
      }),
      clientPadFee: data.client_pad_fee || 0,
      clientPaymentMethod: clientPaymentMethodData.find(
        (payment: OptionsClass) => payment.value === data.client_payment_method
      ),
      clientCodConfigAmount: clientCodConfigAmountData.find(
        (cod: OptionsClass) => cod.value === data.client_cod_config_amount
      ),
      clientCodShipmentDiscount: data.client_cod_shipment_discount,
      isReturnConfig: data.client_meta?.is_return_config || false,
      returnConfigLimit: data.client_meta?.return_config_limit || 0,
      returnConfigReasonIds: data.client_meta?.return_config_reason_ids || "",
      priceType: data.price_type,
      rateVersionCustom3lc: data.rate_version_custom_3lc,
      insuranceType: data.client_meta?.insurance_type || "",
      insuranceValuePercentage: data.client_meta?.insurance_value_percentage || "",
      dataProcessedCurrent: data.data_processed_current,
      dataProcessedRenewal: data.data_processed_renewal,
      totalDataCurrent: data.total_data_current,
      totalDataRenewal: data.total_data_renewal
    });
  }

  public convertChangeDataFromApi(result: AxiosResponse<any>): ResponsePayload {
    const { data } = result;
    return new ResponsePayload({
      success: data.success,
      message: data.message
    });
  }
}
