import { injectable } from "tsyringe";
import { DashboardApiRepository } from "@/app/infrastructures/repositories/api/DashboardApiRepository";
import {
  CodDashboardDetailCourier,
  CutOffConsoleData,
  DashboardConsoleData,
  DashboardDetailList,
  DataCorporateDashboard,
  DetailCourierPerStatusCodDashboardData,
  ListCourierPartnerCodDashboard,
  SummaryCodDashboardData,
  ReferralAffiliate
} from "@/domain/entities/Dashboard";
import {
  DashboardConsoleRequest,
  DashboardDetailListRequest,
  DashboardDownloadRequest,
  DownloadSummaryCodRequest,
  CodDashboardRequest,
  CodDashboardCourierDetailRequest,
  CorporateDashboardRequest,
  CourierDailyDepositRequest
} from "@/data/payload/api/DashboardRequests";
import {
  RequestList,
  ResponsePayload,
  ResponsePayloadV2
} from "@/domain/entities/ResponsePayload";

@injectable()
export class DashboardPresenter {
  private repository: DashboardApiRepository;

  constructor(repo: DashboardApiRepository) {
    this.repository = repo;
  }

  public getConsoleData(
    params: DashboardConsoleRequest
  ): Promise<DashboardConsoleData> {
    return this.repository.getConsoleData(params);
  }

  public getCutOffConsoleOutgoingStt(): Promise<CutOffConsoleData> {
    return this.repository.getCutOffConsoleOutgoingStt();
  }

  public getDashboardDetailList(
    params: DashboardDetailListRequest
  ): Promise<DashboardDetailList> {
    return this.repository.getDashboardDetailList(params);
  }

  public getDownloadDashboard(
    params: DashboardDownloadRequest
  ): Promise<ResponsePayload> {
    return this.repository.getDownloadDashboard(params);
  }

  public getListPosFollowUpDashboard(
    params: RequestList
  ): Promise<ResponsePayloadV2> {
    return this.repository.getListPosFollowUpDashboard(params);
  }

  public getDownloadDetailPOS(
    params: DashboardDetailListRequest
  ): Promise<any> {
    return this.repository.getDownloadDetailPOS(params);
  }

  public getListCourierPartnerCodDashboard(
    params: CodDashboardRequest
  ): Promise<ListCourierPartnerCodDashboard> {
    return this.repository.getListCourierPartnerCodDashboard(params);
  }

  public getSummaryCodDashboardData(
    path: string,
    params: CodDashboardRequest
  ): Promise<SummaryCodDashboardData> {
    return this.repository.getSummaryCodDashboardData(path, params);
  }

  public getDetailCourierPerStatusCodDashboardData(
    params: CodDashboardCourierDetailRequest
  ): Promise<DetailCourierPerStatusCodDashboardData[]> {
    return this.repository.getDetailCourierPerStatusCodDashboardData(params);
  }

  public postCourierDailyDeposit(
    type: string,
    payload: CourierDailyDepositRequest
  ): Promise<ResponsePayload> {
    return this.repository.postCourierDailyDeposit(type, payload);
  }

  public async getSummaryDownload(
    type: string,
    filter: DownloadSummaryCodRequest
  ): Promise<any> {
    return this.repository.getSummaryDownload(type, filter);
  }

  public getDetailCourierData(
    params: CodDashboardCourierDetailRequest
  ): Promise<CodDashboardDetailCourier> {
    return this.repository.getCourierDetailData(params);
  }

  public getDetailCourierDownloadCodDashboardData(
    driverPhone: string,
    filter: CodDashboardCourierDetailRequest,
    isPic = false
  ): Promise<any> {
    return this.repository.getDetailCourierDownloadCodDashboardData(
      driverPhone,
      filter,
      isPic
    );
  }

  public getCorporateDashboardData(
    path: string,
    params: CorporateDashboardRequest
  ): Promise<DataCorporateDashboard> {
    return this.repository.getCorporateDashboardData(path, params);
  }

  public getReferralAffiliate(): Promise<ReferralAffiliate> {
    return this.repository.getReferralAffiliate();
  }
}
