import { Pagination } from "./Pagination";

export class Handover {
  handoverId: number;
  handoverDestinationPartnerName: string;
  handoverVendorCode: string;
  handoverTotalSTT: number;
  handoverTotalPiece: number;
  handoverTotalGrossWeight: number;
  handoverTotalVolumeWeight: number;
  handoverCreatedAt: string;
  handoverCreatedBy: string;

  constructor(
    handoverId: number,
    handoverDestinationPartnerName: string,
    handoverVendorCode: string,
    handoverTotalSTT: number,
    handoverTotalPiece: number,
    handoverTotalGrossWeight: number,
    handoverTotalVolumeWeight: number,
    handoverCreatedAt: string,
    handoverCreatedBy: string
  ) {
    this.handoverId = handoverId;
    this.handoverDestinationPartnerName = handoverDestinationPartnerName;
    this.handoverVendorCode = handoverVendorCode;
    this.handoverTotalSTT = handoverTotalSTT;
    this.handoverTotalPiece = handoverTotalPiece;
    this.handoverTotalGrossWeight = handoverTotalGrossWeight;
    this.handoverTotalVolumeWeight = handoverTotalVolumeWeight;
    this.handoverCreatedAt = handoverCreatedAt;
    this.handoverCreatedBy = handoverCreatedBy;
  }
}

export class HandoverList {
  pagination: Pagination;
  data: Handover[];

  constructor(pagination: Pagination, data: Handover[]) {
    this.pagination = pagination;
    this.data = data;
  }
}

export class HandoverDetail {
  handoverManifestId = 0;
  handoverManifestTotalStt = 0;
  handoverTotalPiece = 0;
  handoverManifestTotalGrossWeight = 0;
  handoverManifestTotalVolumeWeight = 0;
  handoverPartnerName = "";
  handoverDestinationPartnerName = "";
  handoverVendorCode = "";
  handoverArrivalCitycode = "";
  handoverArrivalCityName = "";
  handoverOriginCityCode = "";
  handoverOriginCityName = "";
  handoverCreatedAt = "";
  handoverCreatedBy = "";
  stt: HandoverSttResult[] = [];
  constructor(fields?: Partial<HandoverDetail>) {
    Object.assign(this, fields);
  }
}

export class HandoverSttResult {
  sttId: string;
  sttNo: string;
  sttProductType: string;
  sttTotalPiece: string;
  sttDestinationCityId: string;
  sttDestinationCityName: string;
  sttWoodPacking: string;
  sttCommodityCode: string;
  sttCommodityName: string;
  sttGrossWeight: number;
  sttVolumeWeight: number;
  sttChargeableWeight: number;
  sttCodAmount: number;
  sttPiece: SttPiece[];
  sttElexysNo?: string;
  sttOriginCityId: string;
  sttOriginCityName: string;
  constructor(
    sttId: string,
    sttNo: string,
    sttProductType: string,
    sttTotalPiece: string,
    sttDestinationCityId: string,
    sttDestinationCityName: string,
    sttWoodPacking: string,
    sttCommodityCode: string,
    sttCommodityName: string,
    sttGrossWeight: number,
    sttVolumeWeight: number,
    sttChargeableWeight: number,
    sttCodAmount: number,
    sttPiece: SttPiece[],
    sttOriginCityId: string,
    sttOriginCityName: string,
    sttElexysNo?: string
  ) {
    this.sttId = sttId;
    this.sttNo = sttNo;
    this.sttProductType = sttProductType;
    this.sttTotalPiece = sttTotalPiece;
    this.sttDestinationCityId = sttDestinationCityId;
    this.sttDestinationCityName = sttDestinationCityName;
    this.sttWoodPacking = sttWoodPacking;
    this.sttCommodityCode = sttCommodityCode;
    this.sttCommodityName = sttCommodityName;
    this.sttGrossWeight = sttGrossWeight;
    this.sttVolumeWeight = sttVolumeWeight;
    this.sttChargeableWeight = sttChargeableWeight;
    this.sttCodAmount = sttCodAmount;
    this.sttPiece = sttPiece;
    this.sttElexysNo = sttElexysNo;
    this.sttOriginCityId = sttOriginCityId;
    this.sttOriginCityName = sttOriginCityName;
  }
}

export class SttPiece {
  sttPieceId: number;
  sttPieceGrossWeight: number;
  sttPieceVolumeWeight: number;
  sttPieceNo: number;
  constructor(
    sttPieceId: number,
    sttPieceGrossWeight: number,
    sttPieceVolumeWeight: number,
    sttPieceNo: number
  ) {
    this.sttPieceId = sttPieceId;
    this.sttPieceGrossWeight = sttPieceGrossWeight;
    this.sttPieceVolumeWeight = sttPieceVolumeWeight;
    this.sttPieceNo = sttPieceNo;
  }
}

export class SttDetailData {
  sttId: number;
  sttNumber: string;
  product: string;
  totalPieces: number;
  originCityId: string;
  destinationCityId: string;
  destinationCity: string;
  woodPacking: string;
  commodityCode: string;
  commodity: string;
  totalGrossWeight: number;
  totalVolumeWeight: number;
  chargeableWeight: number;
  codAmount: number;
  sttLastStatusId: string;
  bookedAt: string;
  piece: PieceDetail[];
  sttNoRefExternal: string;
  sttElexysNumber?: string;
  constructor(
    sttId: number,
    sttNumber: string,
    product: string,
    totalPieces: number,
    originCityId: string,
    destinationCityId: string,
    destinationCity: string,
    woodPacking: string,
    commodityCode: string,
    commodity: string,
    totalGrossWeight: number,
    totalVolumeWeight: number,
    chargeableWeight: number,
    codAmount: number,
    sttLastStatusId: string,
    bookedAt: string,
    piece: PieceDetail[],
    sttNoRefExternal: string,
    sttElexysNumber?: string
  ) {
    this.sttId = sttId;
    this.sttNumber = sttNumber;
    this.product = product;
    this.totalPieces = totalPieces;
    this.originCityId = originCityId;
    this.destinationCityId = destinationCityId;
    this.destinationCity = destinationCity;
    this.woodPacking = woodPacking;
    this.commodityCode = commodityCode;
    this.commodity = commodity;
    this.totalGrossWeight = totalGrossWeight;
    this.totalVolumeWeight = totalVolumeWeight;
    this.chargeableWeight = chargeableWeight;
    this.codAmount = codAmount;
    this.sttLastStatusId = sttLastStatusId;
    this.bookedAt = bookedAt;
    this.piece = piece;
    this.sttNoRefExternal = sttNoRefExternal;
    this.sttElexysNumber = sttElexysNumber;
  }
}

export class PieceDetail {
  sttPieceId: number;
  grossWeight: number;
  volumeWeight: number;
  sttPieceNumber: string;
  lastStatus: string;
  pieceNo: string;
  constructor(
    sttPieceId: number,
    grossWeight: number,
    volumeWeight: number,
    sttPieceNumber: string,
    lastStatus: string,
    pieceNo: string
  ) {
    this.sttPieceId = sttPieceId;
    this.grossWeight = grossWeight;
    this.volumeWeight = volumeWeight;
    this.sttPieceNumber = sttPieceNumber;
    this.lastStatus = lastStatus;
    this.pieceNo = pieceNo;
  }
}

export class ResponseUpdate {
  handoverId: number;
  totalSttSuccess: number;
  totalSttFailed: number;
  sttFailed: Array<string>;
  constructor(
    handoverId: number,
    totalSttSuccess: number,
    totalSttFailed: number,
    sttFailed: Array<string>
  ) {
    this.handoverId = handoverId;
    this.totalSttSuccess = totalSttSuccess;
    this.totalSttFailed = totalSttFailed;
    this.sttFailed = sttFailed;
  }
}

export class PartnerVendorList {
  code: string;
  partnerId: number;
  partnerName: string;
  type: string;
  cityCode: string;
  vendorName: string;
  constructor(
    code: string,
    partnerId: number,
    partnerName: string,
    type: string,
    cityCode: string,
    vendorName: string
  ) {
    this.code = code;
    this.partnerId = partnerId;
    this.partnerName = partnerName;
    this.type = type;
    this.cityCode = cityCode;
    this.vendorName = vendorName;
  }
}
