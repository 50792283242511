import { Pagination } from "./Pagination";
import { QueryParamsEntities } from "@/domain/entities/MainApp";

export class DashboardDetailList {
  pagination = new Pagination(1, 20);
  data = [] as DashboardDetailListData[];
  constructor(fields?: Partial<DashboardDetailList>) {
    Object.assign(this, fields);
  }
}

export class DashboardDetailListData {
  sttId = 0;
  sttNo = "";
  sttOriginCityId = "";
  sttOriginCityName = "";
  sttOriginDistrictId = "";
  sttOriginDistrictName = "";
  sttDestinationCityId = "";
  sttDestinationCityName = "";
  sttDestinationDistrictId = "";
  sttDestinationDistrictName = "";
  sttLastStatusId = "";
  sttCreatedAt = "";
  sttCreatedName = "";
  sttBookedName = "";
  sttUpdatedAt = "";
  sttUpdatedName = "";
  sttUpdatedActorName = "";
  sttTotalPiece = 0;
  sttChargeableWeight = 0;
  sttStatusLabel = "";
  constructor(fields?: Partial<DashboardDetailListData>) {
    Object.assign(this, fields);
  }
}

export class DashboardConsoleData {
  total = 0;
  constructor(fields?: Partial<DashboardConsoleData>) {
    Object.assign(this, fields);
  }
}

export class DashboardData {
  title = "";
  description = "";
  totalStt = 0;
  key = "";
  icon = "";
  accountType = "";
  status = "";
  loading = false;
  error = false;
  type = "";
  detailTitle? = "";
  total? = 0;
  posCode? = "";
  endpoint? = "";
  fromOutside? = "";
  constructor(fields?: Partial<DashboardData>) {
    Object.assign(this, fields);
  }
}

// cut off console
// outgoing
export class CutOffConsoleOutgoingCycle {
  bkdSti = 0;
  stiCargoPlane = 0;
  stiCargoTruck = 0;
  cycleStart = "";
  targetSti = "";
  cycleEndCargoPlane = "";
  cycleEndCargoTruck = "";
  total = 0;
  constructor(fields?: Partial<CutOffConsoleOutgoingCycle>) {
    Object.assign(this, fields);
  }
}

export class CutOffConsoleOutgoingProcess {
  bkdSti = 0;
  stiCargoPlane = 0;
  stiCargoTruck = 0;
  total = 0;
  constructor(fields?: Partial<CutOffConsoleOutgoingProcess>) {
    Object.assign(this, fields);
  }
}

// incoming
export class CutOffConsoleIncomingCycle {
  stiDestDel = 0;
  delPod = 0;
  cycleStart = "";
  targetDel = "";
  targetPod = "";
  cycleEndCargoPlane = "";
  cycleEndCargoTruck = "";
  total = 0;
  constructor(fields?: Partial<CutOffConsoleIncomingCycle>) {
    Object.assign(this, fields);
  }
}

export class CutOffConsoleIncomingProcess {
  stiDestDel = 0;
  delPod = 0;
  total = 0;
  constructor(fields?: Partial<CutOffConsoleIncomingProcess>) {
    Object.assign(this, fields);
  }
}

export class CutOffConsoleOutgoingStt {
  cycle1: CutOffConsoleOutgoingCycle | CutOffConsoleIncomingCycle =
    new CutOffConsoleOutgoingCycle() || new CutOffConsoleIncomingCycle();
  cycle2: CutOffConsoleOutgoingCycle | CutOffConsoleIncomingCycle =
    new CutOffConsoleOutgoingCycle() || new CutOffConsoleIncomingCycle();
  backlog: CutOffConsoleOutgoingProcess | CutOffConsoleIncomingProcess =
    new CutOffConsoleOutgoingProcess() || new CutOffConsoleIncomingProcess();
  backlogToday: CutOffConsoleOutgoingProcess | CutOffConsoleIncomingProcess =
    new CutOffConsoleOutgoingProcess() || new CutOffConsoleIncomingProcess();
  onTime: CutOffConsoleOutgoingProcess | CutOffConsoleIncomingProcess =
    new CutOffConsoleOutgoingProcess() || new CutOffConsoleIncomingProcess();
  late: CutOffConsoleOutgoingProcess | CutOffConsoleIncomingProcess =
    new CutOffConsoleOutgoingProcess() || new CutOffConsoleIncomingProcess();
  hanging: CutOffConsoleOutgoingProcess | CutOffConsoleIncomingProcess =
    new CutOffConsoleOutgoingProcess() || new CutOffConsoleIncomingProcess();
  invalid = 0;
  constructor(fields?: Partial<CutOffConsoleOutgoingStt>) {
    Object.assign(this, fields);
  }
}

export class CutOffConsoleData {
  outgoing: CutOffConsoleOutgoingStt = new CutOffConsoleOutgoingStt();
  incoming: CutOffConsoleOutgoingStt = new CutOffConsoleOutgoingStt();
  constructor(fields?: Partial<CutOffConsoleData>) {
    Object.assign(this, fields);
  }
}

export class PosFollowUpDashboard {
  posCode = "";
  posName = "";
  phoneNumber = "";
  unprocessed = 0;
  backlogToday = 0;
  backlog = 0;
  hanging = 0;
  constructor(fields?: Partial<PosFollowUpDashboard>) {
    Object.assign(this, fields);
  }
}

export class ListCourierPartnerCodDashboard {
  pagination = new Pagination(1, 10);
  data = [] as CourierPartnerCodDashboard[];
  constructor(fields?: Partial<ListCourierPartnerCodDashboard>) {
    Object.assign(this, fields);
  }
}
export class CourierPartnerCodDashboard {
  courierId = "";
  courierName = "";
  courierPhone = "";
  partnerId = 0;
  partnerName = "";
  partnerPhone = "";
  partnerContactPhone = "";
  totalStt = 0;
  totalSttPodSameDay = 0;
  totalSttPodDifferentDay = 0;
  totalCollected = 0;
  totalQrisCollected = 0;
  totalOutstanding = 0;
  totalSttDexCollected = 0;
  totalSttDexOutstanding = 0;
  totalSttCodrejCollected = 0;
  totalSttCodrejOutstanding = 0;
  totalSttDel = 0;

  constructor(fields?: Partial<CourierPartnerCodDashboard>) {
    Object.assign(this, fields);
  }
}
export class SummaryCodDashboardData {
  sttQuantity = 0;
  sttTotalCodAmount = 0;
  sttStatus = "";
  loading = false;
  lastSyncDate = "";
  paymentMethod = "";

  constructor(fields?: Partial<SummaryCodDashboardData>) {
    Object.assign(this, fields);
  }
}

export class DetailCourierPerStatusCodDashboardData {
  sttNo = "";
  sttStatus = "";
  sttReason = "";
  sttCodAmount = 0;
  sttCollectionStatus = "";
  sttRecipientName = "";
  sttRecipientPhone = "";
  sttDriverName = "";
  sttDriverPhone = "";
  status = "";
  loading = true;
  sttPaymentMethod = "";

  constructor(fields?: Partial<DetailCourierPerStatusCodDashboardData>) {
    Object.assign(this, fields);
  }
}

export class CodDashboardDetailCourier {
  courierPhone = "";
  courierName = "";
  courierId = 0;
  courierPartnerName = "";
  courierPartnerPhone = "";
  courierPartnerContactPhone = "";
  totalStt = 0;
  totalSttPod = 0;
  totalCollected = 0;
  totalQrisCollected = 0;
  totalOutstanding = 0;
  totalSttDexCollected = 0;
  totalSttDexOutstanding = 0;
  totalSttCodrejCollected = 0;
  totalSttCodrejOutstanding = 0;
  totalSttDel = 0;
  performanceSameDay = "";
  percentageSameDay = "";
  performanceDifferentDay = "";
  percentageDifferentDay = "";
  constructor(fields?: Partial<CodDashboardDetailCourier>) {
    Object.assign(this, fields);
  }
}

export class SttByProductTypeData {
  productType = "";
  totalStt = 0;
  constructor(fields?: Partial<SttByProductTypeData>) {
    Object.assign(this, fields);
  }
}

export class DeliveryPerRouteData {
  routeName = "";
  totalStt = 0;
  sttByProductType = [] as SttByProductTypeData[];
  constructor(fields?: Partial<DeliveryPerRouteData>) {
    Object.assign(this, fields);
  }
}

export class AreaBarChart {
  series: any[] = [];
  categories: any[] = [];

  constructor(fields?: Partial<AreaBarChart>) {
    Object.assign(this, fields);
  }
}

export class ClaimReason {
  reason = "";
  count = 0;

  constructor(fields?: Partial<ClaimReason>) {
    Object.assign(this, fields);
  }
}

export class DistributionProductData {
  regpack = 0;
  bigpack = 0;
  jagopack = 0;
  interpack = 0;
  onepack = 0;
  docupack = 0;
  constructor(fields?: Partial<DistributionProductData>) {
    Object.assign(this, fields);
  }
}

export class DetailPerformanceData {
  productType = "";
  totalResi = 0;
  constructor(fields?: Partial<DetailPerformanceData>) {
    Object.assign(this, fields);
  }
}

export class PerformancePerRouteData {
  routeName = "";
  performance = 0;
  detailPerformance: DetailPerformanceData[] = [];
  constructor(fields?: Partial<PerformancePerRouteData>) {
    Object.assign(this, fields);
  }
}

export class DataCorporateDashboard {
  totalStt = 0;
  totalSttCod = 0;
  totalSttNonCod = 0;
  totalCodAmount = 0;
  totalAmount = 0;
  totalTariffAmount = 0;
  totalResi: AreaBarChart = {
    series: [],
    categories: []
  };
  performaDelivery: AreaBarChart = {
    series: [],
    categories: []
  };
  lastUpdatedAt = "";
  totalClaim = 0;
  totalClaimSubmitted = 0;
  totalClaimApproved = 0;
  totalClaimRejected = 0;
  totalClaimCompleted = 0;
  totalClaimMbg = 0;
  totalReceipt = 0;
  distributionProduct = {};
  deliveryPerRouteData = [] as DeliveryPerRouteData[];
  claimReasons: ClaimReason[] = [];
  percentageSttOnTime = 0;
  totalSttOnTime = 0;
  totalSttLate = 0;
  totalRoute = 0;
  performancePerRouteData: PerformancePerRouteData[] = [];

  constructor(fields?: Partial<DataCorporateDashboard>) {
    Object.assign(this, fields);
  }
}

export class ReferralAffiliate {
  posId = 0;
  affiliatelink = "";
  expiredAt = "";

  constructor(fields?: Partial<ReferralAffiliate>) {
    Object.assign(this, fields);
  }
}

export class QueryParamDashboardToDetail {
  startDate = ""; // format YYYY-MM-DD
  endDate = ""; // format YYYY-MM-DD
  productType = ""; // all, regpack, onepack, jagopack, etc
  deliveryType = ""; // cod, non_cod, all
  status = "";
  deliveryStatus = "";
  path = "";

  constructor(fields?: Partial<QueryParamDashboardToDetail>) {
    Object.assign(this, fields);
  }

  toQueryString(): string {
    return new QueryParamsEntities(this).queryString;
  }
  toQueryStringB64(): string {
    return btoa(this.toQueryString());
  }
  toFullPath(): string {
    return `${this.path}?q=${this.toQueryStringB64()}`;
  }
}

export class SummaryType {
  sttStatus = "";
  type = "";
  paymentMethod = "";

  constructor(fields?: Partial<SummaryType>) {
    return Object.assign(this, fields);
  }
}

export class DetailStatusSummary {
  statusName = "";
  statusSubName = "";
  toolTipDescription = "";
  sttDisplay = "";
  percentageSttDisplay = "";
  totalAmountSttDisplay = "";
  backgroundColor = "";
  iconColor = "";
  iconName = "";
  statusType = "";
  loading = false;

  constructor(fields?: Partial<DetailStatusSummary>) {
    Object.assign(this, fields);
  }
}

export const paymentMethod: any = {
  QRIS: "QRIS",
  CASH: "Tunai",
  FREE: "Gratis"
};
